<template>
  <div>
    <ul class="nav flex-column nav-pills mb-3">

      <a
        class="nav-link"
        href="#"
      >
        Overview
      </a>

      <li
        v-for="tab in clientTabs"
        class="nav-item text-capitalize"
        :class="{ 'd-none': isHidden('clients_'+tab) }"
      >
          <a
            class="nav-link"
            :href="setTabLink(tab.link)"
            >{{ tab.name.replace("_", " ", tab.name) }}</a>

      </li>
    </ul>

    
  </div>
</template>

<script>
import tabs from '../mixins/tabs';
import queries from '../mixins/queries';

export default {
  
  mixins: [ tabs, queries ],

  props: ['uuid'],

  data () {
    return {
      active_tab: null,
      tabs: [
        {name: 'events', type: 'link', link: 'events'},
        {name: 'cases', type: 'link', link: 'cases'},
        {name: 'payments', type: 'link', link: 'payments'},
        {name: 'sent_files', type: 'link', link: 'sent_files'},
        {name: 'addresses', type: 'link', link: 'addresses'},
        {name: 'messages', type: 'link', link: 'messages'},
        {name: 'comments', type: 'link', link: 'comments'},
        {name: 'documents', type: 'link', link: 'documents'},
        {name: 'warrants', type: 'link', link: 'warrants'},
      ]
    }
  },

  computed: {

    clientTabs () {
      if(this.settings && this.settings.client_tabs) {
        if(Array.isArray(this.settings.client_tabs) && this.settings.client_tabs.length) {
          return this.settings.client_tabs;
        }
      }

      return this.tabs;
    }

  },

  mounted () {
    if (this.$route.query.tab && this.tabs.find(item => item.title === this.$route.query.tab)) {
        this.active_tab = this.$route.query.tab
        this.$eventBus.$emit('tabChange', this.$route.query.tab);
    }
    if (!this.active_tab && this.tabs && this.tabs.length) {
      this.active_tab = this.tabs[0].title;
    }
    if (this.active_tab && this.active_tab.length) {
      this.$eventBus.$emit("tabChange", this.active_tab);
    }
  },

  methods: {

    isHidden(navigation) {
      
        if(!this.$root.current_user || !this.$root.current_user.uuid) {
            return true;
        }

        if(this.$root.current_user.hidden_navigations && this.$root.current_user.hidden_navigations.length > 0) {
            return this.$root.current_user.hidden_navigations.includes(navigation);
        }

        return false;
    },

    isActive (tab) {
      if (tab == this.active_tab) {
        return true;
      }
    },

    setTabLink(link) {

      if(link.indexOf('/') === 0) {
        return link+'?client='+this.uuid;
      }

      if(link.indexOf('http') !== 0) {
        return '/' + link+'?client='+this.uuid;
      }

      return link;

    },

    changeTab (tab) {
      this.$router.push({ query: Object.assign({}, this.$route.query, { tab: tab }) });
      localStorage.setItem(this.view + '-tab', tab);
      this.$eventBus.$emit('tabChange', tab);
      this.active_tab = tab;
    }
  }
}
</script>
