<template>
  <div>

    <page-tabs :page="$route.meta.base_url" />

    <div class="row">
      <div class="col-sm-12 col-md-3">
        <div class="card">
          <div class="card-body">
            <p class="card-title">
             Create
            </p>

            <form @submit.prevent="postForm()">
              <div class="form-group">
                <label for="name">Portal Name *</label>
                <input
                  id="name"
                  v-model="form.name"
                  class="form-control"
                  required
                  @change="genSubdomain"
                >
              </div>

              <div class="form-group">
                <label for="link">Subdomain *</label>
                <input
                  id="link"
                  v-model="form.link"
                  class="form-control"
                  required
                >
              </div>

              <div class="form-group">
                <label for="domain">Domain *</label>
                <select
                  id="domain"
                  v-model="form.domain"
                  class="form-control"
                  :disabled="processing"
                  required
                >
                  <template v-for="domain in domains">
                    <option :value="domain.domain">{{ domain.domain }}</option>
                  </template>
                </select>
              </div>



                <button
                  type="submit"
                  class="btn btn-success"
                  :disabled="processing"
                >
                  Add
                </button>


            </form>
          </div><!--Body-->
        </div><!--Card-->

        <div class="card my-3">
          <div class="card-body">
            <p class="card-title">Search {{ $route.meta.title }}s</p>

            <form @submit.prevent="searchRecords()">


              <div class="form-group">
                <label for="search">Search</label>
                <input
                  id="search"
                  v-model="queries.search"
                  class="form-control"
                  :disabled="processing"
                >
              </div>

              <div class="form-group">
                <label for="domain">Domain</label>
                <select
                  id="domain"
                  v-model="queries.domain"
                  class="form-control"
                  :disabled="processing"
                >
                  <option value="">Any</option>
                  <template v-for="domain in domains">
                    <option :value="domain.domain">{{ domain.domain }}</option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="link">Link</label>
                <input
                  id="link"
                  v-model="queries.link"
                  class="form-control"
                  :disabled="processing"
                >
              </div>

              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group custom-control custom-switch">
                    <input
                      id="archived"
                      v-model="queries.archived"
                      type="checkbox"
                      class="custom-control-input"
                      @change="searchRecords"
                      :disabled="processing"
                    >
                    <label
                      class="custom-control-label"
                      for="archived"
                    >Archived</label>
                  </div>
                </div><!--Col-->

                <div class="col-sm-12 col-md-6">
                  <div class="form-group custom-control custom-switch">
                    <input
                      id="trashed"
                      v-model="queries.trashed"
                      type="checkbox"
                      class="custom-control-input"
                      @change="searchRecords"
                      :disabled="processing"
                    >
                    <label
                      class="custom-control-label"
                      for="trashed"
                    >Trashed</label>
                  </div>
                </div><!--Col-->
              </div><!--Row-->

              <button
                type="submit"
                class="btn btn-primary"
                :disabled="processing"
              >
                Search
              </button>

            </form>

          </div>
        </div>

      </div><!--Col-->

      <div class="col-sm-12 col-md-9">

        <template v-if="!default_portal || !default_portal.uuid">
          <div class="alert alert-warning" :hidden="processing">
            No default portal found.
          </div>
        </template>

        <template v-if="default_portal && default_portal.uuid">
          <div class="alert alert-info" :hidden="processing">
            Default portal: <a :href="'/'+$route.meta.base_url+'/'+default_portal.uuid" class="alert-link">{{ default_portal.name }}</a>
          </div>
        </template>

        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 col-md-8">
                <p class="card-title">
                  {{ records.length }} of {{ total.toLocaleString() }} {{  $route.meta.title }}s
                </p>

              </div><!--Col-->

              <div class="col-sm-12 col-md-4 text-right">
                <div class="btn-group d-print-none">
                  <button type="button" class="btn btn-secondary" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
                  <button type="button" class="btn btn-secondary" @click="downloadCSV()" :hidden="!total" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
                </div>
              </div><!--Col-->

            </div><!--Row-->

            <div class="table-responsive my-3">
            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <template v-for="column in columns">
                    <th
                      class="text-uppercase"
                    >
                      <template v-if="column.key">
                        <span
                          class="text-capitalize link-hover"
                          @click="sortColumn(column)"
                        >{{ column.label }}</span>
                      </template>

                      <template v-if="!column.key">
                        <span class="text-capitalize">{{ column.label }}</span>
                      </template>

                      <template v-if="column.key == queries.sort">
                        <button
                          type="button"
                          class="btn btn-outline-secondary btn-sm ml-1"
                          :disabled="processing"
                          @click="updateSortDirection()"
                        >
                          <template v-if="queries.sortDirection == 'asc'">
                            <i class="fa fa-arrow-up" /> <span class="sr-only">Asc</span>
                          </template>

                          <template v-if="queries.sortDirection == 'desc'">
                            <i class="fa fa-arrow-down" /> <span class="sr-only">Desc</span>
                          </template>
                        </button>
                      </template>
                    </th>
                  </template>
                </tr>
              </thead>

              <tbody>
                <template v-if="records && records.length > 0">
                  <template v-for="record in records">
                    <tr>
                      <td><a :href="'/'+$route.meta.base_url+'/'+record.uuid"><i class="fa fa-edit"></i> Edit</a></td>
                      <td>{{ record.name }}</td>
                      <td>{{ record.link }}</td>
                      <td>{{ record.domain }}</td>
                      <td>
                        <template v-if="record.link && record.domain">
                          <a :href="'https://'+record.link+'.'+record.domain" target="_blank" class="btn btn-secondary btn-sm"><i class="fa fa-link"></i> Open Portal</a>
                        </template>
                      </td>
                      <td>{{ record.portal_reference }}</td>
                      <td>
                      <template v-if="record.default === 1">
                        <i class="fa fa-check text-success" /> <span class="sr-only">DEFAULT</span>
                      </template>
                      <template v-else>
                        <i class="fa fa-times text-danger" /> <span class="sr-only">NOT DEFAULT</span>
                      </template>
                      </td>
                      <td>
                        <template v-if="record.redirect">
                          <a
                            :href="record.redirect"
                            class="btn btn-outline-secondary btn-sm"
                          ><i class="fa fa-link" /> <span class="sr-only">Link</span></a>
                        </template>
                      </td>
                      <td>{{ record.merge_tag }}
                        <button type="button" class="btn btn-outline-secondary btn-sm" @click="copyToClipboard('{'+record.merge_tag+'}')"><i class="fa fa-copy" /> <span class="sr-only">Copy</span></button>
                      </td>
                      <td>{{ record.created_by }}</td>
                      <td>{{ record.created_at | datetime }}
                        <template v-if="record.archived_at">
                          <span class="badge badge-warning">Archived</span>
                        </template>
                        <template v-if="record.deleted_at">
                          <span class="badge badge-danger">Trashed</span>
                        </template>
                      </td>
                      <td>{{ record.updated_at | datetime }}</td>
                    </tr>
                  </template>
                </template>
              </tbody>
            </table>
            </div><!--Responsive-->

            <template v-if="!processing && !records.length">
              <div class="alert alert-warning">
                No {{  $route.meta.title }}s found.
              </div>
            </template>
          </div><!--Body-->
        </div><!--Card-->

        <template v-if="records && records.length && total > records.length">

        <div class="row mt-3">
          <div class="col-12 text-center">

              <button
                type="button"
                class="btn btn-outline-primary"
                :disabled="processing"
                @click="getRecords"
              >
                Load more
              </button>

          </div><!--Col-->
        </div><!--Row-->

        </template>

      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>
<script>
import _ from 'lodash';
import queries from '../mixins/queries';

export default {

  mixins: [ queries ],

  data () {
    return {
      processing: false,
      validating: false,
      total: 0,
      queries: {},
      records: [],
      form: {},
      columns: [
      { label: 'Edit'},
      { key: 'name', label: 'Name'},
      { key: 'link', label: 'Subdomain'},
      { key: 'domain', label: 'Domain'},
      { label: 'Link'},
      { key: 'reference', label: 'Reference'},
      { key: 'default', label: 'Default'},
      { key: 'redirect', label: 'Redirect'},
      { key: 'merge_tag', label: 'Merge Tag'},
      { key: 'creator', label: 'Creator'},
      { key: 'created_at', label: 'Created'},
      { key: 'updated_at', label: 'Updated'}
      ],
      domains: [],
      default_portal: {},
      errors: null
    }
  },

  mounted() {

    var self = this;

    this.$eventBus.$on('errors', function (errors) {
        self.errors = errors.data || errors;
    });
  },

  created() {
    this.resetForm();
    this.resetQueries();
    this.resetRecords();
    this.setURLParams();
    this.getRecords();
    this.getDomains();
    this.getDefault();
  },

  methods: {

    setURLParams() {

        var searchParams = new URLSearchParams(window.location.search);

        var self = this;

        searchParams.forEach(function(value, key) {
            self.queries[key] = value;
        });
    },
    
    resetQueries() {
      this.queries = {
        limit: 10,
        slim: true,
        sort: 'name',
        sortDirection: 'asc',
        archived: false,
        trashed: false
      }
    },

    resetForm() {
      this.form = {
        link: null,
        name: null,
        domain: 'ecase.io',
        geolocation: 'optional',
        verify: 'one-time-password',
        weather: false,
        default: 0,
        settings: {
          show_contact_info: true,
        }
      }
    },

    resetRecords() {
      this.records = [];
      this.total = 0;
      this.queries.skip = 0;
    },

    updateSortDirection() {

      if(this.processing) {
        return;
      }

      if(this.queries.sortDirection == 'asc') {
        this.queries.sortDirection = 'desc';
      } else {
        this.queries.sortDirection = 'asc';
      }

      this.resetRecords();

      this.getRecords();
    },

    searchRecords() {
      this.searched = true;
      this.resetRecords();
      this.getRecords();
    },

    sortColumn(column) {

      if(this.processing) {
        return;
      }

      this.queries.sort = column.key;

      this.updateSortDirection();
    },

    getRecords() {

      this.processing = true;

      this.updateURL();
      this.getTotal();

      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
      .then(response => {
        if(response && response.data) {
            
            if(response.data.length) {

                var self = this;

                response.data.forEach(function(data) {
                    self.records.push(data);
                });
            }
            
            this.queries.skip = this.records.length;

            this.processing = false;
        }
      })
    },

    getTotal() {
      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
      .then(response => {
        if(response && response.status === 200) {
          this.total = response.data;
          this.setTitle();
        }
      })
    },

    updateURL() {
      history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url, this.queries));
    },

    setTitle() {
      document.title = this.total + ' ' + this.$route.meta.title + 's | eCourtDate.com';
    },

    setApexDomain(value) {
      if(value) {
        var parts = value.split('.');
        if(parts.length > 2) {
          return parts[parts.length - 2] + '.' + parts[parts.length - 1];
        }
      }
      return value;
    },

    setSubdomain(value) {
      if(value) {
        var parts = value.split('.');
        if(parts.length > 2) {
          return parts[0];
        }
      }
      return value;
    },

    genSubdomain() {
      if(this.form.name) {
        this.form.link = this.$options.filters.slugString(this.form.name);
      }
    },

    postForm() {

      if(this.form.domain) {
        var domain = _.find(this.domains, {'domain': this.form.domain});

        if(domain.alias) {
          this.form.alias = domain.alias;
          this.form.domain = this.setApexDomain(domain.domain);
          this.form.link = this.setSubdomain(domain.domain);
        }
      }

      if(!this.form.link) {
        this.genSubdomain();
      }
      
      if(!this.default_portal || !this.default_portal.uuid) {
        this.form.default = 1;
      }

      this.form.welcome = {
        en: '<p>Welcome to the ' + this.form.name + ' portal.</p>'
      }
      
      this.processing = true;
      this.errors = null;
      this.$http.post('/'+this.$route.meta.base_url, this.form)
      .then(response => {
        if(response && response.data) {

          this.processing = false;

          if(response.status === 201) {

            if(!this.records) {
              this.records = [];
            }

            this.resetForm();
            this.total++;
            this.records.unshift(response.data);
            new this.$noty({text: this.$route.meta.title + ' created'}).show();
            this.$router.push('/'+this.$route.meta.base_url+'/'+response.data.uuid);
          }

        }
      })
    },

    getDomains() {
      this.$http.get('/domains?fields=domain,alias&slim=true&limit=100&sortDirection=asc&sort=domain&alias=ecase.io')
      .then(response => {
        if(response && response.status) {
          this.domains = response.data;
          
          this.domains.unshift({'domain': 'ecase.io'});
        }
      })
    },   

    getDefault() {
      this.$http.get('/portals?default=true&limit=1&fields=name,uuid&slim=true')
      .then(response => {
        if(response && response.status) {
          this.default_portal = response.data[0];
        }
      })
    }
  },
}
</script>