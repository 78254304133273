<template>
  <div>
    
    <onboarding-alert />

    <div class="row">
      <div class="col-sm-12 col-md-6 col-xl-3">

        <messages-form />

        <div class="card mb-3">
            <div class="card-body">

              <p class="card-title">Search Messages</p>

              <form @submit.prevent="searchRecords">

                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                        Search {{ queries.search_field }}
                      </button>
                      <div class="dropdown-menu">
                        <template v-for="search_field in search_fields">
                        <button type="button" class="dropdown-item" @click="setSearchField(search_field)" :disabled="processing">{{ search_field }}</button>
                        </template>
                      </div>
                    </div>
                    <input type="search" class="form-control" aria-label="Search messages" v-model="queries.search">
                  </div>


                <div class="form-group">
                  <label for="channel">Channel</label>
                  <select
                    id="channel"
                    v-model="queries.channel"
                    class="form-control"
                    @change="searchRecords()"
                    :disabled="processing"
                  >
                    <option value="">All</option>
                    <template v-for="channel in channels">
                      <option :value="channel">{{ channel }}</option>
                    </template>
                  </select>
                </div>

                <div class="form-group">
                  <label for="type">Type</label>
                  <select
                    id="type"
                    v-model="queries.type"
                    class="form-control"
                    :disabled="processing"
                    @change="searchRecords"
                  >
                    <option value="" />
                    <template v-for="message_type in message_types">
                      <option
                        :value="message_type"
                      >
                        {{ message_type }}
                      </option>
                    </template>
                  </select>
                </div>

                <div class="form-group vbDatePicker">
                  <label for="from_date">From Date</label>
                  <input
                    id="from_date"
                    v-model="queries.from_date"
                    placeholder="mm/dd/yyyy"
                    type="date"
                    class="form-control"
                  >
                </div>

                <div class="form-group vbTimePicker" v-if="queries.from_date">
                  <label for="from_time">From Time</label>
                  <input
                    id="from_time"
                    v-model="queries.from_time"
                    placeholder="hh:mm AM"
                    type="time"
                    class="form-control"
                  >
                </div>

                <div class="form-group vbDatePicker">
                  <label for="to_date">To Date</label>
                  <input
                    id="to_date"
                    v-model="queries.to_date"
                    placeholder="mm/dd/yyyy"
                    type="date"
                    class="form-control"
                  >
                </div>

                <div class="form-group vbTimePicker" v-if="queries.to_date">
                  <label for="to_time">To Time</label>
                  <input
                    id="to_time"
                    v-model="queries.to_time"
                    placeholder="hh:mm AM"
                    type="time"
                    class="form-control"
                  >
                </div>

                <div class="form-group custom-control custom-switch">
                <input
                    id="simulate"
                    v-model="queries.simulate"
                    type="checkbox"
                    class="custom-control-input"
                    @change="searchRecords()"
                    :disabled="processing"
                >
                <label
                    class="custom-control-label"
                    for="simulate"
                >Simulate Mode</label>
              </div>


                <button
                  type="submit"
                  class="btn btn-primary"
                  :disabled="processing"
                >
                  Search
                </button>
              </form>
            </div><!--Body-->
          </div><!--Card-->

          <messages-scheduled class="mb-3" />
      </div><!--Col-->
      <div class="col-sm-12 col-md-6 col-xl-9">

        <div class="row">
          <div class="col-sm-12 col-md-9">

            <div class="row">

              <div class="col-sm-12 col-md-9">

                <ul class="nav nav-tabs mb-3">
                    <li class="nav-item">
                      <button class="nav-link text-uppercase text-primary"
                      :class="isActiveDirection('all')"
                      @click="toggleDirection('all')"
                      >
                        All
                      </button>
                    </li>
                    <li class="nav-item">
                      <button class="nav-link text-uppercase text-primary"
                      :class="isActiveDirection('outbound')"
                      @click="toggleDirection('outbound')"
                      >
                      Outbound
                      <template v-if="totals.outbound">
                        <span class="badge badge-pill badge-primary">{{ totals.outbound }}</span>
                      </template>  
                      </button>
                    </li>
                    <li class="nav-item">
                      <button class="nav-link text-uppercase text-primary"
                      :class="isActiveDirection('inbound')"
                      @click="toggleDirection('inbound')"
                      >
                      Inbound
                      <template v-if="totals.inbound">
                        <span class="badge badge-pill badge-secondary">{{ totals.inbound }}</span>
                      </template>  
                      </button>
                    </li>
                </ul>

                </div><!--Col-->

                <div class="col-sm-12 col-md-3">


                </div><!--Col-->

                </div><!--Row-->

                <div class="row">
                  <div class="col-sm-12 col-md-6">
                    <div class="form-group custom-control custom-switch">
                      <input
                          id="failed"
                          v-model="queries.failed"
                          type="checkbox"
                          class="custom-control-input"
                          @change="searchRecords()"
                          :disabled="processing"
                      >
                      <label
                          class="custom-control-label"
                          for="failed"
                      >Failed</label>
                    </div>
                    <template v-if="queries.failed">
                      <div class="form-group">
                        <label for="error_code" class="sr-only">Error Code</label>
                        <select
                          id="error_code"
                          v-model="queries.error_code"
                          class="form-control"
                          @change="searchRecords()"
                          :disabled="processing"
                        >
                          <option value="">Any error</option>
                        <template v-for="(error_code, error_index) in error_codes">
                            <option :value="error_index">{{  error_index }} {{ error_code }}</option>
                          </template>
                        </select>
                        <template v-if="queries.failed && queries.error_code">
                          <p class="text-muted form-text m-0">
                            {{ setErrorDescription(queries.error_code) }}
                          </p>
                        </template>
                      </div>
                    </template>
                  </div><!--Col-->
                  <div class="col-sm-12 col-md-3">
                    <div class="form-group custom-control custom-switch">
                      <input
                          id="mms"
                          v-model="queries.mms"
                          type="checkbox"
                          class="custom-control-input"
                          @change="searchRecords()"
                          :disabled="processing"
                      >
                      <label
                          class="custom-control-label"
                          for="mms"
                      >Media</label>
                    </div>
                  </div><!--Col-->
                  <div class="col-sm-12 col-md-3 text-right">

                  </div><!--Col-->
                </div>

          </div><!--Col-->
          <div class="col-sm-12 col-md-3 text-right d-print-none">
            <div class="btn-group">
              <a href="/geolocations" 
              class="btn btn-outline-primary btn-sm"
              >
              Geos
            </a>
            <button class="btn btn-outline-primary btn-sm" 
              @click="toggleLayout()"
              :disabled="processing"
            >
            Layout
          </button>
          </div>

          </div><!--Col-->
        </div><!--Row-->

        <template v-if="layout == 'grid'">
          <messages-feed />        
        </template>
        <template v-if="layout == 'table'">
          <messages-table />
        </template>
      </div><!--Col-->
    </div><!--Row-->

  </div>
</template>
<script>
import queries from '../mixins/queries';
export default {

  mixins: [ queries ],

  data() {
    return {
      processing: false,
      layout: 'grid',
      queries: {},
      search_fields: ['to', 'from', 'content', 'subject'],
      channels: ['text', 'voice', 'email'],
      error_codes: [],
      error_reasons: [],
      message_types: [],
      users: [],
      totals: {
        inbound: 0,
        outbound: 0
      }
    }
  },

  created() {
    this.setLayout();
    this.setTitle();
    this.getErrorCodes();
    this.getErrorReasons();
    this.resetQueries();
    this.setURLParams();
    this.getTotals();
    this.getMessageTypes();
  },

  methods: {

    resetQueries() {
      this.queries = {
        channel: null,
        from: null,
        mms: null,
        simulate: null,
        search_field: 'to',
        status: '',
        direction: null,
        error_code: '',
        failed: false,
        sortDirection: 'desc',
        sort: 'scheduled_at'
      }
    },

    setURLParams() {

      let params = new URLSearchParams(window.location.search);

      const self = this;

      params.forEach(function(value, key) {
        self.queries[key] = value;
      });
    },

    searchRecords() {

      if(this.queries.direction == 'all') {
        this.queries.direction = '';
      }

      this.$eventBus.$emit('searchMessages', this.queries);
    },

    toggleDirection(direction) {
      this.queries.direction = direction;
      this.searchRecords();
    },

    toggleChannel(channel) {

      this.queries.channel = channel;
      
      if(this.queries.channel == 'all') {
        this.queries.channel = '';
      }

      this.queries.skip = 0;
      
      this.searchRecords();


    },

    toggleStatus(status) {
      this.queries.status = status;

      if(!this.queries.error_code) {
        this.queries.error_code = '';
      }

      if(this.queries.status == 'all') {
        this.queries.status = '';
      }

      if(this.queries.status == 'sent') {
        this.queries.direction = 'outbound';
        this.queries.sent = 1;
        // this.queries.error_code = 0;
      }

      if(this.queries.status == 'received') {
        this.queries.direction = 'inbound';
      }

      if(this.queries.status == 'failed') {
        this.queries.direction = 'outbound';
        this.queries.sent = 1;
        this.queries.error_code = 1;
      }

      this.queries.skip = 0;

      this.searchRecords();
    },

    isActiveDirection(direction) {
      if(!this.queries.direction && direction == 'all') {
        return 'active';
      }

      return this.queries.direction == direction ? 'active' : '';
    },

    setSearchField(search_field) {
      this.queries.search_field = search_field;
    },

    getTotals() {
      this.getTotalInbound();
      this.getTotalOutbound();
    },

    getTotalInbound() {
      this.$http.get('/messages/total?direction=inbound')
      .then(response => {
        if(response && response.status) {
          this.totals.inbound = response.data;
        }
      })
    },

    getTotalOutbound() {
      this.$http.get('/messages/total?direction=outbound&sent=1')
      .then(response => {
        if(response && response.status) {
          this.totals.outbound = response.data;
        }
      })
    },

    getTotalFailed() {
      this.$http.get('/messages/total?sent=1&error_code=1&direction=outbound')
      .then(response => {
        if(response && response.status) {
          this.totals.failed = response.data;
        }
      })
    },

    setLayout() {
      let layout = localStorage.getItem('layout') || this.layout;

      this.layout = layout;

      localStorage.setItem('layout', layout);
    },

    setTitle() {

      let title = 'Dashboard';


      if(this.settings && this.settings.name) {
        title += ' - '+this.settings.name;
      }

      document.title = title;
    },

    toggleLayout() {
      let layout = localStorage.getItem('layout') || this.layout;

      if(layout == 'table') {
        layout = 'grid';
      } else {
        layout = 'table';
      }

      localStorage.setItem('layout', layout);

      this.layout = layout;    
    },

    getErrorReasons() {
      this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/error_reasons.json')
      .then(response => {
        if(response && response.data) {
          this.error_reasons = response.data;
        }
      })
    },

    setErrorDescription(code) {
      
      if(!code) {
        return;
      }

      if(!this.error_reasons) {
        return;
      }

      if(this.error_reasons[code]) {
        return this.error_reasons[code];
      }
    },

    // getErrorCodes() {
    //   this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/error_codes.json')
    //   .then(response => {
    //     if(response && response.data) {
    //       this.error_codes = response.data;

    //       //sort by key
    //       this.error_codes = Object.keys(this.error_codes).sort().reduce((obj, key) => {
    //         obj[key] = this.error_codes[key];
    //         return obj;
    //       }, {});
    //     }
    //   })
    // },

    getMessageTypes() {
        this.$http.get('/message_types')
        .then(response => {
            if(response && response.data) {
               this.message_types = response.data;
            }
        })
    },

  }
}
</script>