<template>
  <div>

    <page-tabs page="bulk_actions" />

    <template v-if="templates && templates.length > 0">

      <p class="card-title">{{ templates.length }} Saved Templates</p>

      <div class="row">

      <template v-for="template in templates">

        <div class="col-sm-12 col-md-4">

        <div class="card mb-3">
          <div class="card-body">

            <div class="row mb-3">

              <div class="col-sm-12 col-md-9">
                <p class="card-title"><a :href="'/bulk_actions/'+template.uuid" target="_blank">{{ template.name }}</a></p>
              </div><!--Col-->

              <div class="col-sm-12 col-md-3 text-right">
                  <button type="button" class="btn btn-success btn-sm" @click="importRecord(template)" :disabled="processing"><i class="fa fa-download"></i> Import</button>
              </div><!--Col-->

            </div><!--Row-->

            <template v-if="template.type">
            <span class="badge badge-secondary my-3">{{ template.type }}</span>
            </template>

          <template v-if="template.action && template.action['messages'] && template.action['messages']['en']">

            <p class="sms outbound">{{ template.action['messages']['en'] }}</p>

            <div class="btn-group">
              <a :href="'/preview?content='+template.action['messages']['en']" class="btn btn-outline-secondary" :disabled="processing">Preview</a>
              <button type="button" class="btn btn-outline-secondary" @click="copyToClipboard(template.action['messages']['en'])" :disabled="processing">Copy</button>
            </div>

          </template>

          </div><!--Body-->
        </div><!--Card-->

        </div><!--Col-->

      </template>

      </div><!--Row-->

    </template>

    <template v-if="records && records.length > 0">
      <p class="card-title">{{ records.length }} Sample Templates</p>
    </template>

    <div class="row">

      <template v-for="(record, record_index) in records">

        <div class="col-sm-12 col-md-4">

        <div class="card mb-3">
          <div class="card-body">

            <div class="row mb-3">

              <div class="col-sm-12 col-md-9">
                <p class="card-title">{{ record.name }}</p>
              </div><!--Col-->

              <div class="col-sm-12 col-md-3 text-right">
                  <button type="button" class="btn btn-success btn-sm" @click="importRecord(record)" :disabled="processing"><i class="fa fa-download"></i> Import</button>
              </div><!--Col-->

            </div><!--Row-->

          <template v-if="record.action && record.action['messages'] && record.action['messages']['en']">

            <p class="sms outbound">{{ record.action['messages']['en'] }}</p>

            <div class="btn-group">
              <a :href="'/preview?content='+record.action['messages']['en']" class="btn btn-outline-secondary" :disabled="processing">Preview</a>
              <button type="button" class="btn btn-outline-secondary" @click="copyToClipboard(record.action['messages']['en'])" :disabled="processing">Copy</button>
            </div>

          </template>

          </div><!--Body-->
        </div><!--Card-->

        </div><!--Col-->

      </template>

    </div><!--Row--> 

  </div>
</template>
<script>
import _ from 'lodash';
import queries from '../mixins/queries';

export default {
  
  mixins: [ queries ],

  data () {
    return {
      processing: false,
      records: [],
      queries: {},
      triggers: [],
      templates: [],
      form: {}
    }
  },

  created () {
    this.resetQueries();
    this.resetForm();
    this.setURLParams();
    this.getTriggers();
    this.getRecords();
    this.getTemplates();
  },

  methods: {

    resetQueries() {
      this.queries = {
        slim: false,
        location: null
      }
    },

    setURLParams() {
          var searchParams = new URLSearchParams(window.location.search);

          var self = this;

          searchParams.forEach(function(value, key) {
              self.queries[key] = value;
          });
      },

    resetForm() {
      this.form = {
        name: null,
        type: 'Send a Message',
        status: 'draft',
        action: {
          messages: {
            'en': null
          }
        }
      }
    },

    getTriggers() {
        this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/options.json')
        .then(response => {
          if(response && response.data) {
            this.triggers = response.data.flow_message_triggers;
          }
        })
    },

    setMessageName(message) {
        var trigger = _.find(this.triggers, {'value': message.trigger});

        if(trigger && trigger.label) {
          return trigger.label;
        }

        return message.trigger;
    },

   getTemplates() {
    this.$http.get('/bulk_actions?template=1')
    .then(response => {
      if(response && response.data) {

          this.templates = response.data;
          this.processing = false;
        }

      })
   },

   getRecords() {
      this.processing = true;
      this.$axios.get('https://assets.ecourtdate.com/demo/bulk_actions.json')
      .then(response => {
        if(response && response.data) {
          this.records = response.data;
          this.processing = false;
        }
      })
    },

    importRecord(record) {

      this.form.name = record.name;
      this.form.action = record.action;
      this.form.search = record.search;
      this.form.type = record.type;
      this.form.template = 0;

      if(this.queries.location) {
        this.form.search.location = this.queries.location;
      }

      this.processing = true;
      this.$http.post('/bulk_actions', this.form)
      .then(response => {
        if(response && response.data) {
          
          this.processing = false;

          if(response.data.uuid) {
            this.resetForm();
            this.$router.push('/bulk_actions/'+response.data.uuid);
          }

        }
      })
    },

  }
}
</script>
