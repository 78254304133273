<template>
  <div>

    <page-tabs page="clients" />

    <div class="row">
      <div class="col-sm-12 col-md-3">

        <div class="card mb-3">
          <div class="card-body">
            <p class="card-title">Add {{ $route.meta.title }}</p>

            <form @submit.prevent="postForm()">

              <div class="form-group">
                <label for="lookup">Lookup</label>
                <input id="lookup" type="text" class="form-control" v-model="form.lookup" placeholder="Phone or Email" required>
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                    id="social"
                    v-model="form.social"
                    type="checkbox"
                    class="custom-control-input"
                    :disabled="processing"
                >
                <label
                    class="custom-control-label"
                    for="social"
                > Social Media</label>
              </div>

              <button type="submit" class="btn btn-success" :disabled="processing">Add</button>

            </form>

          </div>
        </div>

        <div class="card my-3">
          <div class="card-body">
            <p class="card-title">Person Lookup</p>

            <p class="card-text">Run a lookup on a person.</p>

            <form @submit.prevent="postPersonLookup()">

              <div class="form-group">
                <label for="name">Name</label>
                <input id="name" type="text" class="form-control" v-model="personLookup.name" required>
                <p class="form-text text-muted">First and Last Name</p>
              </div>

              <div class="form-group">
                <label for="location">Location</label>
                <input id="location" type="text" class="form-control" v-model="personLookup.location" required>
                <p class="form-text text-muted">City and/or State</p>
              </div>

              <button type="submit" class="btn btn-success" :disabled="processing">Add</button>

            </form>

            </div><!--Body-->
          </div><!--Card-->


        <div class="card my-3">
            <div class="card-body">

              <p class="card-title">IP Address Lookup</p>

              <form @submit.prevent="geolocateIP()">
                
                <div class="form-group">
                  <label for="geo_ip">IP Address</label>
                  <input id="geo_ip" type="text" class="form-control" v-model="geo_ip" required>
                </div>

                <button type="submit" class="btn btn-primary" :disabled="processing">Geolocate</button>

              </form>


            </div><!--Body-->
          </div><!--Card-->

        <div class="card">
          <div class="card-body">
            <form @submit.prevent="searchRecords()">
              <p class="card-title">
                Search {{ $route.meta.title}}s
              </p>

               <div class="form-group">
                <label for="search" class="form-label">Search</label>
                <input id="search" type="search" class="form-control" v-model="queries.search">
              </div>

              <div class="form-group">
                <label for="search_type" class="form-label">Type</label>
                <select id="search_type" class="form-control" v-model="queries.type" 
                @change="searchRecords()"
                :disabled="processing">
                  <option value=""></option>
                  <option value="phone">Phone</option>
                  <option value="email">Email</option>
                  <option value="person">Person</option>
                </select>
              </div>

              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group custom-control custom-switch">
                    <input
                        id="search_valid"
                        v-model="queries.valid"
                        type="checkbox"
                        class="custom-control-input"
                        @change="searchRecords()"
                        :disabled="processing"
                    >
                    <label
                        class="custom-control-label"
                        for="search_valid"
                    >Valid</label>
                  </div>
                </div><!--Col-->

                <div class="col-sm-12 col-md-6">
                  <div class="form-group custom-control custom-switch">
                    <input
                        id="search_invalid"
                        v-model="queries.invalid"
                        type="checkbox"
                        class="custom-control-input"
                        @change="searchRecords()"
                        :disabled="processing"
                    >
                    <label
                        class="custom-control-label"
                        for="search_invalid"
                    >Invalid</label>
                  </div>
                </div><!--Col-->
              </div><!--Row-->

              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group custom-control custom-switch">
                    <input
                        id="search_archived"
                        v-model="queries.archived"
                        type="checkbox"
                        class="custom-control-input"
                        @change="searchRecords()"
                        :disabled="processing"
                    >
                    <label
                        class="custom-control-label"
                        for="search_archived"
                    >Archived</label>
                  </div>
                </div><!--Col-->

                <div class="col-sm-12 col-md-6">
                  <div class="form-group custom-control custom-switch">
                    <input
                        id="search_trashed"
                        v-model="queries.trashed"
                        type="checkbox"
                        class="custom-control-input"
                        @change="searchRecords()"
                        :disabled="processing"
                    >
                    <label
                        class="custom-control-label"
                        for="search_trashed"
                    >Trashed</label>
                  </div>
                </div><!--Col-->
              </div><!--Row-->

              <button
                  type="submit"
                  class="btn btn-primary"
                  :disabled="processing"
              >
                Search
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->

      </div><!--Col-->

      <div class="col-sm-12 col-md-9">

        <div class="card">
          <div class="card-body">

            <div class="row">

              <div class="col-sm-12 col-md-6">

                <p class="card-title">
                  {{ records.length }} of {{ total.toLocaleString() }} {{  $route.meta.title }}s
                </p>

                </div><!--Col-->

              <div class="col-sm-12 col-md-6 text-right">

                <div class="btn-group d-print-none">
                  <button type="button" class="btn btn-secondary" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
                  <button type="button" class="btn btn-secondary" @click="downloadCSV()" :hidden="!total" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
                </div>
              </div><!--Col-->

            </div><!--Row-->

            <div class="table-responsive my-3">
              <table class="table table-striped table-hover">
                <sort-columns
                    :columns="columns"
                    :queries="queries"
                    :processing="processing"
                    :base_url="$route.meta.base_url"
                />

                <tbody>
                <template v-if="records && records.length > 0">
                  <template v-for="record in records">
                    <tr>
                      <td>
                        <a :href="'/'+$route.meta.base_url+'/'+record.uuid">
                          <i class="fa fa-edit"></i> Edit
                        </a>
                      </td>
                      <td>
                          {{ record.lookup }}
                      </td>
                      <td>{{ record.type }}</td>
                      <td>{{ record.status }}</td>
                      <td>
                        <template v-if="record.valid == 1">
                          <span class="badge badge-success">VALID</span>
                        </template>
                        <template v-else>
                          <span class="badge badge-danger">INVALID</span>
                        </template>
                      </td>
                      <td>{{ record.created_by }}</td>
                      <td>{{ record.created_at | datetime }}
                      <template v-if="record.archived_at">
                        <span class="badge badge-pill badge-warning">Archived</span>
                      </template>
                      <template v-if="record.deleted_at">
                        <span class="badge badge-pill badge-danger">Trashed</span>
                      </template>
                      </td>
                    </tr>
                  </template>
                </template>
                </tbody>
              </table>
            </div><!--Responsive-->

            <template v-if="!processing && records && !records.length">
              <div class="alert alert-warning">
                No {{ $route.meta.title }}s found.
              </div>
            </template>
          </div><!--Body-->
        </div><!--Card-->

        <template v-if="records && records.length && total > records.length">
          <div class="row mt-3">
            <div class="col-12 text-center">
              <button
                  type="button"
                  class="btn btn-outline-primary"
                  :disabled="processing"
                  @click="getRecords"
              >
                Load more
              </button>
            </div><!--Col-->
          </div><!--Row-->
        </template>
      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>
<script>
import queries from '../mixins/queries';

export default {

  mixins: [ queries ],

  data () {
    return {
      processing: false,
      downloading: false,
      searched: false,
      queries: {},
      records: [],
      downloads: [],
      total: 0,
      columns: [
        {label: 'Edit'},
        {key: 'lookup', label: 'Lookup'},
        {key: 'type', label: 'type'},
        {key: 'status', label: 'status'},
        {key: 'valid', label: 'valid'},
        {key: 'created_by', label: 'Creator'},
        {key: 'created_at', label: 'Created'}
      ],
      form: {},
      personLookup: {},
      errors: null
    }
  },

  mounted() {

    var self = this;

    this.$eventBus.$on('refreshRecords', function(view) {

      if(view !== self.$route.meta.base_url) {
        return;
      }

      self.resetRecords();
      self.getRecords();

    });

    this.$eventBus.$on('errors', function (errors) {
      self.errors = errors.data || errors;
      self.processing = false;
    });

  },

  created() {
    this.resetQueries();
    this.setURLParams();
    this.resetForm();
    this.getRecords();
  },

  methods: {

    setURLParams() {

      var searchParams = new URLSearchParams(window.location.search);

      var self = this;

      searchParams.forEach(function(value, key) {
        self.queries[key] = value;
      });
    },

    resetQueries() {
      this.queries = {
        search: null,
        type: null,
        client: null,
        valid: null,
        invalid: null,
        archived: false,
        trashed: false,
        skip: 0,
        sort: 'created_at',
        sortDirection: 'desc',
      }
    },

    resetForm() {
      this.form = {
        lookup: null,
        social: true
      }

      this.errors = null;
    },

    resetRecords() {
      this.records = [];
      this.total = 0;
      this.queries.skip = 0;
    },

    searchRecords() {
      this.searched = true;
      this.resetRecords();
      this.getRecords();
    },

    getRecords() {
      this.getTotal();
      this.processing = true;
      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
          .then(response => {
            if(response && response.data) {

              this.records = this.records.concat(response.data);
              this.queries.skip = this.records.length;

              this.processing = false;

            }
          })
    },

    getTotal() {
      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
          .then(response => {
            if(response && response.status === 200) {
              this.total = response.data;
              document.title = this.total + ' ' +this.$route.meta.title + 's | eCourtDate.com';
            }
          })
    },

    postForm() {
      this.processing = true;
      this.$http.post('/'+this.$route.meta.base_url, this.form)
          .then(response => {
            if(response && response.data) {
              this.processing = false;

              this.resetForm();

              if(response.data.uuid) {
                location.href = '/'+this.$route.meta.base_url+'/'+response.data.uuid;
              }
            }
          })
    },

    postPersonLookup() {
      this.processing = true;
      this.personLookup.type = 'person';
      this.personLookup.lookup = this.personLookup.name + ' ' + this.personLookup.location;
      this.$http.post('/'+this.$route.meta.base_url, this.personLookup)
          .then(response => {
            if(response && response.data) {
              this.processing = false;

              this.personLookup = {};

              if(response.data.uuid) {
                location.href = '/'+this.$route.meta.base_url+'/'+response.data.uuid;
              }
            }
          })
    },

    updateLookups(lookup) {
      this.processing = true;

      this.$root.current_user.current_agency.settings.auto_lookups = lookup;

        this.$http.patch('/settings?auto_lookups='+lookup)
            .then(response => {
                if(response && response.status === 200) {
                    new this.$noty({text: 'Auto-lookups updated.', type: 'success', timeout: 3000}).show();
                    this.processing = false;
                }
            })
    },


    geolocateIP() {

    if(!this.geo_ip) {
      return;
    }          

    window.location.href = '/ips/'+this.geo_ip;
    }

  },
}
</script>