<template>
  <div>

    <archived-trashed-status
        :form="form"
        :processing="processing"
        :title="$route.meta.title"
    />

    <div class="row">
      <div class="col-sm-12 col-md-8">
        <h1>{{ form.name }}</h1>
        <h3>{{ form.phone_number }}</h3>
        <p><router-link :to="{name: $route.meta.base_url+'.index'}">{{ $route.meta.title }}s</router-link> / Edit {{ $route.meta.title }}</p>
      </div><!--Col-->

      <div class="col-sm-12 col-md-4 text-right">
        <div class="btn-group">

          <div class="btn-group">
            <button
                type="button"
                class="btn btn-outline-primary dropdown-toggle radius-right-0"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            >
              Tools
            </button>
            <div class="dropdown-menu">
              <a
                  v-if="!form.archived_at"
                  class="dropdown-item"
                  @click="archiveRecord()"
              >Archive</a>
              <a
                  v-if="form.archived_at || form.deleted_at"
                  class="dropdown-item"
                  @click="restoreRecord()"
              >Restore</a>
              <a
                  v-if="!form.deleted_at"
                  class="dropdown-item"
                  @click="trashRecord()"
              >Trash</a>
            </div>
          </div><!--BtnGroup-->

          <button
              v-if="!form.archived_at && !form.deleted_at"
              type="button"
              class="btn btn-success"
              :disabled="processing"
              @click="postForm()"
          >
            Save
          </button>

          <router-link
              :to="{ name: $route.meta.base_url+'.index' }"
              class="btn btn-danger"
          >
            Close
          </router-link>

        </div>
      </div><!--Col-->
    </div><!--Row-->

    <div class="row">
      <div class="col-sm-12 col-md-6">
        <div class="card">
          <div class="card-body">

            <form
                @submit.prevent="postForm"
            >

              <div class="form-group">
                <label for="role_name">Role Name</label>
                <input
                    id="role_name"
                    v-model="form.name"
                    type="text"
                    class="form-control"
                    required
                >
              </div>
              <div class="form-group">
                <label for="permissions">Enabled Permissions
                  <small class="form-text text-muted">
                    <span v-if="chosenPermissions && chosenPermissions.length">{{ chosenPermissions.length }} of {{ permissions.length }} permissions enabled</span>
                  </small>
                </label><br>
                <multi-select
                    id="permissions"
                    v-model="chosenPermissions"
                    :multiple="true"
                    :close-on-select="false"
                    placeholder="Select Permissions"
                    :options="permissions"
                    label="name"
                    track-by="key"
                />
              </div>

              <div class="btn-group mb-3">

              <button
                  type="button"
                  class="btn btn-light"
                  @click="allPermissions"
              >
                Add All
              </button>
                <button
                    type="button"
                    class="btn btn-light"
                    @click="togglePermissions('create')"
                >
                  Create Permissions
                </button>
              <button
                  type="button"
                  class="btn btn-light"
                  @click="togglePermissions('view')"
              >
                View/Read Permissions
              </button>
                <button
                    type="button"
                    class="btn btn-light"
                    @click="togglePermissions('update')"
                >
                  Update Permissions
                </button>
                <button
                    type="button"
                    class="btn btn-light"
                    @click="togglePermissions('trash')"
                >
                  Trash Permissions
                </button>

              <button
                  type="button"
                  class="btn btn-light"
                  @click="resetPermissions"
              >
                Remove All
              </button>

              </div><!--Group-->


              <div class="form-group">
                <label for="navigations">Hidden Navigations
                  <small class="form-text text-muted">
                    <span v-if="chosenNavigations && chosenNavigations.length">{{ chosenNavigations.length }} of {{ navigations.length }} navigations hidden</span>
                  </small>
                </label><br>
                <multi-select
                    id="navigations"
                    v-model="chosenNavigations"
                    :multiple="true"
                    :close-on-select="false"
                    placeholder="Select Navigations"
                    :options="navigations"
                    label="name"
                    track-by="key"
                />

                <span class="form-text text-muted">Choose the navigations to hide for this role</span>
              </div>

              <div class="btn-group">

              <button
                  type="button"
                  class="btn btn-light"
                  @click="allNavigations"
              >
                Add All
              </button>
              <button
                  type="button"
                  class="btn btn-light"
                  @click="resetNavigations"
              >
                Remove All
              </button>

              </div><!--Group-->

              <br>

              <button type="submit" class="btn btn-success my-3" :disabled="processing">Save</button>

            </form>
          </div><!--Body-->
        </div><!--Card-->

        <audit-logs
            class="mt-3"
            :form="form"
        />
      </div><!--Col-->

      <div class="col-sm-12 col-md-6">
        <div class="card">
          <div class="card-body">

            <template v-if="form.name">
            <p class="card-title"><a :href="'/users?role='+form.name">{{ users.length }} Users</a></p>
            </template>

            <template v-if="users && users.length > 0">
              <ul class="list-group">
              <template v-for="user in users">
                <li class="list-group-item">
                  <router-link :to="{name: 'users.edit', params: {id: user.uuid}}">{{ user.name }} <template v-if="user.email != user.name">{{ user.email }}</template> {{ user.phone }}</router-link>
                </li>
              </template>
              </ul>
            </template>

          </div><!--Body-->
        </div><!--Card-->

      </div><!--Col-->

    </div><!--Row-->
  </div>
</template>
<script>
import queries from '../mixins/queries';

export default {

  mixins: [ queries ],

  data () {
    return {
      processing: false,
      total: 0,
      queries: {
        limit: 100,
        slim: true,
        fields: 'name,uuid,link,portal_reference,redirect,created_at',
        sort: 'name',
        sortDirection: 'asc'
      },
      users: [],
      form: {},
      chosenPermissions: [],
      chosenNavigations: [],
      permissions: [],
      navigations: [],
    }
  },

  created() {
    this.resetForm();
    this.setURLParams();
    this.getPermissions();
    this.getNavigations();
    this.getRecord();
  },

  methods: {

    setURLParams() {

      var searchParams = new URLSearchParams(window.location.search);

      var self = this;

      searchParams.forEach(function(value, key) {
        self.queries[key] = value;
      });
    },

    postForm() {
      this.processing = true;

      this.setForm();

      this.$http.patch('/'+this.$route.meta.base_url+'/'+this.form.uuid, this.form)
          .then(response => {
            if(response && response.data) {

              this.processing = false;

              if(response.data.updated_at) {
                this.form.updated_at = response.data.updated_at;
                new this.$noty({text: this.$route.meta.title + ' updated'}).show();
              }

            }
          })
    },

    resetForm() {
      this.form = {
        name: null,
        permissions: [],
        navigations: []
      }

      this.chosenPermissions = [];
      this.chosenNavigations = [];
    },

    getPermissions() {
      this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/permissions.json')
          .then(response => {
            if(response && response.data) {
              this.permissions = response.data;
            }
          })
    },

    getNavigations() {
      this.$axios.get('https://ecdassets.s3-us-gov-east-1.amazonaws.com/js/navigations.json')
          .then(response => {
            if(response && response.data) {
              this.navigations = response.data;
            }
          })
    },

    togglePermissions(permission) {
      var permissions = this.permissions.filter(r => r.key.includes(permission));

      if(!permissions || !permissions.length) {
        return;
      }

      var results = [];

      this.chosenPermissions.forEach(r => {
        if(!r.key.includes(permission)) {
          results.push(r);
        }
      });

      permissions.forEach(r => {
        results.push(r);
      });

      this.chosenPermissions = results;
    },

    allPermissions() {
      this.chosenPermissions = this.permissions;
    },

    resetPermissions() {
      this.chosenPermissions = [];
    },

    allNavigations() {
      this.chosenNavigations = this.navigations;
    },

    resetNavigations() {
      this.chosenNavigations = [];
    },

    setForm() {
      if(this.chosenPermissions && this.chosenPermissions.length) {
        this.form.permissions = this.chosenPermissions.map(r => r.key);
      } else {
        this.form.permissions = null;
      }
      if(this.chosenNavigations && this.chosenNavigations.length) {
        this.form.navigations = this.chosenNavigations.map(r => r.key);
      } else {
        this.form.navigations = null;
      }
    },

    setFields() {

      if(this.form.permissions && this.form.permissions.length) {
        this.chosenPermissions = this.permissions.filter(r => this.form.permissions.includes(r.key));
      } else {
        this.chosenPermissions = [];
      }

      if(this.form.navigations && this.form.navigations.length) {
        this.chosenNavigations = this.navigations.filter(r => this.form.navigations.includes(r.key));
      } else {
        this.chosenNavigations = [];
      }
    },

    getRecord() {
      this.processing = true;
      this.$http.get('/'+this.$route.meta.base_url+'/'+this.$route.params.id)
          .then(response => {
            if(response && response.data) {

              if(response.data.uuid) {
                this.form = response.data;
                this.setTitle();
                this.setFields();

                this.getUsers();
              }

              this.processing = false;
            }
          })
    },

    getUsers() {
      if(!this.form.name) {
        return;
      }

      this.$http.get('/users?limit=100&fields=uuid,name,phone,email&role='+this.form.name)
          .then(response => {
            if(response && response.data) {
              this.users = response.data;
            }
          })

    },

    setTitle() {
      document.title = this.form.name + ' | Edit ' + this.$route.meta.title;
    },

    restoreRecord () {
      this.processing = true;
      this.$http.put('/'+this.$route.meta.base_url + '/' + this.form.uuid + '/restore')
          .then(response => {
            if (response && response.data) {

              this.processing = false;

              if(response.status === 200) {
                new this.$noty({text: this.$route.meta.title + ' restored'}).show();
                this.form.deleted_at = null;
                this.form.archived_at = null;
              }

            }
          })
    },

    archiveRecord () {
      this.processing = true
      this.$http.put('/'+this.$route.meta.base_url + '/' + this.form.uuid + '/archive')
          .then(response => {
            if (response && response.data) {

              this.processing = false;

              if(response.status === 200) {
                new this.$noty({text: this.$route.meta.title + ' archived'}).show()
                this.form.archived_at = new Date().toISOString();
              }

            }

          })
    },

    trashRecord () {
      this.processing = true;
      this.$http.delete('/'+this.$route.meta.base_url + '/' + this.form.uuid)
          .then((response) => {
            if(response && response.status) {
              this.processing = false;

              if(response.status === 204) {
                new this.$noty({text: this.$route.meta.title + ' trashed'}).show();
                this.form.deleted_at = new Date().toISOString();
              }
            }
          })
    },


  },
}
</script>