<template>
  <div>

    <page-tabs page="cases" />

    <div class="row">
      <div class="col-sm-12 col-md-3">
        <template v-if="client && client.uuid">
          <div class="card mb-3">
            <div class="card-body">
              <div class="row mb-3">
                <div class="col-sm-12 col-md-6">
                  <p class="card-title">
                    Client <router-link :to="{ 'name': 'clients.edit', 'params': {'id': client.uuid} }">
                      {{ client.first_name }} {{ client.middle_name }} {{ client.last_name }}
                    </router-link>
                  </p>
                </div><!--Col-->

                <div class="col-sm-12 col-md-6 text-right">
                  <button
                    type="submit"
                    class="btn btn-danger btn-sm"
                    data-toggle="tooltip"
                    data-tooltip="Close client"
                    :disabled="processing"
                    @click="closeClient"
                  >
                    <i class="fa fa-times" /> <span class="sr-only">Close</span>
                  </button>
                </div><!--Col-->
              </div><!--Row-->

              <ul class="list-group">
                <template v-if="client.client_reference">
                  <li class="list-group-item">
                    {{ setCustomField('clients', 'client_reference') }} {{ client.client_reference }}
                  </li>
                </template>

                <template v-if="client.type">
                  <li class="list-group-item">
                    {{ setCustomField('clients', 'type') }} {{ client.type }}
                  </li>
                </template>

                <template v-if="client.group">
                  <li class="list-group-item">
                    {{ setCustomField('clients', 'group') }} {{ client.group }}
                  </li>
                </template>
              </ul>
            </div><!--Body-->
          </div><!--Card-->
        </template>

        <template v-if="client && !client.uuid">
          <client-search />
        </template>

        <div class="card">
          <div class="card-body">
            <p class="card-title">
              
              Add {{ title }}

              <template v-if="client && client.uuid">
                for {{ client.client_reference }} {{ client.first_name }} {{ client.last_name }}
              </template>
            </p>

            <form @submit.prevent="postForm">
              <div class="form-group">
                <label for="case_number">{{ setCustomField('client_cases', 'case_number') }}</label>
                <input
                  id="case_number"
                  v-model="form.case_number"
                  type="text"
                  class="form-control"
                  :disabled="processing"
                >
              </div>

              <button
                type="submit"
                class="btn btn-success"
                :disabled="processing"
              >
                Add
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->

        <div class="card mt-3">
          <div class="card-body">

            <p class="card-title">Search {{ title }}s</p>

            <form @submit.prevent="searchRecords()">
              <div class="form-group">
                <label for="search">Search</label>
                <input
                  id="search"
                  v-model="queries.search"
                  type="search"
                  class="form-control"
                  placeholder="Search..."
                >
              </div>

              <div class="form-group">
                <label for="search_location">{{ setCustomField('client_cases', 'location') }}</label>
                <select
                    id="search_location"
                    v-model="queries.location"
                    class="form-control"
                    :disabled="processing"
                >
                  <option value="">
                    Any
                  </option>
                  <option value="none">
                    No Location
                  </option>
                  <template v-if="locations && locations.length > 0">
                    <template v-for="search_location in locations">
                      <option
                          :value="search_location.uuid"
                      >
                        {{ search_location.name }}
                      </option>
                    </template>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="attorneys">{{ setCustomField('client_cases', 'attorney') }}</label>
                <select
                  id="attorneys"
                  v-model="queries.attorneys"
                  class="form-control"
                  :disabled="processing"
                >
                  <option value="">
                    Any
                  </option>
                  <template v-if="attorneys && attorneys.length > 0">
                    <template v-for="attorney in attorneys">
                      <option
                        :value="attorney.uuid"
                      >
                        {{ attorney.bar_number }} {{ attorney.firm_name }}
                      </option>
                    </template>
                  </template>
                </select>
              </div>

              <div class="form-group" :hidden="!processing && !statuses.length">
                <label for="search_status">{{ setCustomField('client_cases', 'status') }}</label>
                <select
                  id="search_status"
                  v-model="queries.status"
                  class="form-control"
                  :disabled="processing"
                >
                  <option value="">
                    Any
                  </option>
                  <template v-if="statuses && statuses.length > 0">
                    <template v-for="search_status in statuses">
                      <option
                        :value="search_status.name"
                      >
                        {{ search_status.name }}
                      </option>
                    </template>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="search_type">{{ setCustomField('client_cases', 'type') }}</label>
                <select
                  id="search_type"
                  v-model="queries.type"
                  class="form-control"
                  :disabled="processing"
                >
                  <option value="">
                    Any
                  </option>
                  <template v-if="case_types && case_types.length > 0">
                    <template v-for="case_type in case_types">
                      <option
                        :value="case_type"
                      >
                        {{ case_type }}
                      </option>
                    </template>
                  </template>
                </select>
              </div>
              
              <template v-if="!processing && !statuses.length">
                  <div class="alert alert-info">
                    <a href="/statuses?type=case" class="alert-info" target="_blank">No case statuses found</a>

                    <button type="button" class="btn btn-secondary btn-sm float-right" @click="getStatuses()" :disabled="processing">
                      <i class="fa fa-refresh"></i>
                    </button>

                  </div>

              </template>

              <div class="form-group">
                <label for="search_file_date">{{ setCustomField('client_cases', 'file_date') }}</label>
                <input
                  id="search_file_date"
                  v-model="queries.file_date"
                  type="date"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="search_arrest_date">{{ setCustomField('client_cases', 'arrest_date') }}</label>
                <input
                  id="search_arrest_date"
                  v-model="queries.arrest_date"
                  type="date"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="search_offense_date">{{ setCustomField('client_cases', 'offense_date') }}</label>
                <input
                  id="search_offense_date"
                  v-model="queries.offense_date"
                  type="date"
                  class="form-control"
                >
              </div>


              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group custom-control custom-switch">
                    <input
                      id="archived"
                      v-model="queries.archived"
                      type="checkbox"
                      class="custom-control-input"
                      @change="searchRecords()"
                      :disabled="processing"
                    >
                    <label
                      class="custom-control-label"
                      for="archived"
                    >Archived</label>
                  </div>
                </div><!--Col-->

                <div class="col-sm-12 col-md-6">
                  <div class="form-group custom-control custom-switch">
                    <input
                      id="trashed"
                      v-model="queries.trashed"
                      type="checkbox"
                      class="custom-control-input"
                      @change="searchRecords()"
                      :disabled="processing"
                    >
                    <label
                      class="custom-control-label"
                      for="trashed"
                    >Trashed</label>
                  </div>
                </div><!--Col-->
              </div><!--Row-->

              <button
                type="submit"
                class="btn btn-primary"
                :disabled="processing"
              >
                Search
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->


        <div class="card my-3">
          <div class="card-body">
            
            <p class="card-title">Assign Cases</p>

            <p class="card-text">Auto-assign cases to attorneys, judges, and locations.</p>

            <form @submit.prevent="assignCases">
              <div class="form-group">
                <label for="assign_location">Assign Location</label>
                <select
                  id="assign_location"
                  v-model="assignCaseForm.location"
                  class="form-control"
                  :disabled="processing"
                >
                  <option value="">
                    Skip
                  </option>
                  <option value="random">
                    Random
                  </option>
                  <template v-if="locations && locations.length > 0">
                    <template v-for="location in locations">
                      <option
                        :value="location.uuid"
                      >
                        {{ location.name }}
                      </option>
                    </template>
                  </template>
                </select>
              </div>

              <template v-if="assignCaseForm.location == 'random'">
                <div class="form-group">
                  <label for="max_per_location">Max per Location</label>
                  <input
                    id="max_per_location"
                    v-model="assignCaseForm.max_per_location"
                    type="number"
                    class="form-control"
                    :disabled="processing"
                  >
                </div>
              </template>

              <div class="form-group">
                <label for="assign_judge">Assign Judge</label>
                <select
                  id="assign_judge"
                  v-model="assignCaseForm.judge"
                  class="form-control"
                  :disabled="processing"
                >
                  <option value="">
                    Skip
                  </option>
                  <option value="random">
                    Random
                  </option>
                  <template v-if="judges && judges.length > 0">
                    <template v-for="judge in judges">
                      <option
                        :value="judge.uuid"
                      >
                        {{ judge.name }}
                      </option>
                    </template>
                  </template>
                </select>
              </div>

              <template v-if="assignCaseForm.judge == 'random'">
                <div class="form-group">
                  <label for="max_per_judge">Max per Judge</label>
                  <input
                    id="max_per_judge"
                    v-model="assignCaseForm.max_per_judge"
                    type="number"
                    class="form-control"
                    :disabled="processing"
                  >
                </div>
              </template>

              <div class="form-group">
                <label for="assign_attorney">Assign Attorney</label>
                <select
                  id="assign_attorney"
                  v-model="assignCaseForm.attorney"
                  class="form-control"
                  :disabled="processing"
                >
                  <option value="">
                    Skip
                  </option>
                  <option value="random">
                    Random
                  </option>
                  <template v-if="attorneys && attorneys.length > 0">
                    <template v-for="attorney in attorneys">
                      <option
                        :value="attorney.uuid"
                      >
                        {{ attorney.bar_number }} {{ attorney.firm_name }}
                      </option>
                    </template>
                  </template>
                </select>
              </div>

              <template v-if="assignCaseForm.attorney == 'random'">
                <div class="form-group">
                  <label for="max_per_attorney">Max per Attorney</label>
                  <input
                    id="max_per_attorney"
                    v-model="assignCaseForm.max_per_attorney"
                    type="number"
                    class="form-control"
                    :disabled="processing"
                  >
                </div>
              </template>

              <template v-if="assignCaseForm.attorney">
                
                <div class="form-group custom-control custom-switch">
                    <input
                      id="notify_attorney"
                      v-model="assignCaseForm.notify_attorney"
                      type="checkbox"
                      class="custom-control-input"
                      :disabled="processing"
                    >
                    <label
                      class="custom-control-label"
                      for="notify_attorney"
                    >Notify Attorney</label>
                  </div>
              
              </template>

              <button
                type="submit"
                class="btn btn-success"
                :disabled="processing"
              >Assign</button>

            </form>


          </div><!--Body-->
        </div><!--Card-->

      </div><!--Col-->

      <div class="col-sm-12 col-md-9">
        <div class="card">
          <div class="card-body">
            <div class="row">

              <div class="col-sm-12 col-md-6">

                <p class="card-title">
                  {{ records.length }} of {{ total.toLocaleString() }} {{  title }}s
                </p>

              </div><!--Col-->

              <div class="col-sm-12 col-md-6 text-right">

                <div class="btn-group d-print-none">
                  <button type="button" class="btn btn-secondary" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
                  <button type="button" class="btn btn-secondary" @click="downloadCSV()" :hidden="!total" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
                </div>
              </div><!--Col-->

              </div><!--Row-->

            <div class="table-responsive my-3">
              <table class="table table-striped table-hover">
                <tr>
                  <template v-for="column in columns">
                    <th>
                      <template v-if="column.key">
                        <span
                          class="text-capitalize link-hover"
                          @click="sortColumn(column)"
                        >{{ setCustomField('client_cases', column.key) }}</span>
                      </template>

                      <template v-if="!column.key">
                        <span class="text-capitalize">{{ column.label }}</span>
                      </template>

                      <template v-if="column.key == queries.sort">
                        <button
                          type="button"
                          class="btn btn-outline-secondary btn-sm ml-1"
                          :disabled="processing"
                          @click="updateSortDirection()"
                        >
                          <template v-if="queries.sortDirection == 'asc'">
                            <i class="fa fa-arrow-up" /> <span class="sr-only">Asc</span>
                          </template>

                          <template v-if="queries.sortDirection == 'desc'">
                            <i class="fa fa-arrow-down" /> <span class="sr-only">Desc</span>
                          </template>
                        </button>
                      </template>
                    </th>
                  </template>
                </tr>
                <tbody>
                  <template v-for="record in records">
                    <tr>
                      <td>
                        <a :href="'/'+$route.meta.base_url+'/'+record.uuid">
                          <i class="fa fa-edit"></i> Edit
                        </a>
                      </td>
                      <td>
                         {{ record.case_number }}
                      </td>
                      <td>
                        {{ record.codes }}
                      </td>
                      <td>
                        <template v-if="record.client">
                          <client-button :uuid="record.client" />
                        </template>
                      </td>
                      <td>
                        <template v-if="record.location">
                          <a :href="'/locations/'+record.location">{{ setLocationName(record) }}</a>
                        </template>
                      </td>
                      <td>
                        <template v-if="record.status">
                            <span class="badge badge-pill badge-secondary">{{ record.status }}</span>
                        </template>
                      </td>
                      <td>{{ record.type }}</td>
                      <td>
                        <template v-if="record.attorney && record.attorney.bar_number">
                          <a :href="'/attorneys/'+record.attorney.uuid" target="_blank">{{ record.attorney.bar_number }} {{ record.attorney.firm_name }}</a>
                        </template>
                      </td>
                      <td>{{ record.description }}</td>
                      <td>{{ record.conditions }}</td>
                      <td>{{ record.file_date | date }}</td>
                      <td>{{ record.arrest_date | date }}</td>
                      <td>{{ record.offense_date | date }}</td>
                      <td>{{ record.offense_description }}</td>
                      <td>{{ record.created_at | datetime }}
                      <template v-if="record.archived_at">
                        <span class="badge badge-warning">Archived</span>
                      </template>
                      <template v-if="record.trashed_at">
                        <span class="badge badge-danger">Trashed</span>
                      </template>
                      </td>
                      <td>{{ record.updated_at | datetime }}</td>
                    </tr>
                  </template>
                </tbody>
              </table>

            <template v-if="!processing && records && !records.length">
              <div class="alert alert-warning">
                No {{ title }}s found.
              </div>
            </template>

            </div>
          </div><!--Body-->
        </div><!--Card-->

        <template v-if="records && records.length && total > records.length">
          <div class="row mt-3">
            <div class="col-12 text-center">
              <button
                type="button"
                class="btn btn-outline-primary"
                :disabled="processing"
                @click="getRecords"
              >
                Load more
              </button>
            </div><!--Col-->
          </div><!--Col-->
        </template>
      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>

<script>
import queries from '../mixins/queries';
import _ from "lodash";

export default {
    mixins: [ queries ],
    data () {
        return {
            processing: false,
            searching: false,
            downloading: false,
            title: null,
            errors: null,
            search_clients: '',
            total: 0,
            form: {},
            client: {},
            clients: [],
            records: [],
            downloads: [],
            statuses: [],
            case_types: [],
            queries: {
                search: '',
                case_number: '',
                channel: '',
                optin: '',
                arrest_date: null,
                offense_date: null,
                file_date: null,
                offense_description: null,
                limit: 10,
                skip: 0,
                slim: true,
                upload: null,
                archived: false,
                trashed: false,
                sort: 'created_at',
                sortDirection: 'desc'
            },
            columns: [
            { label: 'Edit'},
            { key: 'case_number', label: 'Case Number'},
            { key: 'codes', label: 'Codes'},
            { key: 'client', label: 'Client'},
            { key: 'location', label: 'Location'},
            { key: 'status', label: 'Status'},
            { key: 'type', label: 'Type'},
            { label: 'Attorney'},
            { key: 'description', label: 'Description'},
            { key: 'conditions', label: 'Conditions'},
            { key: 'file_date', label: 'File Date'},
            { key: 'arrest_date', label: 'Arrest Date'},
            { key: 'offense_date', label: 'Offense Date'},
            { key: 'offense_description', label: 'Offense'},
            { key: 'created_at', label: 'Created'},
            { key: 'updated_at', label: 'Updated'}
            ],
            attorneys: [],
            locations: [],
            judges: [],
            assignCaseForm: {}
        }
    },

    mounted() {

        var self = this;

        this.$eventBus.$on('search_clients', function(uuid) {
           self.queries.client = uuid;
           self.getClient();

        });

        this.$eventBus.$on('errors', function (errors) {
            self.processing = false;
            self.errors = errors.data || errors;
        });

    },

    created () {

        this.resetForm();

        this.title = this.setCustomLabel(this.$route.meta.title);

        this.resetAssignCaseForm();
        this.resetQueries();
        this.setURLParams();

        if(this.queries.client) {
            this.getClient();
        }

        if(!this.queries.client) {
            this.getRecords();        
        }

        this.getLocations();
        this.getJudges();
        this.getAttorneys();
    },

    methods: {

        setURLParams() {
            var searchParams = new URLSearchParams(window.location.search);

            var self = this;

            searchParams.forEach(function(value, key) {
                self.queries[key] = value;
            });

        },

        resetQueries() {
          this.queries = {
            search: null,
            location: null,
            status: null,
            type: null,
            attorneys: null,
            archived: false,
            trashed: false,
            sort: 'created_at',
            sortDirection: 'desc',
            limit: 10,
            skip: 0
          }
        },

        searchRecords () {
            this.resetRecords();
            this.getRecords();
        },

        resetRecords() {
            this.records = [];
            this.total = 0;
            this.queries.skip = 0;
        },

        getRecords () {

            this.updateURL();

            this.getTotal();

            this.processing = true;
            this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
            .then(response => {
                if(response && response.data) {

                    if(response.data.length) {

                        let self = this;

                        response.data.forEach(function(data) {
                            self.records.push(data);
                        });
                    }

                    this.queries.skip = this.records.length;
                    this.processing = false;

                    this.getStatuses();

                    if(this.settings && this.settings.case_types) {
                      this.case_types = this.settings.case_types.split(',');
                    }

                }
            })
        },

        updateURL() {
          history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url, this.queries));
        },

        setTitle() {
          document.title = this.total + ' ' + this.title + 's | eCourtDate.com';
        },

        getTotal() {
            this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
            .then(response => {
                if(response && response.status === 200) {
                    this.total = response.data;
                    this.setTitle();
                }
            })
        },

        closeClient() {
            this.queries.client = null;
            this.client = {};
            this.resetRecords();
            this.getRecords();
        },

        getClient() {

            if(!this.queries.client) {
                this.client = {};
                return;
            }

            this.$http.get('/clients/'+this.queries.client+'?slim=true')
            .then(response => {
                if(response && response.data) {
                    this.client = response.data;
                    this.resetRecords();
                    this.getRecords();
                }
            })
        },

        updateSortDirection() {

          if(this.queries.sortDirection == 'asc') {
            this.queries.sortDirection = 'desc';
          } else {
            this.queries.sortDirection = 'asc';
          }

          this.resetRecords();

          this.getRecords();
        },

        sortColumn(column) {

          this.queries.sort = column.key;

          this.updateSortDirection();

        },

        resetForm() {
            this.form = {
                case_number: null,
                client: null
            }

            this.errors = null;
        },

        postForm() {

            if(this.client && this.client.uuid) {
                this.form.client = this.client.uuid;
            }
            
            this.processing = true;

            this.errors = null;

            this.$http.post('/'+this.$route.meta.base_url, this.form)
            .then(response => {
                if(response && response.data) {

                    if(response.data.uuid) {
                        this.resetForm();
                        this.records.unshift(response.data);
                        this.total++;
                        new this.$noty({text: this.title + ' created'}).show();
                    }

                    this.processing = false;
                }
            })
        },


        searchClients() {
            this.searching = true;
            this.$http.get('/search?search='+this.search_clients)
            .then(response => {
                if(response && response.data) {
                    this.searching = false;
                    this.clients = response.data;
                }
            })
        },

        setClientCase(client) {
            this.form.client = client.uuid;
            this.queries.client = client.uuid;
            this.clients = [];
            this.getRecords();
        },


      setLocationName(record) {

        if(!record.location) {
          return;
        }

        var location = _.find(this.locations, {'uuid': record.location});

        if(!location || !location.name) {
          return;
        }

        return location.name;
      },

      getLocations () {
        this.$http.get('/locations?limit=100&slim=true&fields=name,uuid&sort=name&sortDirection=asc')
            .then(response => {
              if (response && response.data) {
                this.locations = response.data;
              }
            })
      },

      getJudges () {
        this.$http.get('/judges?limit=100&slim=true&fields=name,uuid&sort=name&sortDirection=asc')
            .then(response => {
              if (response && response.data) {
                this.judges = response.data;
              }
            })
      },

      getAttorneys () {
        this.$http.get('/attorneys?limit=100&slim=true&fields=firm_name,bar_number,uuid&sort=name&sortDirection=asc')
            .then(response => {
              if (response && response.data) {
                this.attorneys = response.data;
              }
            })
      },

      getStatuses() {

        if(!this.processing) {
          this.processing = true;
        }

        this.$http.get('/statuses?slim=true&fields=name,uuid&limit=100&sort=name&sortDirection=asc&type=case')
            .then(response => {
              if (response && response.data) {
                this.statuses = response.data;
                if(this.processing === true) {
                  this.processing = false;
                }
              }
            })
      },

      resetAssignCaseForm() {
        this.assignCaseForm = {
          location: null,
          judge: null,
          attorney: null,
          type: null,
          status: null,
          max_per_attorney: 10,
          max_per_judge: 10,
          max_per_location: 10,
          notify_attorney: true
        }
      },

      assignCases() {
          
          this.processing = true;

          var params = ['type', 'status'];

          params.forEach(param => {
            if(this.queries[param] && this.queries[param] !== null) {
              this.assignCaseForm[param] = this.queries[param];
            }
          });

          this.$http.post('/cases/assign', this.assignCaseForm)
              .then(response => {
                if (response && response.status && response.status === 202) {
                  this.processing = false;
                  this.resetAssignCaseForm();
                  new this.$noty({text: 'Assigning cases'}).show();
                }
              })
        },
      
    }
}
</script>
