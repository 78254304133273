<template>
  <div>
    <div class="card">
        <div class="card-body">

            <p class="card-title">Upload {{ $route.meta.title }}s</p>

            <p class="card-text">Upload a CSV file to bulk upload locations.</p>

            <p><a href="https://assets.ecourtdate.com/files/bulk-upload-locations.csv" target="_blank" class="btn btn-secondary btn-sm"><i class="fa fa-file-csv"></i> Sample File</a></p>

           <form @submit.prevent="postForm()">

              <input type="file" ref="file" accept=".csv" />

               <div class="form-group custom-control custom-switch mt-3">
                 <input
                     id="overwrite"
                     v-model="form.overwrite"
                     type="checkbox"
                     class="custom-control-input"
                     :disabled="processing"
                 >
                 <label
                     class="custom-control-label"
                     for="overwrite"
                 >Update Existing</label>
               </div>

               <p v-if="form.overwrite === true">Update locations that already exist.</p>
               <p v-if="form.overwrite === false">Skip locations that already exist.</p>

              <button type="submit" class="btn btn-success" :disabled="processing">Upload</button>
           </form>

        </div><!--Body-->
    </div><!--Card-->

  </div>
</template>


<script>
import queries from '../mixins/queries';
export default {

  mixins: [ queries ],

  data() {
    return {
        results: [],
        processing: false,
        form: {
            overwrite: true
        }
    }
},

methods: {
      

    postForm() {

      if(!this.$refs.file.files.length) {
        new this.$noty({text: 'No file selected', type: 'error'}).show();
        return;
      }

         const input = this.$refs.file.files[0];
         const reader = new FileReader();

         this.processing = true;

         var self = this;

         reader.onload = function (e) {
            const data = e.target.result;
            const results = self.readResults(data);

            if(!results) {
              return;
            }

           results.forEach(function(r) {
             self.processResult(r);
           });

            var time = results.length;

             setTimeout(function() {
               self.processing = false;
             }, time * 500);
         };

         reader.readAsText(input);

        this.$refs.file.name = null;
        this.$refs.file.value = null;
    },

    processResult(r) {

      var name = r['Name'] || r['name'] || '';

      if(!name) {
        new this.$noty({text: this.$route.meta.title +' invalid', type: 'error'}).show();
        return;
      }

      this.$http.get('/'+this.$route.meta.base_url+'?slim=1&fields=uuid&slim=true&name='+name)
          .then(response => {
            if(response && response.data) {

              if(!response.data[0] || !response.data[0]['uuid']) {
                this.geocodeResult(r);
                return;
              }

              if(this.form.overwrite == false) {
                new this.$noty({text: this.$route.meta.title + ' skipped'}).show();
                return;
              }

              var post = this.setResultPost(r);

              delete post['location_reference'];

              this.$http.patch('/'+this.$route.meta.base_url+'/'+response.data[0]['uuid'], post)
                .then(response => {
                  if(response && response.data) {
                      if(response.data.updated_at) {
                        new this.$noty({text: this.$route.meta.title + ' updated'}).show();
                      }
                  }
                })
            }
          })
    },

  setGeocodeQueries(r) {
    return {
      'address': r['Address'] || r['address'] || r['address_1'] || r['address 1'] || '',
      'address_2': r['Address 2'] || r['address 2'] || r['address_2'] || r['address 2'] || '',
      'city': r['City'] || r['city'] || '',
      'state': r['State'] || r['state'] || '',
      'zip': r['Zip'] || r['zip'] || r['postal_code'] || '',
      'country': r['Country'] || r['country'] || 'USA'
    };
  },

    geocodeResult(r) {

      var queries = this.setGeocodeQueries(r);

      if(!queries['address'] || !queries['city'] || !queries['state'] || !queries['zip']) {
        this.postResult(r, {});
        return;
      }

         this.$http.get(this.buildQueries('/geocode', queries))
        .then(response => {
            if(response && response.data && response.data[0]) {

                if(response.data[0]['geometry'] && response.data[0]['geometry']['location']) {
                      this.postResult(r, response.data[0]);
                }

            }
        })
    },

    setResultPost(r) {
      var post = {
        name: r['Name'] || r['name'] || r['Location Name'] || r['location name'] || r['location_name'] || '',
        phone: r['Phone'] || r['phone'] || r['Phone Number'] || r['phone number'] || r['phone_number'] || r['Phone Number'] || r['phone number'] || '',
        email: r['Email'] || r['email'] || r['Email Address'] || r['email address'] || r['email_address'] || '',
        website: r['Website'] || r['website'] || '',
        description: r['Description'] || r['description'] || '',
        location_reference: r['Reference'] || r['reference'] || r['Location Reference'] || r['location reference'] || r['location_reference'] || r['ID'] || r['id'] || r['Location ID'] || '',
        address: r['Address'] || r['address'] || r['address 1'] || r['Address 1'] || r['address_1'] || '',
        address_2: r['Address 2'] || r['address 2'] || r['address_2'] || '',
        city: r['City'] || r['city'] || '',
        state: r['State'] || r['state'] || '',
        zip: r['Zip'] || r['zip'] || r['postal_code'] || '',
        county: r['County'] || '',
        country: r['Country'] || r['country'] || 'US'
      }

      if(!post['address'] || r['virtual'] == '1') {
        post['virtual'] = true;
      }

      return post;
    },

    postResult(r, data)
    {
        var post = this.setResultPost(r);

        if(data['geometry'] && data['geometry']['location']['lat']) {
          post['lat'] = data['geometry']['location']['lat'];
        }

        if(data['geometry'] && data['geometry']['location']['lng']) {
          post['lng'] = data['geometry']['location']['lng'];
        }

        if(data['address_components']) {
          data['address_components'].forEach(function(c) {
            if(c['types'] && c['types'].length) {

              if(c['types'][0] == 'street_address') {
                post['address'] = c['long_name'];
              }

              if(c['types'][0] == 'locality') {
                post['city'] = c['long_name'];
              }

              if(c['types'][0] == 'administrative_area_level_1') {
                post['state'] = c['short_name'];
              }

              if(c['types'][0] == 'postal_code') {
                post['zip'] = c['short_name'];
              }

              if(c['types'][0] == 'administrative_area_level_2') {
                post['county'] = c['long_name'];
              }
            }
          });
        }

        post['portal'] = true;

        var fields = ['name', 'phone', 'email', 'website', 'description', 'location_reference', 'address', 'address_2', 'city', 'state', 'zip', 'county', 'lat', 'lng', 'portal', 'virtual'];

        fields.forEach(function(field) {
          if(post[field] && typeof post[field] === 'string') {
            post[field] = post[field].replace(/"/g, '');
          }
        });

        this.$http.post('/'+this.$route.meta.base_url, post)
        .then(response => {
            if(response && response.data) {

                if(response.status === 201) {
                    this.$eventBus.$emit('newLocation', response.data);
                }
            }
        })
    },

    readResults(data) {

      const headers = data.slice(0, data.indexOf("\n")).split(',');

      const rows = data.slice(data.indexOf("\n") + 1).split("\n");

      const arr = rows.map(function (row) {
        const values = row.split(',');
        const el = headers.reduce(function (object, header, index) {
          object[header] = values[index]
          return object;
        }, {});
        return el;
      });

      return arr;
    }

    }
}
</script>