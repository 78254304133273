<template>
  <div>

    <archived-trashed-status
        :form="form"
        :processing="processing"
        :title="$route.meta.title"
    />

    <div class="row">
      <div class="col-sm-12 col-md-8">
        <h1>{{ form.name }}</h1>
        <h3>{{ form.phone_number }}</h3>
        <p>
          <router-link :to="{name: 'settings.index'}">Settings</router-link> /
          <router-link :to="{name: $route.meta.base_url+'.index'}">{{ $route.meta.title }}s</router-link> / Edit {{ $route.meta.title }}
        </p>
      </div><!--Col-->

      <div class="col-sm-12 col-md-4 text-right">
        <div class="btn-group">

          <div class="btn-group">
            <button
                type="button"
                class="btn btn-outline-primary dropdown-toggle radius-right-0"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            >
              Tools
            </button>
            <div class="dropdown-menu">
              <a
                  v-if="!form.archived_at"
                  class="dropdown-item"
                  @click="archiveRecord()"
              >Archive</a>
              <a
                  v-if="form.archived_at || form.deleted_at"
                  class="dropdown-item"
                  @click="restoreRecord()"
              >Restore</a>
              <a
                  v-if="!form.deleted_at"
                  class="dropdown-item"
                  @click="trashRecord()"
              >Trash</a>
            </div>
          </div><!--BtnGroup-->

          <button
              v-if="!form.archived_at && !form.deleted_at"
              type="button"
              class="btn btn-success"
              :disabled="processing"
              @click="postForm()"
          >
            Save
          </button>

          <router-link
              :to="{ name: $route.meta.base_url+'.index' }"
              class="btn btn-danger"
          >
            Close
          </router-link>

        </div>
      </div><!--Col-->
    </div><!--Row-->

    <div class="row">
      <div class="col-md-3 col-xs-12">
        <div class="card">
          <div class="card-body">

            <p class="card-title">Email Settings</p>

            <form
                @submit.prevent="postForm"
                @keyup.enter="postForm"
            >

              <div class="form-group">
                <label for="from_name">Email Name *</label>
                <input
                    id="from_name"
                    v-model="form.name"
                    type="text"
                    class="form-control"
                    required
                    :disabled="processing"
                >
              </div>

              <div class="form-group">
                <label for="from_email">Email Address *</label>
                <input
                    id="from_email"
                    v-model="form.email"
                    type="email"
                    class="form-control"
                    :class="{ 'is-valid': isValidEmail(), 'is-invalid': isInvalidEmail() }"
                    required
                    :disabled="processing"
                >

                <template v-if="isInvalidEmail() === true">
                  <div class="btn-group mt-3">
                    <button
                        type="button"
                        class="btn btn-success"
                        :disabled="processing"
                        @click="retryVerification()"
                    >
                      Retry Verification
                    </button>
                    <button type="button" class="btn btn-outline-secondary" @click="getRecord()" data-tooltip="Refresh Status" data-toggle="tooltip" :disabled="processing"><i class="fa fa-sync"></i> <span class="sr-only">Refresh</span></button>
                  </div>
                </template>
              </div>

              <template v-if="isValidEmail() === true">
                <div class="alert alert-success">Email is verified and ready to use.</div>
              </template>

              <template v-if="isInvalidEmail() === true">
                <div class="alert alert-danger">Email is not verified. Please retry verification.</div>
              </template>

              <template v-if="gateways && gateways.length > 0">
                <div class="form-group">
                <label for="gateway">Gateway</label>
                <select
                    id="gateway"
                    v-model="form.gateway"
                    class="form-control"
                    @change="postForm()"
                    :disabled="processing"
                >
                  <option value="">Select Gateway</option>
                <template v-for="gateway in gateways">
                    <option
                        :value="gateway.uuid"
                    >
                      {{ gateway.name }}
                    </option>
                  </template>
                </select>
              </div>              
              </template>


              <div class="form-group custom-control custom-switch">
                <input
                    id="primary"
                    v-model="form.primary"
                    type="checkbox"
                    class="custom-control-input"
                    @click="togglePrimary()"
                    :disabled="processing"
                >
                <label
                    class="custom-control-label"
                    for="primary"
                >Primary</label>
              </div>

              <p class="text-muted">The primary email address is used for outbound emails unless a different email is specified in a message or template.</p>

            </form>
          </div><!--Body-->
        </div><!--Card-->

        <audit-logs
            class="mt-3"
            :form="form"
        />
      </div><!--Col-->

      <div class="col-sm-12 col-md-6">


        <div class="card mb-3">
          <div class="card-body">
            <p class="card-title">Outbound Settings</p>

            <p class="lead">The following settings are used when an email is sent through eCourtDate.</p>

            <div class="form-group">
              <label for="header">Header <code v-if="form.template === 'html'">HTML</code></label>
              <input id="header" type="text" class="form-control" v-model="form.header" @keyup.enter="postForm()" :disabled="processing">
            </div>

            <div class="form-group">
              <label for="logo">Logo URL</label>
              <input
                  id="logo"
                  v-model="form.logo"
                  type="text"
                  class="form-control"
                  @keyup.enter="postForm()"
                  :disabled="processing"
              >
            </div>

            <div class="form-group">
              <label for="signature">Signature <code v-if="form.template === 'html'">HTML</code></label>
              <textarea
                  id="signature"
                  v-model="form.signature"
                  class="form-control"
                  rows="3"
                  :disabled="processing"
              />
            </div>

            <div class="form-group">
              <label for="footer">Footer <code v-if="form.template === 'html'">HTML</code></label>
              <textarea
                  id="footer"
                  v-model="form.footer"
                  class="form-control"
                  rows="3"
                  :disabled="processing"
              />
            </div>

            <div class="form-group">
              <label for="template">Layout</label>
              <select
                  id="template"
                  v-model="form.template"
                  class="form-control"
                  @change="postForm()"
                  :disabled="processing"
              >
                <template v-for="template in email_templates">
                  <option
                      :value="template"
                  >
                    {{ template }}
                  </option>
                </template>
              </select>
            </div>

            <div class="form-group">
              <label for="theme">Theme</label>
              <select
                  id="theme"
                  v-model="form.theme"
                  class="form-control"
                  @change="postForm()"
                  :disabled="processing"
              >
                <option value="light">Light</option>
                <option value="dark">Dark</option>
              </select>
            </div>


            <div class="form-group">
              <label for="reply_to">Reply-To</label>
              <input
                  id="reply_to"
                  v-model="form.reply_to"
                  type="email"
                  class="form-control"
                  @keyup.enter="postForm()"
                  :disabled="processing"
              >
              <p class="form-text text-muted small">Use a different email address when a recipient replies to an email. Defaults to {{ form.email }}</p>
            </div>
            <div class="form-group">
              <label for="ccs">Carbon Copy (CC)</label>
              <input
                  id="ccs"
                  v-model="form.ccs"
                  type="text"
                  class="form-control"
                  @keyup.enter="postForm()"
                  :disabled="processing"
              >
              <p class="form-text text-muted small">Comma-separated list of emails.</p>
            </div>
            <div class="form-group">
              <label for="bccs">Blind Carbon Copy (BCC)</label>
              <input
                  id="bccs"
                  v-model="form.bccs"
                  type="text"
                  class="form-control"
                  @keyup.enter="postForm()"
                  :disabled="processing"
              >
              <p class="form-text text-muted small">Comma-separated list of emails.</p>
            </div>
            <div class="form-group">
              <label for="subject">Default Subject</label>
              <input
                  id="subject"
                  v-model="form.subject"
                  type="text"
                  class="form-control"
                  @keyup.enter="postForm()"
                  :disabled="processing"
              >
            </div>


          </div><!--Body-->
        </div><!--Card-->

        <div class="card">
          <div class="card-body">
            <p class="card-title">Inbound Settings</p>

            <p class="lead">The following settings are used when an email is received through eCourtDate.</p>

            <div class="form-group mt-3">
              <label for="auto_reply">Auto Response</label>
              <textarea
                  id="auto_reply"
                  v-model="form.auto_reply"
                  class="form-control"
                  rows="3"
                  :disabled="processing"
              />
              <p class="form-text small text-muted">The message that is sent back to the sender when an email is received.</p>
            </div>

            <template v-if="form.auto_reply">
              <p class="outbound" v-html="form.auto_reply"></p>
            </template>

            <div class="form-group">
              <label for="forward">Auto Forward by Email</label>
              <input
                  id="forward"
                  v-model="form.forward"
                  type="text"
                  class="form-control"
                  @keyup.enter="postForm()"
                  :disabled="processing"
              >
              <p class="form-text small text-muted">Comma-separated list of emails to forward emails to.</p>
            </div>


            <div class="form-group custom-control custom-switch mt-3">
              <input
                  id="forwardAssigned"
                  v-model="form.forward_assigned"
                  type="checkbox"
                  class="custom-control-input"
                  @change="postForm()"
              >
              <label
                  class="custom-control-label"
                  for="forwardAssigned"
              >Auto Forward to Assigned Users</label>
              <p class="form-text small text-muted">Automatically forward inbound emails to the user who is assigned to the client.</p>
            </div>

            <p for="forward_roles" class="m-0 lead">Auto Forward by User Role</p>

            <template v-for="role in roles">
            <span class="badge badge-pill link-hover" :class="{'badge-primary': isEnabledRole('forward_roles', role) }" @click="toggleRole('forward_roles', role)">{{ role.name }}</span>
            </template>

            <template v-if="!processing && form && form.uuid && roles && roles.length === 0">
              <div class="alert alert-warning">
                No roles found. <a href="/roles" target="_blank" class="alert-link">Create at least one role</a> to enable this feature.
              </div>
            </template>

            <p for="forward_groups" class="lead mt-3 mb-0">Auto Forward to Clients by Group</p>

            <template v-for="group in groups">
            <span class="badge badge-pill link-hover my-3" :class="{'badge-primary': isEnabledGroup('forward_groups', group.name) }" @click="toggleGroup('forward_groups', group)" :disabled="processing">{{ group.name }}</span>
            </template>

            <template v-if="!processing && form && form.uuid && groups && groups.length === 0">
              <div class="alert alert-warning">
                No groups found. <a href="/settings?search=Default%20Groups" target="_blank" class="alert-link">Create at least one group</a> to enable this feature.
              </div>
            </template>


          </div>
        </div>

      </div><!--Col-->

      <div class="col-sm-12 col-md-3">

            <email-preview :form="form" />

            <p class="mt-5"><a :href="'/messages?search_field=from&search='+form.email" target="_blank">{{ messages.length }} of {{ total_messages }} Outbound Messages</a></p>

            <template v-if="messages && messages.length > 0">
              <template v-for="message in messages">
                <message :message="message" />
              </template>
            </template>

      </div><!--Col-->

    </div><!--Row-->
  </div>
</template>
<script>
import queries from '../mixins/queries';

export default {

  mixins: [ queries ],

  data () {
    return {
      processing: false,
      form: {},
      email_templates: ['default', 'html'],
      roles: [],
      errors: null,
      messages: [],
      gateways: [],
      groups: [],
      total_messages: 0
    }
  },

  mounted() {

    let self = this;

    this.$eventBus.$on('errors', function (errors) {
        self.processing = false;
        self.errors = errors.data || errors;
    });

  },

  created() {
    this.resetForm();
    this.getRecord();
    this.getRoles();
    this.setGroups();
  },

  methods: {

    resetForm() {

      this.form = {
        name: null,
        email: null,
        reply_to: null,
        primary: 0,
        forward_assigned: null,
        forward_roles: [],
        ccs: null,
        bccs: null,
        subject: null,
        template: null,
        header: null,
        footer: null,
        signature: null,
        forward: null,
        logo: null,
      }
    },

    isValidEmail() {

      if(this.form && this.form.verified == true) {
        return true;
      }

      if(this.form && this.form.verified == false) {
        return false;
      }

      return false;
    },

    isInvalidEmail() {

      if(this.form && this.form.verified == false) {
        return true;
      }

      return;
    },

    retryVerification() {
      this.processing = true;
      this.$http.post('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/verify')
          .then(response => {
            if(response && response.data && response.data.message) {
              new this.$noty({text: response.data.message}).show();
              this.processing = false;
            } else {
              this.processing = false;
            }
          })
    },

    togglePrimary() {
      if(this.form.primary === 1) {
        this.form.primary = 0;
      } else {
        this.form.primary = 1;
      }
      this.postForm();
    },

    postForm() {
      this.processing = true;

      this.$http.patch('/'+this.$route.meta.base_url+'/'+this.form.uuid, this.form)
          .then(response => {
            if(response && response.data) {

              this.processing = false;

              if(response.data.updated_at) {
                this.getRecord();
              }

            }
          })
    },

    getStatus() {
      if(!this.form.uuid) {
        return;
      }

      this.$http.get('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/status')
          .then(response => {
            if(response && response.data) {
              this.form.verified = response.data.verified;
            }
          })
    },


    getRecord() {
      this.processing = true;
      this.errors = null;
      this.$http.get('/'+this.$route.meta.base_url+'/'+this.$route.params.id)
          .then(response => {
            if(response && response.data) {

              if(response.data.uuid) {
                this.form = response.data;

                this.setTitle();
                this.getMessages();
                this.getGateways();
                this.getStatus();
              }

              this.processing = false;
            }
          })
    },

    setTitle() {
      document.title = this.form.name + ' | ' + this.$route.meta.title + ' | ' + this.$route.meta.app_name;
    },

    restoreRecord () {
      this.processing = true;
      this.$http.put('/'+this.$route.meta.base_url + '/' + this.form.uuid + '/restore')
          .then(response => {
            if (response && response.data) {

              this.processing = false;

              if(response.status === 200) {
                new this.$noty({text: this.$route.meta.title + ' restored'}).show();
                this.form.deleted_at = null;
                this.form.archived_at = null;
              }

            }
          })
    },

    archiveRecord () {
      this.processing = true
      this.$http.put('/'+this.$route.meta.base_url + '/' + this.form.uuid + '/archive')
          .then(response => {
            if (response && response.data) {

              this.processing = false;

              if(response.status === 200) {
                new this.$noty({text: this.$route.meta.title + ' archived'}).show()
                this.form.archived_at = new Date().toISOString();
              }

            }

          })
    },

    trashRecord () {
      this.processing = true;
      this.$http.delete('/'+this.$route.meta.base_url + '/' + this.form.uuid)
          .then((response) => {
            if(response && response.status) {
              this.processing = false;

              if(response.status === 204) {
                new this.$noty({text: this.$route.meta.title + ' trashed'}).show();
                this.form.deleted_at = new Date().toISOString();
              }
            }
          })
    },

    getMessages() {
      if(!this.form.email) {
        return;
      }

      if(this.total_messages > 0) {
        return;
      }

      this.messages = [];
      this.total_messages = 0;

      this.$http.get('/messages?limit=5&from='+this.form.email)
          .then(response => {
            if(response && response.data && response.data.data) {
              this.messages = response.data.data;
            }
          })

          this.$http.get('/messages/total?from='+this.form.email)
          .then(response => {
            if(response && response.status) {
              this.total_messages = response.data;
            }
          })
    },

    getRoles() {
      this.$http.get('/roles?slim=true&fields=name&limit=100&sort=name&sortDirection=asc')
      .then(response => {
        if(response && response.data) {
          this.roles = response.data;
        }
      })
    },

    isEnabledRole(field, role) {
      if(!this.form[field]) {
        return false;
      }

      if(this.form[field].includes(role.name)) {
        return true;
      }

      return false;
    },

    toggleRole(field, role) {

      if(!this.form[field]) {
        this.form[field] = [];
      }

      if(this.form[field].includes(role.name)) {
        var index = this.form[field].indexOf(role.name);
        this.form[field].splice(index, 1);
      } else {
        this.form[field].push(role.name);
      }
    },

    setGroups() {

      if(!this.settings || !this.settings.default_groups) {
        return;
      }

      let groups = this.settings.default_groups.split(',');

      this.groups = groups.map(group => {
        group = group.trim();
        return {name: group};
      });
    },

    isEnabledGroup(field, group) {
      if(!this.form[field]) {
        return false;
      }

      return this.form[field].includes(group);
    },

    toggleGroup(field, group) {

      if(!this.form[field]) {
        this.form[field] = [];
      }


      if(this.form[field].includes(group.name)) {
        let index = this.form[field].indexOf(group.name);
        this.form[field].splice(index, 1);
      } else {
        this.form[field].push(group.name);
      }

      this.postForm();
    },

    getGateways() {
      if(!this.form.uuid) {
        return;
      }

      this.gateways = [];
      this.$http.get('/gateways')
          .then(response => {
            if(response && response.data) {
              this.gateways = response.data;
            }
          })
    }


  },
}
</script>