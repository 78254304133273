<template>
  <div>

    <page-tabs page="clients" />

    <div class="row">
      <div class="col-sm-12 col-md-3">

        <template v-if="client && client.uuid">
          <div class="card mb-3">
            <div class="card-body">
              <div class="row mb-3">
                <div class="col-sm-12 col-md-6">
                  <p class="card-title m-0">
                    Client <router-link :to="{ 'name': 'clients.edit', 'params': {'id': client.uuid} }">
                    {{ client.first_name }} {{ client.middle_name }} {{ client.last_name }}

                  </router-link>
                  </p>
                  <small class="text-muted"><i class="fa fa-language"></i> {{ setLanguageName(client) }}</small>

                </div><!--Col-->

                <div class="col-sm-12 col-md-6 text-right">
                  <button
                      type="submit"
                      class="btn btn-danger btn-sm"
                      :disabled="processing"
                      @click="closeClient()"
                  >
                    <i class="fa fa-times" /> <span class="sr-only">Close</span>
                  </button>
                </div><!--Col-->
              </div><!--Row-->

              <ul class="list-group">
                <template v-if="client.client_reference">
                  <li class="list-group-item">
                    Reference {{ client.client_reference }}
                  </li>
                </template>

                <template v-if="client.type">
                  <li class="list-group-item">
                    Type {{ client.type }}
                  </li>
                </template>

                <template v-if="client.group">
                  <li class="list-group-item">
                    Group {{ client.group }}
                  </li>
                </template>
              </ul>
            </div><!--Body-->
          </div><!--Card-->
        </template>

        <div class="card">
          <div class="card-body">

            <p class="card-title">Search</p>

            <form @submit.prevent="searchRecords">

              <div class="form-group">
                <label for="search">Search</label>
                <input id="search" type="search" class="form-control" v-model="queries.search" placeholder="Search by file name...">
              </div>

              <div class="form-group">
                <label for="search_status">Status</label>
                <select
                    id="search_status"
                    v-model="queries.status"
                    class="form-control"
                    :disabled="processing"
                >
                  <option value="" />
                  <template v-for="status in file_statuses">
                    <option
                        :value="status"
                    >
                      {{ status }}
                    </option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="search_flow">Flow</label>
                <select
                    id="search_flow"
                    v-model="queries.flow"
                    class="form-control"
                    :disabled="processing"
                >
                  <option value="" />
                  <template v-for="flow in flows">
                    <option
                        :value="flow.uuid"
                    >
                      {{ flow.name }}
                    </option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="search_creator">Creator</label>
                <select
                    id="search_creator"
                    v-model="queries.created_by"
                    class="form-control"
                    :disabled="processing"
                >
                  <option value="" />
                  <template v-for="user in users">
                    <option
                        :value="user.email"
                    >
                      {{ user.name }} {{ user.email }}
                    </option>
                  </template>
                </select>
              </div>

              <button type="submit" class="btn btn-primary" :disabled="processing">Search</button>

            </form>

          </div><!--Body-->
        </div><!--Card-->

        <div class="card my-3">
          <div class="card-body">
            <p class="card-title">Send Files</p>

            <p>Send a client a file from your existing library or upload a new file.</p>

            <a href="/files" class="btn btn-primary">Browse</a>
          </div>
        </div>

      </div><!--Col-->

      <div class="col-sm-12 col-md-9">

        <div class="card">
          <div class="card-body">

            <div class="row">
                <div class="col-sm-12 col-md-6">
                    <p class="card-title">
                      {{ records.length }} of {{ total.toLocaleString() }} {{ $route.meta.title }}s
                    </p>
                </div><!--Col-->
  
                <div class="col-sm-12 col-md-6 text-right">
                  <div class="btn-group d-print-none">
                  <button type="button" class="btn btn-secondary" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
                  <button type="button" class="btn btn-secondary" @click="downloadCSV()" :hidden="!total" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
                  </div>
                </div><!--Col-->
              </div><!--Row-->

            <div class="table-responsive my-3">
              <table class="table table-striped table-hover">
                <tr>
                  <template v-for="column in columns">
                    <th>
                      <template v-if="column.key">
                        <span
                            class="text-capitalize link-hover"
                            @click="sortColumn(column)"
                        >{{ column.label }}</span>
                      </template>

                      <template v-if="!column.key">
                        <span class="text-capitalize">{{ column.label }}</span>
                      </template>

                      <template v-if="column.key == queries.sort">
                        <button
                            type="button"
                            class="btn btn-outline-secondary btn-sm ml-1"
                            :disabled="processing"
                            @click="updateSortDirection()"
                        >
                          <template v-if="queries.sortDirection == 'asc'">
                            <i class="fa fa-arrow-up" /> <span class="sr-only">Asc</span>
                          </template>

                          <template v-if="queries.sortDirection == 'desc'">
                            <i class="fa fa-arrow-down" /> <span class="sr-only">Desc</span>
                          </template>
                        </button>
                      </template>
                    </th>
                  </template>
                </tr>
                <tbody>
                <template v-for="record in records">
                  <tr>
                    <td>
                      <a :href="'/'+$route.meta.base_url+'/'+record.uuid">
                        <i class="fa fa-edit"></i> Edit
                      </a>
                    </td>
                    <td>{{ record.name }}</td>
                    <td>
                      <template v-if="record.status">
                        <file-status :status="record.status" />
                      </template>
                    </td>
                    <td>
                      <template v-if="record.due_at">
                        {{ record.due_at | date }}
                      </template>
                    </td>
                    <td>
                      <template v-if="record.flow">
                        {{ setFlowName(record) }}
                      </template>
                    </td>
                    <td>{{ record.created_by }}</td>
                    <td>{{ record.created_at | datetime }}

                      <template v-if="record.archived_at">
                        <span class="badge badge-pill badge-warning">Archived</span>
                      </template>

                      <template v-if="record.deleted_at">
                        <span class="badge badge-pill badge-danger">Trashed</span>
                      </template>

                    </td>
                  </tr>
                </template>
                </tbody>
              </table>


              <template v-if="!processing && !records.length">
                <div class="alert alert-warning">
                  No {{ $route.meta.title }}s found.
                </div>
              </template>


            </div>
          </div><!--Body-->
        </div><!--Card-->

        <template v-if="records && records.length && total > records.length">
          <div class="row mt-3">
            <div class="col-12 text-center">
              <button
                  type="button"
                  class="btn btn-outline-primary"
                  :disabled="processing"
                  @click="getRecords()"
              >
                Load more
              </button>
            </div><!--Col-->
          </div><!--Col-->
        </template>

      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>
<script>
import queries from '../mixins/queries';
import _ from "lodash";

export default {

  mixins: [ queries ],

  data() {
    return {
      processing: false,
      form: {},
      uploadProgress: null,
      records: [],
      total: 0,
      queries: {},
      columns: [
        { label: 'Edit'},
        { key: 'name', label: 'File Name'},
        { key: 'status', label: 'Status'},
        { key: 'due_at', label: 'Due Date'},
        { key: 'flow', label: 'Flow'},
        { key: 'created_by', label: 'Creator'},
        { key: 'created_at', label: 'Created'}
      ],
      file_types: [
        { key: 'text/csv', label: 'CSV'},
        { key: 'image/png', label: 'PNG'},
        { key: 'image/jpeg', label: 'JPEG'},
        { key: 'application/pdf', label: 'PDF'}
      ],
      users: [],
      client: {},
      file_statuses: ['draft', 'pending', 'complete', 'canceled'],
      flows: []
    }
  },


  created() {
    this.resetQueries();
    this.resetRecords();
    this.resetForm();
    this.setURLParams();
    this.getRecords();
    this.getUsers();
    this.getFlows();
  },

  methods: {

    setURLParams() {
      var searchParams = new URLSearchParams(window.location.search);

      var self = this;

      searchParams.forEach(function(value, key) {
        self.queries[key] = value;
      });

      this.getClient();

    },

    resetQueries() {
      this.queries = {
        search: '',
        limit: 10,
        skip: 0,
        client: null,
        created_by: null,
        status: null,
        sort: 'created_at',
        sortDirection: 'desc'
      }
    },

    resetRecords() {
      this.records = [];
      this.total = 0;
      this.queries.skip = 0;
    },

    getRecords() {

      this.processing = true;
      this.updateURL();
      this.getTotal();

      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
          .then(response => {
            if(response && response.data) {
              this.records = response.data;
              this.processing = false;
            }
          })
    },

    updateURL() {
      history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url, this.queries));
    },

    setTitle() {
      document.title = this.total + ' ' + this.$route.meta.title + 's | eCourtDate.com';
    },

    getTotal() {
      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
          .then(response => {
            if(response && response.status === 200) {
              this.total = response.data;
              this.setTitle();
            }
          })
    },

    storeFiles() {

      const files = this.$refs.files.files;

      if(files && files.length > 0) {

        this.processing = true;

        for (let i = 0; i < files.length; i++) {

          let file = files.item(i);

          this.uploadFiles(file, {
            progress: progress => {
              this.uploadProgress = Math.round(progress * 100);

              if(this.uploadProgress === 100) {
                new this.$noty({text: this.$route.meta.title + ' uploaded'}).show();
                this.uploadProgress = null;
              }
            }
          }).then(response => {
            this.processing = false;

            if(response.uuid) {
              this.records.unshift(response);
              this.total++;
              new this.$noty({text: 'Adding '+this.$route.meta.title}).show();
            }
          })

        }

        this.resetForm();

      }
    },

    searchRecords () {
      this.resetRecords();
      this.getRecords();
    },

    resetForm() {

      this.form = {
        language: 'en',
        public: false,
        attach_emails: true
      };

      if(this.$refs.file && this.$refs.file.value) {
        this.$refs.files.value = null;
      }

    },

    updateSortDirection() {

      if(this.queries.sortDirection == 'asc') {
        this.queries.sortDirection = 'desc';
      } else {
        this.queries.sortDirection = 'asc';
      }

      this.resetRecords();

      this.getRecords();
    },

    sortColumn(column) {

      this.queries.sort = column.key;

      this.updateSortDirection();

    },

    getUsers() {
      this.$http.get('/users?slim=true&limit=1000&fields=name,email&sort=name&sortDirection=asc')
          .then(response => {
            if(response && response.data) {
              this.users = response.data;
            }
          })
    },

    closeClient() {
      this.client = {};
      this.queries.client = null;
      this.searchRecords();
    },

    getClient() {
      if(!this.queries.client) {
        return;
      }

      this.$http.get('/clients/'+this.queries.client+'?slim=true&fields=uuid,first_name,middle_name,last_name,client_reference,type,group,language')
          .then(response => {
            if(response && response.data) {
              this.client = response.data;
            }
          })
    },

    setLanguageName(record) {

      if(!record.language) {
        return;
      }

      var language = _.find(this.languages, {'key': record.language});

      if(!language || !language.label) {
        return record.language;
      }

      return language.label;
    },

    setFlowName(record) {
      if(!record.flow) {
        return;
      }

      var flow = this.flows.find(f => f.uuid == record.flow);

      if(flow) {
        return flow.name;
      }
    },

    getFlows() {
    this.$http.get('/flows?slim=true&fields=name,uuid&limit=100&sort=name&sortDirection=asc&type=file')
    .then(response => {
      if(response && response.data) {
        this.flows = response.data;
      }
    })
  },

  }

}
</script>