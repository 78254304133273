<template>
  <div>

    <template v-if="!processing && show_campaign">
      <div class="alert alert-danger">
        <i class="fa fa-exclamation-triangle"></i> 
        A carrier campaign has not been found. Please <a href="https://ecourtdate.com/carrier-registration" target="_blank" class="alert-link">click here to learn more.</a>
      </div>
    </template>

    <page-tabs page="settings" />

    <div class="row">
      <div class="col-sm-12 col-md-3">
        <div class="card">
          <div class="card-body">
            <p class="card-title">
              Create
            </p>

            <form @submit.prevent="postForm()">

            <div class="form-group">
                <fieldset>
                  <legend>Phone Number Type</legend>
                  <template v-for="phone_type in phone_types">
                    <label
                        class="mr-2 text-capitalize"
                        :for="phone_type.key"
                    >
                      <input
                          :id="phone_type.key"
                          v-model="form.type"
                          type="radio"
                          name="type"
                          :value="phone_type.key"
                      > {{ phone_type.label }}
                    </label>
                  </template>
                  <template v-if="form.type">
                    <p class="form-text text-muted small m-0">{{ setPhoneType(form.type).description }}</p>
                  </template>
                </fieldset>
              </div>

              <template v-if="form.type == 'local'">

                <div class="form-group">
                  <label for="state">Filter Area Codes by State</label>
                  <select
                      id="state"
                      v-model="form.state"
                      class="form-control"
                      :disabled="processing"
                  >
                    <option value=""></option>
                    <template v-for="state in states">
                      <option
                          :value="state.key"
                      >
                        {{ state.label }}
                      </option>
                    </template>
                  </select>
                </div>

                <label for="area_code">Area Code</label>
                <select
                    id="area_code"
                    v-model="form.area_code"
                    class="form-control"
                    :disabled="processing"
                    @change="postForm()"
                >
                  <option value="" />
                  <option
                      v-for="area_code in area_codes"
                      :value="area_code.code"
                      :hidden="isAreaCodeHidden(area_code)"
                  >
                    {{ area_code.code }} {{ area_code.city }} {{ area_code.state }}
                  </option>
                </select>

                <template v-if="campaigns && campaigns">
                <div class="form-group my-3">
                  <label for="campaign">Carrier Campaign</label>
                  <select
                      id="campaign"
                      v-model="form.campaign"
                      class="form-control"
                      :disabled="processing"
                  >
                    <option value=""></option>
                    <template v-for="campaign in campaigns">
                      <option
                          :value="campaign.campaignId"
                      >
                        {{ campaign.campaignId }} {{ campaign.usecase }}
                      </option>
                    </template>
                  </select>

                </div>

              </template>

              </template>

              <template v-if="form.type == 'short_code'">

              <p>New short codes must be purchased through the <a href="https://www.usshortcodes.com/find-short-code" target="_blank">US Short Codes</a> registry.</p>

              <div class="form-group">
                <label for="phone_number">Short Code</label>
                <input id="phone_number" type="text" class="form-control" v-model="form.phone_number" required>
              </div>

              </template>

              <template v-if="form.type == 'port'">
              <p>Port your existing phone number to eCourtDate. Letter of authorization forms are required to start the port.</p>

              <div class="btn-group mb-3">
                <a href="https://assets.ecourtdate.com/files/loa-form.pdf" target="_blank" class="btn btn-sm btn-secondary">Local Area Code LOA</a>
                <a href="https://assets.ecourtdate.com/files/loa-form-toll-free.pdf" target="_blank" class="btn btn-sm btn-secondary">Toll-Free LOA</a>
                <a href="https://assets.ecourtdate.com/files/shortcode-authorization.pdf" target="_blank" class="btn btn-sm btn-secondary">Short Code LOA</a>
              </div>
        
              <div class="form-group">
                <label for="phone_number">Phone Number</label>
                <input id="phone_number" type="tel" class="form-control" v-model="form.phone_number" required>
              </div>
              <p class="form-text small">You can review number portability before starting the port.</p>
              </template>
              
              <div class="form-group">
                <label for="gateway">Gateway</label>
                <select
                    id="gateway"
                    v-model="form.gateway"
                    class="form-control text-uppercase"
                    :disabled="processing"
                >
                  <option value=""></option>
                  <template v-for="gateway in gateways">
                    <option
                        :value="gateway"
                    >
                      {{ gateway }}
                    </option>
                  </template>
                </select>
              </div>


              <button
                  type="submit"
                  class="btn btn-success my-3"
                  :disabled="processing"
              >
                <template v-if="form.type == 'local' || form.type == 'toll_free'">
                  Search Numbers
                </template>

                <template v-if="form.type == 'short_code' || form.type == 'port'">
                  Add
                </template>
              </button>

            </form>

            <template v-if="available_phones && available_phones.length && !processing">
              <ul class="list-group mt-3" :hidden="form.type == 'short_code' || form.type == 'port'">
                <template v-for="available_phone in available_phones">
                  <li
                      class="list-group-item"
                  >
                    <p class="card-text font-weight-bold m-0 lead">{{ available_phone.phone_number }}</p>

                    <template v-if="available_phone.gateway">
                      <p class="small text-muted m-0">Gateway <span class="text-uppercase">{{ available_phone.gateway }}</span></p>
                    </template>

                    <button
                        type="button"
                        class="btn btn-success btn-sm float-right"
                        @click="activatePhone(available_phone)"
                        :disabled="processing"
                    >
                     <i class="fa fa-check-circle"></i> ACTIVATE
                    </button>

                    <template v-if="available_phone.features && available_phone.features.length > 0">
                      <p class="small text-muted m-0">Features</p>
                      <template v-for="feature in available_phone.features">
                        <span class="badge badge-pill badge-primary text-uppercase mr-1">{{ feature.name || feature }}</span>
                      </template>
                    </template>


                  </li>
                </template>
              </ul>
            </template>


          </div><!--Body-->
        </div><!--Card-->


        <div class="card my-3">
          <div class="card-body">

            <p class="card-title">Search</p>

            <form @submit.prevent="searchRecords()">

              <div class="form-group">
                <label for="search">Search</label>
                <input id="search" type="search" class="form-control" v-model="queries.search">
              </div>

              <div class="form-group">
                <label for="type">Type</label>
                <select
                    id="type"
                    v-model="queries.type"
                    class="form-control"
                    :disabled="processing"
                >
                  <option value=""></option>
                  <template v-for="phone_type in phone_types">
                    <option
                        :value="phone_type.key"
                    >
                      {{ phone_type.label }}
                    </option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="campaign">Campaign</label>
                <select
                    id="campaign"
                    v-model="queries.campaign"
                    class="form-control"
                    :disabled="processing"
                >
                  <option value=""></option>
                  <template v-for="campaign in campaigns">
                    <option
                        :value="campaign.campaignId"
                    >
                      {{ campaign.campaignId }} {{ campaign.usecase }}
                    </option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="gateway">Gateway</label>
                <select
                    id="gateway"
                    v-model="queries.gateway"
                    class="form-control text-uppercase"
                    :disabled="processing"
                >
                  <option value=""></option>
                  <template v-for="gateway in gateways">
                    <option
                        :value="gateway"
                    >
                      {{ gateway }}
                    </option>
                  </template>
                </select>
              </div>

              <button type="submit" class="btn btn-primary" :disabled="processing">Search</button>

            </form>

          </div>
        </div>


      </div><!--Col-->

      <div class="col-sm-12 col-md-9">

        <div class="card">
          <div class="card-body">
            <div class="row">

              <div class="col-sm-12 col-md-6">

                <p class="card-title">
                  {{ records.length }} of {{ total.toLocaleString() }} Results
                </p>

              </div><!--Col-->

              <div class="col-sm-12 col-md-6 text-right">

                <div class="btn-group d-print-none">
                  <button type="button" class="btn btn-secondary" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
                  <button type="button" class="btn btn-secondary" @click="downloadCSV()" :hidden="!total" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
                </div>
              </div><!--Col-->

              </div><!--Row-->


            <div class="table-responsive my-3">
              <table class="table table-striped table-hover">
                <thead>
                <tr>
                  <template v-for="column in columns">
                    <th
                        class="text-uppercase"
                    >
                      <template v-if="column.key">
                        <span
                            class="text-capitalize link-hover"
                            @click="sortColumn(column)"
                        >{{ column.label }}</span>
                      </template>

                      <template v-if="!column.key">
                        <span class="text-capitalize">{{ column.label }}</span>
                      </template>

                      <template v-if="column.key == queries.sort">
                        <button
                            type="button"
                            class="btn btn-outline-secondary btn-sm ml-1"
                            :disabled="processing"
                            @click="updateSortDirection()"
                        >
                          <template v-if="queries.sortDirection == 'asc'">
                            <i class="fa fa-arrow-up" /> <span class="sr-only">Asc</span>
                          </template>

                          <template v-if="queries.sortDirection == 'desc'">
                            <i class="fa fa-arrow-down" /> <span class="sr-only">Desc</span>
                          </template>
                        </button>
                      </template>
                    </th>
                  </template>
                </tr>
                </thead>

                <tbody>
                <template v-if="records && records.length > 0">
                  <template v-for="record in records">
                    <tr>
                      <td>
                        <a :href="'/'+$route.meta.base_url+'/'+record.uuid">
                          <i class="fa fa-edit"></i> Edit
                        </a>
                      </td>
                      <td>
                          {{ record.name }}
                      </td>
                      <td>{{ record.phone_number }} <button type="button" class="btn btn-secondary btn-sm" @click="copyToClipboard(record.phone_number)" :disabled="processing"><i class="fa fa-copy"></i> <span class="sr-only">Copy</span></button></td>
                      <td>{{ record.type }}</td>
                      <td>
                        <template v-if="record.primary">
                          <i class="fa fa-check-circle text-success"></i> <span class="sr-only">Primary Phone</span>
                        </template>
                        <template v-else>
                          <i class="fa fa-check-times text-danger"></i> <span class="sr-only">Not Primary Phone</span>
                        </template>
                      </td>
                      <td>
                        <template v-if="record.record_calls">
                          <i class="fa fa-check-circle text-success"></i> <span class="sr-only">Record Calls Enabled</span>
                        </template>
                        <template v-else>
                          <i class="fa fa-check-times text-danger"></i> <span class="sr-only">Record Calls Disabled</span>
                        </template>
                      </td>
                      <td>
                      <template v-if="record.outside_business">
                        Outside Business
                        <template v-if="record.business_days && record.business_days.length">
                          <template v-for="(business_day in record.business_days">
                            <span class="badge badge-pill badge-secondary text-uppercase">{{ business_day }}</span>
                          </template>
                        </template>
                        {{ record.from_time }} {{ record.to_time }}
                      </template>
                      </td>
                      <td>{{ record.campaign }}</td>
                      <td>{{ record.gateway }}</td>
                      <td>{{ record.created_by }}</td>
                      <td>{{ record.updated_by }}</td>
                      <td>{{ record.created_at | datetime }}</td>
                      <td>{{ record.updated_at | datetime }}</td>
                    </tr>
                  </template>
                </template>
                </tbody>
              </table>
            </div><!--Responsive-->

            <template v-if="!processing && !records.length">
              <div class="alert alert-warning">
                No {{ $route.meta.title }}s found.
              </div>
            </template>
          </div><!--Body-->
        </div><!--Card-->

        <template v-if="records && records.length && total > records.length">

          <div class="row mt-3">
            <div class="col-12 text-center">

              <button
                  type="button"
                  class="btn btn-outline-primary"
                  :disabled="processing"
                  @click="getRecords"
              >
                Load more
              </button>

            </div><!--Col-->
          </div><!--Row-->

        </template>

      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>
<script>
import _ from "lodash";
import queries from '../mixins/queries';

export default {

  mixins: [ queries ],

  data () {
    return {
      processing: false,
      show_campaign: false,
      validating: false,
      validated: false,
      downloading: false,
      total: 0,
      queries: {},
      records: [],
      area_codes: [],
      campaigns: [],
      downloads: [],
      form: {},
      columns: [
        { label: 'Edit'},
        { key: 'name', label: 'Name'},
        { key: 'phone_number', label: 'Phone Number'},
        { key: 'type', label: 'Type'},
        { key: 'primary', label: 'Primary'},
        { key: 'record_calls', label: 'Record Calls'},
        { key: 'outside_business', label: 'Outside Business'},
        { key: 'campaign', label: 'Campaign'},
        { key: 'gateway', label: 'Gateway'},
        { key: 'created_by', label: 'Created'},
        { key: 'updated_by', label: 'Updated'},
        { key: 'created_at', label: 'Created'},
        { key: 'updated_at', label: 'Updated'}
      ],
      states: [],
      available_phones: [],
      phone_types: [
        { key: 'local', label: 'Local', description: 'Activate a new 10-digit local area code phone number.'},
        { key: 'toll_free', label: 'Toll-Free', description: 'Activate a new toll-free phone number.'},
        { key: 'short_code', label: 'Short Code', description: 'Activate a new short code phone number.'},
        { key: 'port', label: 'Port', description: 'Transfer an existing phone number to eCourtDate.'}
      ],
      port_data: {},
      gateways: ['auto', 'twilio', 'bandwidth', 'telnyx', 'sinch']
    }
  },

  mounted() {

    var self = this;

  this.$eventBus.$on('errors', function (errors) {
      self.errors = errors.data || errors;
      self.processing = false;
  });

  },

  created() {
    this.resetForm();
    this.resetQueries();
    this.resetRecords();
    this.setURLParams();
    this.getStates();
    this.getAreaCodes();
    this.getRecords();
    this.getCampaigns();
  },

  methods: {


    setURLParams() {

      var searchParams = new URLSearchParams(window.location.search);

      var self = this;

      searchParams.forEach(function(value, key) {
        self.queries[key] = value;
      });
    },

    setStatusClass(record) {

      if(record.status == 'private') {
        return 'badge-danger';
      }

      if(record.status == 'draft') {
        return 'badge-secondary';
      }

      return 'badge-success';
    },

    resetQueries() {
      this.queries = {
        limit: 10,
        slim: false,
        search: null,
        sort: 'primary',
        sortDirection: 'desc'
      }
    },

    resetForm() {
      this.form = {
        area_code: null,
        city: null,
        state: null,
        search: null,
        campaign: null,
        type: 'local',
        gateway: 'auto'
      }
    },

    resetRecords() {
      this.records = [];
      this.total = 0;
      this.queries.skip = 0;
    },

    updateSortDirection() {

      if(this.processing) {
        return;
      }

      if(this.queries.sortDirection == 'asc') {
        this.queries.sortDirection = 'desc';
      } else {
        this.queries.sortDirection = 'asc';
      }

      this.resetRecords();

      this.getRecords();
    },

    sortColumn(column) {

      if(this.processing) {
        return;
      }

      this.queries.sort = column.key;

      this.updateSortDirection();
    },

    getRecords() {

      this.processing = true;

      this.updateURL();
      this.getTotal();

      this.$http.get(this.buildQueries(this.$route.meta.base_url, this.queries))
          .then(response => {
            if(response && response.data) {

              if(response.data.length) {

                var self = this;

                response.data.forEach(function(data) {
                  self.records.push(data);
                });
              }

              this.queries.skip = this.records.length;

              this.processing = false;
            }
          })
    },

    getTotal() {
      this.$http.get(this.buildQueries(this.$route.meta.base_url+'/total', this.queries))
          .then(response => {
            if(response && response.status === 200) {
              this.total = response.data;
              this.setTitle();
            }
          })
    },

    updateURL() {
      history.pushState({}, null, this.buildQueries(this.$route.meta.base_url, this.queries));
    },

    setTitle() {
      document.title = this.total + ' ' + this.$route.meta.title + 's | eCourtDate.com';
    },


    postForm() {

      if(this.form.type == 'local' || this.form.type == 'toll_free') {
        this.searchPhones();
        return;
      }

      this.createPhone();
    },

    createPhone() {

      this.processing = true;

      this.$http.post('/'+this.$route.meta.base_url, this.form)
      .then(response => {
        if(response && response.data) {
            
            this.resetForm();
  
            if(response.data.uuid) {
              new this.$noty({text: this.$route.meta.title + ' added'}).show();
              window.location.href = '/'+this.$route.meta.base_url+'/'+response.data.uuid;
            }
  
            this.processing = false;
        }
      })
    },

    searchPhones() {


      this.processing = true;

      if(this.form.type == 'toll_free') {
        this.form.state = null;
        this.form.area_code = null;
      }

      this.available_phones = [];

      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/search', this.form))
          .then(response => {
            if(response && response.status === 200) {

              this.processing = false;

              if(response.data && response.data.length) {
                this.available_phones = response.data;
                this.noResults = false;
              } else {
                this.available_phones = [];
                this.noResults = true;
              }

            }

          })
    },

    activatePhone(form) {

      this.processing = true;

      form.type = this.form.type;

      if(this.form.campaign) {
        form.campaign = this.form.campaign;
      }

      this.$http.post('/'+this.$route.meta.base_url, form)
          .then(response => {
            if(response && response.data && response.data.uuid) {
              this.processing = false;
              new this.$noty({text: this.$route.meta.title + ' added'}).show();
              this.$router.push('/'+this.$route.meta.base_url+'/'+response.data.uuid);
            }
          })

    },

    resetSearch() {
      this.show_search = true;
      this.resetForm();
      this.available_phones = [];
    },

    searchRecords() {
      this.resetRecords();
      this.getRecords();
    },

    getAreaCodes() {
      this.$axios.get(this.$root.assets_url+'js/area_codes.json')
      .then(response => {
        if(response && response.data) {
          
          this.area_codes = _.uniqBy(response.data, 'code');
        }
      })
    },

    setPhoneType(value) {
      if(!value) {
        return;
      }

      return _.find(this.phone_types, { 'key': value });
    },

    isAreaCodeHidden(area_code) {
        
        if(!area_code) {
          return;
        }

        if(!this.form.state) {
          return false;
        }

        var state = _.find(this.states, { 'key': this.form.state });

        if(!state) {
          return false;
        }


        if(state.label != area_code.state) {
          return true;
        }
  
        return false;
    },


    getCampaigns() {
      this.campaigns = [];
      this.show_campaign = false;
      this.$http.get('/campaigns')
      .then(response => {
        if(response && response.data) {
          this.campaigns = response.data;

          if(!this.campaigns || !this.campaigns.length) {
            this.show_campaign = true;
          }

          if(this.campaigns && this.campaigns.length) {
            this.form.campaign = this.campaigns[0].campaignId;
          }
        }
      })
    }


  },
}
</script>