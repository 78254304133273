<template>
  <div>
    <template v-if="form && form.created_at">
      <div class="card mb-3">
        <div class="card-body">

          <p class="card-title">
            Audit Logs
          </p>

          <template v-if="$root.current_user && $root.current_user.super_admin">
            <a :href="console_url" target="_blank"><i class="fa fa-external-link"></i> View in Console</a>
          </template>
        
          <ul class="list-group">

            <template v-if="form.upload">
              <router-link :to="{ 'name': 'uploads.edit', 'params': {'id': form.upload} }">
                <i class="fa fa-file-csv"></i> Created by upload
              </router-link>
            </template>

            <template v-for="field in fields">
              <template v-if="form[field['key']]">
                <li
                  class="list-group-item"
                >
                  <strong>{{ field['title'] }}</strong> 

                  <template v-if="field['type'] === 'datetime'">
                    {{ form[field['key']] | datetime }}
                  </template>

                  <template v-else>
                    {{ form[field['key']] }}
                  </template>
                </li>
              </template>
            </template>
          </ul>

        </div><!--Body-->
      </div><!--Card-->
    </template>

  </div>
</template>
<script>
export default {

props: [ 'form' ],

data() {

    return {
        processing: false,
        fields: [
            {key: 'created_by', title: 'Created By', type: 'string'},
            {key: 'updated_by', title: 'Updated By', type: 'string'},
            {key: 'created_at', title: 'Created', type: 'datetime'},
            {key: 'updated_at', title: 'Updated', type: 'datetime'},
            {key: 'deleted_at', title: 'Trashed', type: 'datetime'},
            {key: 'archived_at', title: 'Archived', type: 'datetime'},
            {key: 'region', title: 'Region', type: 'string'}
        ],
    }
},

computed: {
  console_url() {
    if(!this.$root.current_user || !this.$root.current_user.super_admin || !this.$root.current_user.current_agency) {
      return '';
    }
    return 'https://console.ecourtdate.com/logs?agency='+this.$root.current_user.current_agency.uuid+'&url='+this.$route.path;
  }
}

}
</script>