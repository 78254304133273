<template>
  <div>
    <div class="card mb-3">
      <div class="card-body">
        <p class="card-title">
          Send Message
        </p>

        <form @submit.prevent="postForm">

          <div class="row mb-3">

            <div class="col-12 col-md-6">

              <button
                type="button"
                class="btn btn-outline-primary btn-sm"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Auto Messages
              </button>
              <div class="dropdown-menu">
                <p class="dropdown-header">
                  Send Auto Message
                </p>

                <template v-for="auto_option in auto_messages">
                  <template v-if="auto_option.templates && auto_option.templates['en']">
                    <a
                      class="dropdown-item"
                      @click="changeAutoMessage(auto_option.uuid)"
                    >{{ auto_option.name }}</a>
                  </template>
                </template>
              </div>

            </div><!--Col-->

            <div class="col-12 col-md-6">

              <merge-tags
                @add-tag-to-message="addTagToMessage"
              />

            </div><!--Col-->

          </div><!--Row-->

          <div class="form-group" :hidden="client">
            <label for="to">To</label>
            <input
              id="to"
              v-model="form.to"
              type="text"
              class="form-control"
            >
          </div>

          <div class="form-group">
            <label for="subject">Subject</label>
            <input
              id="subject"
              v-model="form.subject"
              type="text"
              class="form-control"
            >
          </div>

          <div class="form-group">
            <label for="message">Message</label>
            <textarea
              id="message"
              ref="formContent"
              v-model="form.content"
              class="form-control"
              rows="3"
            />
          </div>

          <div class="row">
            <div class="col-6">
              <div class="form-group vbDatePicker">
                <label for="send_date">Send Date</label>
                <input
                  id="send_date"
                  v-model="form.date"
                  placeholder="mm/dd/yyyy"
                  type="date"
                  class="form-control"
                >
              </div>
            </div><!--Col-->
            <div class="col-6">
              <div class="form-group vbTimePicker">
                <label for="send_time">Send Time</label>
                <input
                  id="send_time"
                  v-model="form.time"
                  placeholder="hh:mm AM"
                  type="time"
                  class="form-control"
                >
              </div>
            </div><!--Col-->
          </div><!--Row-->

          <div class="form-group custom-control custom-switch">
            <input
              id="test"
              v-model="form.test"
              type="checkbox"
              class="custom-control-input"
            >
            <label
              class="custom-control-label"
              for="test"
            >Simulate</label>
          </div>

          <button
            type="submit"
            class="btn btn-success"
            :disabled="processing"
          >
            Send
          </button>
        </form>
      </div><!--Body-->
    </div><!--Card-->
  </div>
</template>

<script>
import _ from 'lodash';
export default {

  props: ['client', 'event', 'payment', 'case'],

  data () {
    return {
      processing: false,
      base_url: '/messages',
      form: {},
      auto_messages: [],
      auto_message: {}
    }
  },

  created() {
    this.resetForm();
    this.getAutoMessages();
  },

  methods: {

    resetForm() {
      this.form = {
        subject: null,
        content: null,
        client: null,
        event: null,
        payment: null,
        case: null,
        date: null,
        time: null,
        test: 0
      }
    },

    postForm() {

      if(this.client) {
        this.form.client = this.client;
      }

      if(this.event) {
        this.form.event = this.event;
      }

      if(this.payment) {
        this.form.payment = this.payment;
      }

      if(this.case) {
        this.form.case = this.case;
      }

      var url = this.base_url;

      if(!this.form.client && this.form.to) {
        url += '/oneoffs';
      }

      this.processing = true;
      this.$http.post(url, this.form)
      .then(response => {
        if(response && response.data) {

          this.processing = false;

          if(response.status === 201) {
            new this.$noty({text: 'Message created'}).show();
            this.resetForm();
          }
        }
      })
    },

    changeAutoMessage (uuid) {
        this.auto_message = _.find(this.auto_messages, {'uuid': uuid});
        this.form.content = this.auto_message.templates['en'];
        this.form.test = this.auto_message.simulate || 0;
        this.form.subject = this.auto_message.subject;

        if(this.auto_message.file_url) {
          this.form.file_url = this.auto_message.file_url;
        }
    },

    getAutoMessages () {
        this.$http.get('/auto_messages')
        .then(response => {
            if(response && response.data) {
                this.auto_messages = response.data;
            }
        })
    },

    addTagToMessage (value) {

      let selectionIndex = this.$refs.formContent.selectionStart;
      let newValue = '';
      if (this.form.content) {
        newValue = this.form.content.slice(0, selectionIndex) + value + this.form.content.slice(selectionIndex, this.form.content.length);
      } else {
        newValue = value;
      }
      this.form.content = newValue;

    },
  }
}
</script>