<template>
  <div>
    <template v-if="$route.meta.requiresAuth">
      <nav class="navbar navbar-expand-md navbar-dark bg-primary mb-3">
        <a href="/" class="d-md-block mr-3">
          <img
          :src="setLogoURL()"
          height="40"
          alt="eCourtDate"
          loading="lazy"
        ></a>

        <app-search />

        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#appNavBar"
          aria-controls="appNavBar"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon" />
        </button>

        <div
          id="appNavBar"
          class="collapse navbar-collapse"
        >
          <ul class="navbar-nav mr-auto d-print-none flex-wrap ml-2" data-html2canvas-ignore>

            <template v-if="preferences && preferences.enabled_navigations">
                
                <li
                  v-for="enabled_navigation in preferences.enabled_navigations"
                  class="nav-item"
                >

                  <router-link
                    :to="{ name: enabled_navigation+'.index' }"
                    class="nav-link text-capitalize"
                  >
                    {{ enabled_navigation }}
                  </router-link>

                </li>
                
            </template>

            <template v-for="user_nav in user_navs">

            <li
              class="nav-item tooltip-bottom"
              :hidden="isHidden(user_nav)"
            >
              <router-link
                :to="{ name: user_nav+'.index' }"
                class="nav-link text-capitalize"
              >
              {{ setNavLabel(user_nav) }}
              </router-link>
            </li>

            </template>


            <li
              class="nav-item dropdown dropright"
              :hidden="isHidden('settings')"
            >
              <a
                id="adminSettingsNav"
                class="nav-link dropdown-toggle"
                href="#"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="fa fa-cog d-lg-none"></i> 
                <span class="d-none d-lg-block">Admin</span>
            </a>
              <div
                class="dropdown-menu"
                aria-labelledby="adminSettingsNav"
                style="z-index: 999999"
              >

                <p class="dropdown-header">
                  Admin Settings
                </p>

                <template v-for="admin_nav in admin_navs">

                <router-link
                  class="dropdown-item text-capitalize"
                  :to="{ name: admin_nav+'.index' }"
                >
                  <template v-if="admin_nav == 'message_templates'">
                    Messages
                    <br><small class="text-muted">(Auto Messages / Flows)</small>
                    </template>
                    <template v-else>
                      {{ admin_nav.replace('_', ' ') }}                      
                    </template>
                </router-link>

                </template>

              </div>
            </li>


          </ul>
          <ul class="navbar-nav ml-4 d-print-none" data-html2canvas-ignore>



            <li class="nav-item dropdown dropleft">
              <a
                id="userDropdown"
                href="#"
                class="nav-link dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >

              <template v-if="preferences && preferences.privacy_mode == true">
                Privacy Mode
              </template>

              <template v-else>

              {{ $root.current_user.name }}

              </template>

              <template v-if="$root.current_user.photo">
                <img
                  :src="$root.current_user.photo"
                  class="rounded-circle"
                  width="30"
                  height="30"
                  :alt="$root.current_user.name"
                  >
              </template>
              <template v-else>
                <i class="fa fa-user"></i> 
              </template>
              </a>
              <div
                class="dropdown-menu dropdown-menu-right"
                aria-labelledby="userDropdown"
                style="z-index: 999999;"
              >

                <p class="dropdown-header">
                  User Settings
                </p>

                  <a
                    class="dropdown-item"
                    href="/users/current"
                  >Your Profile</a>

                  <a
                    class="dropdown-item"
                    data-toggle="modal"
                    data-target="#preferencesModal"
                    href="#"
                  >Preferences</a>

                <button
                  type="button"
                  class="dropdown-item"
                  @click.prevent="getLogout()"
                >
                  Logout
                </button>
              </div>
            </li>


          </ul>
        </div>
      </nav>

      <form @submit.prevent="updatePreferences()">

        <dialog
          id="preferencesModal"
          class="modal fade"
          tabindex="-1"
          aria-labelledby="preferencesModalTitle"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-dialog-scrollable modal-xl"
            role="document"
          >

            <div class="modal-content">
              <div class="modal-header">

                <p
                  id="preferencesModalTitle"
                  class="modal-title"
                >
                  Your Preferences
              </p>

                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div class="modal-body">


                <p class="m-0 mb-3">
                  Color Theme
                </p>

                <div class="row">
                  <template v-for="theme in themes">
                    <div
                      class="col"
                    >
                      <img
                        :src="'https://assets.ecourtdate.com/images/themes/'+theme+'.svg'"
                        class="mr-3"
                        :class="isCurrentTheme(theme)"
                        :alt="'App theme ' +theme"
                        width="200"
                        @click="toggleTheme(theme)"
                      >
                      <code class="text-uppercase">{{ theme }}</code>
                    </div><!--Col-->
                  </template>
                </div><!--Row-->

                <div class="form-group">
                  <label for="notify_timeout">In-app Notification Timeout</label>
                  <input
                    id="notify_timeout"
                    v-model="preferences.notify_timeout"
                    type="range"
                    class="form-control-range"
                    min="0"
                    max="10000"
                    step="100"
                    @change="updatePreferences()"
                    :disabled="processing"
                  >
                </div>

                <div class="form-group">
                  <label for="notify_layout">In-app Notification Layout</label>
                  <select
                    id="notify_layout"
                    v-model="preferences.notify_layout"
                    class="form-control"
                    @change="updatePreferences()"
                    :disabled="processing"
                  >
                    <template v-for="notify_layout in notify_layouts">
                      <option
                        :value="notify_layout"
                      >
                        {{ notify_layout }}
                      </option>
                    </template>
                  </select>
                </div>

                  <div class="form-group">
                    <label for="default_layout">Default Layout</label>
                    <select
                        id="default_layout"
                        v-model="preferences.default_layout"
                        class="form-control"
                        @change="updatePreferences()"
                        :disabled="processing"
                    >
                      <template v-for="default_layout in default_layouts">
                        <option
                            :value="default_layout"
                        >
                          {{ default_layout }}
                        </option>
                      </template>
                    </select>
                  </div>


                  <div class="form-group mb-3" :hidden="superAdmin != 1">
                  <label for="enabled_navigations">Top Navigation 
                    <template v-if="preferences.enabled_navigations && preferences.enabled_navigations.length">
                      <span class="badge badge-primary badge-pill">{{ preferences.enabled_navigations.length }}</span>
                    </template>
                  </label>
                  <multi-select
                    id="enabled_navigations"
                    v-model="preferences.enabled_navigations"
                    placeholder="Choose navigations"
                    tagPosition="top"
                    :close-on-select="false"
                    :multiple="true"
                    :options="availableNavs"
                  />

                  <div class="form-group custom-control custom-switch my-3">
                    <input
                        id="privacy_mode"
                        v-model="preferences.privacy_mode"
                        type="checkbox"
                        class="custom-control-input"
                        :disabled="processing"
                    >
                    <label
                        class="custom-control-label"
                        for="privacy_mode"
                    >Privacy Mode</label>
                  </div>


                </div>

              </div><!--Body-->

              <div class="modal-footer">

                <button
                  type="submit"
                  class="btn btn-success"
                  :disabled="processing"
                >
                  Update Preferences
                </button>

                <template v-if="preferences.updated_at">
                  <p class="text-muted">
                    Last Updated: {{ preferences.updated_at | datetime }}
                  </p>
                </template>

                <button
                  type="button"
                  class="btn btn-outline-danger"
                  :disabled="processing"
                  @click="resetPreferences"
                >
                  Reset Preferences
                </button>

              </div><!--Footer-->

            </div>
          </div><!--Modal-->
        </dialog>

      </form>

    </template>

  </div>
</template>

<script>
import Noty from 'noty';
import queries from '../mixins/queries';

export default {

    mixins: [queries],

    data () {
        return {
            agencies: [],
            processing: false,
            filtered: false,
            search_agencies: '',
            agencies_region: '',
            agencies_sort: 'name_asc',
            preferences: {},
            agency_sorts: [
              {title: 'Name a-z', value: 'name_asc'},
              {title: 'Name z-a', value: 'name_desc'},
              {title: 'Reference a-z', value: 'reference_asc'},
              {title: 'Reference z-a', value: 'reference_desc'},
              {title: 'Status active', value: 'status_desc'},
              {title: 'Status inactive', value: 'status_asc'}
            ],
            notify_layouts: ['top', 'topLeft', 'topCenter', 'topRight', 'center', 'centerLeft', 'centerRight', 'bottom', 'bottomLeft', 'bottomCenter', 'bottomRight'],
            default_layouts: ['grid', 'table'],
            themes: ['auto', 'light', 'dark'],
            regions: ['us-east', 'us-west', 'us-south'],
            admin_navs: ['settings', 'users', 'message_templates', 'portals', 'dashboards', 'files', 'locations'],
            user_navs: ['dashboard', 'clients', 'events', 'cases', 'payments', 'uploads', 'reports', 'bulk_actions'],
            nav_icons: {
              dashboard: 'tachometer-alt',
              clients: 'address-book',
              events: 'calendar-alt',
              cases: 'briefcase',
              payments: 'credit-card',
              uploads: 'cloud-upload-alt',
              reports: 'chart-bar',
              settings: 'cog',
              users: 'users',
              bulk_actions: 'tasks',
            },
            sub_navs: ['contacts', 'addresses', 'documents', 'upload_templates']
        }
    },

    computed: {
        
        superAdmin () {
            return this?.$root?.current_user?.super_admin;
        },

        isGlobalDomain() {

            var localURL = process.env.VUE_APP_API_URL;

            if(localURL != null) {
                return false;
            }

            const host = window.location.host;
            const parts = host.split('.');

            if (parts[0] === 'app') {
              return true;
            }

            return false;
        },

        availableNavs() {
            
            var navs = [];
  
            if(this.superAdmin === 1) {
              navs = [...this.admin_navs, ...this.user_navs];

            } else {
              navs = this.user_navs;
            }

            navs = [...navs, ...this.sub_navs]
  
            return navs;
  
        },

    },

    created() {
      var preferences = localStorage.getItem('preferences');

      if(preferences) {
        this.preferences = JSON.parse(preferences);
        this.setPreferences();
      }
        
        var self = this;

        this.$eventBus.$on('updateClientFields', function (fields) {
            self.preferences['client_fields'] = fields;
            self.updatePreferences();
        });

        this.$eventBus.$on('updateEventFields', function (fields) {
            self.preferences['event_fields'] = fields;
            self.updatePreferences();
        });

        this.$eventBus.$on('updatePreferences', function (form) {
            self.preferences = {...self.preferences, ...form};
            self.updatePreferences();
        });

        this.$eventBus.$on('refreshPreferences', function () {
            self.getPreferences();
        });

    },

    methods: {

        isHidden(navigation) {

          if(this.preferences && this.preferences.enabled_navigations && this.preferences.enabled_navigations.length > 0) {
            return true;
          }

            if(this.superAdmin === 1) {
                return false;
            }

            if(this.$root.current_user && this.$root.current_user.hidden_navigations && this.$root.current_user.hidden_navigations.length > 0) {
                return this.$root.current_user.hidden_navigations.includes(navigation);
            }

            return false;
        },

        getLogout() {
            this.$http.post(this.$apiURL + 'end_session')
            .then(response => {
                if(response && response.data) {
                    this.clearUser();
                    window.location.replace(response.data);
                }
            })
        },

        clearUser() {
            localStorage.clear();
            delete this.$http.defaults.headers.common['Authorization'];
        },

        getAgencies() {
            this.processing = true;

            var sort = 'name';
            var direction = 'asc';

            if(this.agencies_sort == 'name_asc') {
              sort = 'name';
              direction = 'asc';
            }

            if(this.agencies_sort == 'name_desc') {
              sort = 'name';
              direction = 'desc';
            }

            if(this.agencies_sort == 'reference_asc') {
              sort = 'reference';
              direction = 'asc';
            }

            if(this.agencies_sort == 'reference_desc') {
              sort = 'reference';
              direction = 'desc';
            }

            if(this.agencies_sort == 'status_asc') {
              sort = 'status';
              direction = 'asc';
            }

            if(this.agencies_sort == 'status_desc') {
              sort = 'status';
              direction = 'desc';
            }

            this.$http.get('/agencies?limit=25&slim=y&fields=uuid,name,reference,region,status&search='+this.search_agencies+'&region='+this.agencies_region+'&sort='+sort+'&sortDirection='+direction)
            .then(response => {
                if(response && response.data) {
                    this.processing = false;
                    this.agencies = response.data;
                }
            })
        },

        setNavLabel(nav) {

          if(['dashboard'].includes(nav)) {
            return nav;
          }

          nav = nav.replace('_', ' ');

          if(!this.settings || !this.settings['labels']) {
            return nav;
          }

          if(nav.slice(-1) == 's') {
            nav = nav.slice(0, -1);
          }

          var result = this.settings['labels'][nav] || nav;

          return result+'s';
        },

        setLogoURL() {

          var settings = localStorage.getItem('settings');

          if(settings) {
            settings = JSON.parse(settings);
          }

          this.setIconURL(settings);

          if(settings && settings['logo']) {
              return settings['logo'];
          }

          return '/images/logo-white.png';
        },


        setIconURL(settings) {

          var link = document.querySelector("link[rel~='icon']");

          if(!link) {
            return;
          }

          var url = 'https://assets.ecourtdate.com/images/icons/icon076@2x.png';

          if(settings && settings['icon']) {
              url = settings['icon'];
          }

          link.href = url;
          
        },

        isActiveAgency(agency) {

            if(!this.$root.current_user) {
                return false;
            }

            if(agency.uuid === this.localStorage('agency')) {
                return true;
            }

            return false;
        },


        updatePreferences() {
          
            this.$root.preferences = this.preferences;

            this.processing = true;
            this.$http.post('/users/preferences', this.preferences)
            .then(response => {
                if(response && response.data) {
                    new this.$noty({text: 'Updated preferences'}).show();
                    this.$eventBus.$emit('preferencesUpdated', response.data);
                    this.preferences = response.data;
                    this.setPreferences();
                    this.processing = false;
                  }
            })
        },

        getPreferences() {

          if(!this.$route.meta.requiresAuth) {
            return;
          }

            this.$http.get('/users/preferences')
            .then(response => {
                if(response && response.data) {
                    this.preferences = response.data;
                    this.setPreferences();
                }
            })
        },

        setPreferences() {

          if(!this.preferences) {
            return;
          }

          this.$root.preferences = this.preferences;

          localStorage.setItem('preferences', JSON.stringify(this.preferences));

          this.$eventBus.$emit('preferences_updated', {});

        },

        resetPreferences() {

          this.preferences = {
            "theme": "auto",
            "default_layout": "grid",
            "notify_layout": "top",
            "notify_timeout": 5000,
            "enabled_navigations": [],
            "privacy_mode": false
          }

          this.updatePreferences();

        },

        toggleTheme(theme) {
            if(localStorage.getItem('theme') != theme) {
                this.preferences['theme'] = theme;
                localStorage.setItem('theme', theme);
                this.updatePreferences();
            }  
        },

        isCurrentTheme(theme) {

            if(localStorage.getItem('theme') == theme) {
                return 'border border-primary border-3';
            }

        },

        toggleEnabledNavigation(navigation) {

            if(!this.$root.current_user) {
                return;
            }

            var preferences = this.preferences;

            if(!preferences.enabled_navigations) {
                preferences.enabled_navigations = [];
            }

            if(!preferences.enabled_navigations.includes(navigation)) {
                preferences.enabled_navigations = _.without(preferences.enabled_navigations, navigation);
                preferences.enabled_navigations.push(navigation);
            } else {
                preferences.enabled_navigations.push(navigation);
                preferences.enabled_navigations = _.without(preferences.enabled_navigations, navigation);
            }

        },

        isEnabledNavigation(nav) {
            
              if(!this.preferences) {
                  return;
              }
  
              if(!this.preferences.enabled_navigations) {
                  return;
              }
  
              if(this.preferences.enabled_navigations.includes(nav)) {
                  return true;
              }
  
              return false;
  
        },

        setNoty() {

          if(!this.preferences) {
            return;
          }

          if(!this.preferences.notify_timeout) {
            return;
          }

          if(!this.preferences.notify_layout) {
            return;
          }

          Noty.overrideDefaults({
            layout: this.preferences.notify_layout,
            timeout: this.preferences.notify_timeout
          });

        },

        localStorage(key, value) {

            if(!this.$root.current_user) {
                return;
            }

            if(!this.$root.current_user.uuid) {
                return;
            }

            if(!key) {
                return;
            }

            if(!value) {
                return localStorage.getItem(this.$root.current_user.uuid+'_'+key);
            }

            localStorage.setItem(this.$root.current_user.uuid+'_'+key, value);

        },

        showUserNav(nav) {
        }



    },
    
}
</script>
