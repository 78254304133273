<template>
  <div>

    <page-tabs page="portals" />

      <div class="row">
        <div class="col-sm-12 col-md-3">
          <div class="card">
            <div class="card-body">
              <form @submit.prevent="storeFiles()">
                <div class="form-group">
                  <label for="upload_files">Upload {{ $route.meta.title }}s</label>
                  <input
                    id="upload_files"
                    ref="files"
                    type="file"
                    class="form-control"
                    multiple="true"
                    :disabled="processing"
                  >
                </div>

                <template v-if="!uploadProgress">
                  <button
                    type="submit"
                    class="btn btn-success btn-block"
                    :disabled="processing"
                  >
                    Upload
                  </button>
                </template>

                <template v-if="processing && uploadProgress">
                  <div class="progress">
                    <div
                      class="progress-bar"
                      role="progressbar"
                      :style="'width:'+uploadProgress+'%'"
                    >
                      {{ uploadProgress }}%
                    </div>
                  </div>
                </template>
              </form>
            </div><!--Body-->
          </div><!--Card-->

          <div class="card mt-3">
            <div class="card-body">

            <p class="card-title">Search {{ $route.meta.title }}s</p>

            <form @submit.prevent="searchRecords">

                <div class="form-group">
                    <label for="search">Search</label>
                    <input id="search" type="search" class="form-control" v-model="queries.search" placeholder="Search by file name...">
                </div>

              <div class="form-group">
                <label for="type">Type</label>
                <select
                    id="type"
                    v-model="queries.type"
                    class="form-control"
                    :disabled="processing"
                >
                  <option value="" />
                  <template v-for="file_type in file_types">
                    <option
                        :value="file_type.key"
                    >
                      {{ file_type.label }}
                    </option>
                  </template>
                </select>
              </div>

                <div class="form-group">
                  <label for="created_by">Creator</label>
                  <select 
                    id="created_by"
                    v-model="queries.created_by"
                    class="form-control" 
                    :disabled="processing"
                  >
                    <option value="" />
                    <template v-for="user in users">
                      <option 
                        :value="user.email"
                      >
                        {{ user.name }} {{ user.email }}
                      </option>
                    </template>
                  </select>
                </div>

                <div class="form-group custom-control custom-switch">
                  <input
                    id="trashed"
                    v-model="queries.trashed"
                    type="checkbox"
                    class="custom-control-input"
                    :disabled="processing"
                  >
                  <label
                    class="custom-control-label"
                    for="trashed"
                  >Trashed</label>
                </div>

                <button type="submit" class="btn btn-primary" :disabled="processing">Search</button>

            </form>

            </div><!--Body-->
          </div><!--Card-->

          <div class="card mt-3">
            <div class="card-body">
              <p class="card-title">Manage Sent Files</p>

              <p>Manage and download files previously sent to clients.</p>

              <router-link :to="{name: 'sent_files.index'}" class="btn btn-primary">Browse</router-link>
            </div>
          </div>

        </div><!--Col-->

        <div class="col-sm-12 col-md-9">

        <div class="card">
          <div class="card-body">
            <div class="row">

            <div class="col-sm-12 col-md-6">

              <p class="card-title">
                {{ records.length }} of {{ total.toLocaleString() }} {{  $route.meta.title }}s
              </p>

            </div><!--Col-->

            <div class="col-sm-12 col-md-6 text-right">

              <div class="btn-group d-print-none">
                <button type="button" class="btn btn-secondary" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
                <button type="button" class="btn btn-secondary" @click="downloadCSV()" :hidden="!total" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
              </div>
            </div><!--Col-->

            </div><!--Row-->

            <div class="table-responsive my-3">
              <table class="table table-striped table-hover">
                <tr>
                  <template v-for="column in columns">
                    <th>
                      <template v-if="column.key">
                        <span
                          class="text-capitalize link-hover"
                          @click="sortColumn(column)"
                        >{{ column.label }}</span>
                      </template>

                      <template v-if="!column.key">
                        <span class="text-capitalize">{{ column.label }}</span>
                      </template>

                      <template v-if="column.key == queries.sort">
                        <button
                          type="button"
                          class="btn btn-outline-secondary btn-sm ml-1"
                          :disabled="processing"
                          @click="updateSortDirection()"
                        >
                          <template v-if="queries.sortDirection == 'asc'">
                            <i class="fa fa-arrow-up" /> <span class="sr-only">Asc</span>
                          </template>

                          <template v-if="queries.sortDirection == 'desc'">
                            <i class="fa fa-arrow-down" /> <span class="sr-only">Desc</span>
                          </template>
                        </button>
                      </template>
                    </th>
                  </template>
                </tr>
                <tbody>
                  <template v-for="record in records">
                    <tr>
                      <td>
                        <a :href="'/'+$route.meta.base_url+'/'+record.uuid">
                          <i class="fa fa-edit"></i> Edit
                        </a>
                      </td>
                      <td>
                            {{ record.name }}
                      </td>
                      <td class="text-uppercase">
                        <template v-if="record.type">
                          <template v-if="setFileType(record.type) == 'pdf'">

                          </template>
                          <template v-if="['jpeg', 'jpg', 'png', 'svg', 'gif'].includes(setFileType(record.type))">
                            <i class="fa fa-file-image"></i> {{ setFileType(record.type) }}
                          </template>
                          <template v-else-if="['csv', 'text/csv'].includes(setFileType(record.type))">
                            <i class="fa fa-file-csv"></i> csv
                          </template>
                          <template v-else-if="['text', 'text/plain'].includes(setFileType(record.type))">
                            <i class="fa fa-file-text"></i> text
                          </template>
                          <template v-else-if="['xlsx', 'xls'].includes(setFileType(record.type))">
                            <i class="fa fa-file-excel"></i> xlsx
                          </template>
                          <template v-else-if="['video/mp4'].includes(record.type)">
                            <i class="fa fa-file-video"></i> mp4
                          </template>
                          <template v-else-if="['video/ogg'].includes(record.type)">
                            <i class="fa fa-file-video"></i> ogg
                          </template>
                          <template v-else-if="['video/webm'].includes(record.type)">
                            <i class="fa fa-file-video"></i> webm
                          </template>
                          <template v-else-if="['video/mp3'].includes(record.type)">
                            <i class="fa fa-file-audio"></i> mp3
                          </template>
                          <template v-else-if="['audio/mpeg'].includes(record.type)">
                            <i class="fa fa-file-audio"></i> mpeg
                          </template>

                          <template v-else-if="['text/calendar'].includes(record.type)">
                            <i class="fa fa-calendar"></i> ics
                          </template>

                          <template v-else>
                            <i :class="'fa fa-file-'+setFileType(record.type)"></i> {{ setFileType(record.type) }}
                          </template>

                        </template>
                      </td>
                      <td>
                      <template v-if="record.size">
                        {{ record.size | fileSize }}
                      </template>
                      </td>
                      <td>
                        <template v-if="record.public">
                          <i class="fa fa-check-circle text-success"></i> Public
                        </template>
                        <template v-else>
                          <i class="fa fa-times-circle text-danger"></i> Private
                        </template>
                      </td>
                      <td>{{ record.created_by }}</td>
                      <td>{{ record.created_at | datetime }}

                        <template v-if="record.deleted_at">
                          <br><span class="badge badge-pill badge-danger">Trashed {{ record.deleted_at | datetime | timeago }}</span>
                        </template>

                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>

              
              <template v-if="!processing && !records.length">
                <div class="alert alert-warning">
                  No {{ $route.meta.title }}s found.
                </div>
              </template>


            </div>
          </div><!--Body-->
        </div><!--Card-->

        <template v-if="records && records.length && total > records.length">
          <div class="row mt-3">
            <div class="col-12 text-center">
              <button
                type="button"
                class="btn btn-outline-primary"
                :disabled="processing"
                @click="getRecords()"
              >
                Load more
              </button>
            </div><!--Col-->
          </div><!--Col-->
        </template>

        </div><!--Col-->
      </div><!--Row-->
  </div>
</template>
<script>
import queries from '../mixins/queries';

export default {

mixins: [ queries ],

data() {
    return {
        processing: false,
        form: {},
        uploadProgress: null,
        records: [],
        total: 0,
        queries: {},
        columns: [
        { label: 'Edit'},
        { key: 'name', label: 'File Name'},
        { key: 'type', label: 'Type'},
        { key: 'size', label: 'Size'},
        { key: 'public', label: 'Public' },
        { key: 'created_by', label: 'Creator'},
        { key: 'created_at', label: 'Created'}
        ],
        file_types: [
          { key: 'text/csv', label: 'CSV'},
          { key: 'image/png', label: 'PNG'},
          { key: 'image/jpeg', label: 'JPEG'},
          { key: 'application/pdf', label: 'PDF'},
          { key: 'text/csv', label: 'CSV'},
          { key: 'text/calendar', label: 'iCalendar'},
          { key: 'application/vnd.ms-excel', label: 'XLSX'},
          { key: 'image/gif', label: 'GIF'},
          { key: 'image/svg', label: 'SVG'},
          { key: 'text/plain', label: 'Text'},
          { key: 'video/mp4', label: 'MP4'},
          { key: 'video/ogg', label: 'OGG'},
          { key: 'video/webm', label: 'WEBM'},
          { key: 'video/mp3', label: 'MP3'}
        ],
        users: []
    }
},


created() {
    this.resetQueries();
    this.resetRecords();
    this.resetForm();
    this.setURLParams();
    this.getRecords();
    this.getUsers();
},

methods: {

    setURLParams() {
        var searchParams = new URLSearchParams(window.location.search);

        var self = this;

        searchParams.forEach(function(value, key) {
            self.queries[key] = value;
        });

    },

    resetQueries() {
      this.queries = {
          search: '',
          limit: 10,
          skip: 0,
          slim: true,
          trashed: false,
          client: null,
          type: null,
          created_by: null,
          sort: 'created_at',
          sortDirection: 'desc'
      }
    },

    resetRecords() {
        this.records = [];
        this.total = 0;
        this.queries.skip = 0;
    },

    getRecords() {

        this.processing = true;
        this.updateURL();
        this.getTotal();


        this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
        .then(response => {
            if(response && response.data) {
                this.processing = false;

                if(response.data.length) {
                    this.records = this.records.concat(response.data);
                    this.queries.skip += response.data.length;
                }
            }
        })
    },

    updateURL() {
      history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url, this.queries));
    },

    setTitle() {
      document.title = this.total + ' ' + this.$route.meta.title + 's | eCourtDate.com';
    },

    getTotal() {
        this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
        .then(response => {
            if(response && response.status === 200) {
                this.total = response.data;
                this.setTitle();
            }
        })
    },

    storeFiles() {

        const files = this.$refs.files.files;

        if(files && files.length > 0) {

            this.processing = true;

            for (let i = 0; i < files.length; i++) {
                
                let file = files.item(i);

                this.uploadFiles(file, {
                    progress: progress => {
                        this.uploadProgress = Math.round(progress * 100);

                        if(this.uploadProgress === 100) {
                          new this.$noty({text: this.$route.meta.title + ' uploaded'}).show();
                          this.uploadProgress = null;
                        }
                    }
                }).then(response => {
                  this.processing = false;

                  if(response.uuid) {
                    this.records.unshift(response);
                    this.total++;
                    new this.$noty({text: 'Adding '+this.$route.meta.title}).show();
                  }
                })

            }

            this.resetForm();

        }
    },

    searchRecords () {
        this.resetRecords();
        this.getRecords();
    },

    resetForm() {
      
        this.form = {
          language: 'en',
          public: false,
          attach_emails: true
        };

        if(this.$refs.file && this.$refs.file.value) {
          this.$refs.files.value = null;          
        }

    },

    updateSortDirection() {

      if(this.queries.sortDirection == 'asc') {
        this.queries.sortDirection = 'desc';
      } else {
        this.queries.sortDirection = 'asc';
      }

      this.resetRecords();

      this.getRecords();
    },

    sortColumn(column) {

      this.queries.sort = column.key;

      this.updateSortDirection();

    },

    getUsers() {
      this.$http.get('/users?slim=true&limit=1000&fields=name,email&sort=name&sortDirection=asc')
      .then(response => {
        if(response && response.data) {
          this.users = response.data;
        }
      })
    },

    async uploadFiles(file, options = {}) {
        const response = await this.$http.post(this.$apiURL + 'v1/files', {
            'bucket': 'ecdfiles',
            'name': file.name,
            'size': file.size,
            'type': file.type,
            'language': 'en',
            'attach_emails': true,
            'content_length': file.size,
            'extension': file.name.split('.').pop(),
            'content_type': options.contentType || file.type,
            'expires': options.expires || '',
            'visibility': options.visibility || ''
        }, {
            baseURL: options.baseURL || null,
            headers: options.headers || {},
            ...options.options
        });
        if (response && response.data) {

            let headers = response.data.upload_headers;

            if ('Host' in headers) {
                delete headers.Host;
            }

            if (typeof options.progress === 'undefined') {
                options.progress = () => {};
            }

            const cancelToken = options.cancelToken || '';

            await this.$axios.put(response.data.upload_url, file, {
                cancelToken: cancelToken,
                headers: headers,
                onUploadProgress: (progressEvent) => {
                    options.progress(progressEvent.loaded / progressEvent.total);
                }
            })

            response.data.extension = file.name.split('.').pop();

            return response.data;
        }
        return null;
    },

  setFileType(type) {

    if(!type) {
      return '';
    }

    const file_type = this.file_types.find(file_type => file_type.key === type);


    if(file_type) {
      return file_type.label.toLowerCase();
    }

    let file_types = [
      {'type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'label': 'xlsx'},
      {'type': 'application/vnd.ms-excel', 'label': 'xls'},
      {'type': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'label': 'docx'},
      {'type': 'application/msword', 'label': 'doc'},
      {'type': 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'label': 'pptx'},
      {'type': 'application/vnd.ms-powerpoint', 'label': 'ppt'},
      {'type': 'application/zip', 'label': 'zip'}
    ]

    if(file_types.find(file_type => file_type.type === type)) {
      return file_types.find(file_type => file_type.type === type).label;
    }

    return type.trim();
  },

}

}
</script>