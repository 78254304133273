<template>
  <div>

    <page-tabs :page="$route.meta.base_url" />

    <div class="row">
      <div class="col-sm-12 col-md-3">
        <template v-if="client && client.uuid">
          <div class="card mb-3">
            <div class="card-body">
              <div class="row mb-3">
                <div class="col-sm-12 col-md-6">
                  <p class="card-title">
                    Client 
                    <router-link :to="{ 'name': 'clients.edit', 'params': {'id': client.uuid} }">
                      {{ client.first_name }} {{ client.middle_name }} {{ client.last_name }}

                      {{ client.language }}
                    </router-link>
                  </p>
                </div><!--Col-->

                <div class="col-sm-12 col-md-6 text-right">
                  <button
                    type="submit"
                    class="btn btn-danger btn-sm"
                    :disabled="processing"
                    @click="closeClient"
                  >
                    <i class="fa fa-times" /> <span class="sr-only">Close</span>
                  </button>
                </div><!--Col-->
              </div><!--Row-->

              <ul class="list-group">
                <template v-if="client.client_reference">
                  <li class="list-group-item">
                    Reference {{ client.client_reference }}
                  </li>
                </template>

                <template v-if="client.type">
                  <li class="list-group-item">
                    Type {{ client.type }}
                  </li>
                </template>

                <template v-if="client.group">
                  <li class="list-group-item">
                    Group {{ client.group }}
                  </li>
                </template>
              </ul>
            </div><!--Body-->
          </div><!--Card-->
        </template>

        <template v-if="client && !client.uuid">
          <client-search />
        </template>

        <div class="card">
          <div class="card-body">

            <div class="row">

              <div class="col-sm-12 col-md-6">

                <p class="card-title">
                  Add {{ setCustomLabel(title ) }}
                  <template v-if="client && client.uuid">
                    for {{ client.client_reference }} {{ client.first_name }} {{ client.last_name }}
                  </template>
                </p>

              </div><!--Col-->

              <div class="col-sm-12 col-md-6 text-right">

                
              <button
                class="btn btn-outline-secondary btn-sm"
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Fields
              </button>
              <div class="dropdown-menu">
                <p class="dropdown-header">
                  Fields
                </p>
                <a
                  v-for="availableField in availableFields"
                  class="dropdown-item text-capitalize" 
                  :class="activeField(availableField.key)"
                  @click="toggleField(availableField)"
                >
                  {{ setCustomField('events', availableField.key) }}
                </a>
              </div>

          </div><!--Col-->

            </div><!--Row-->

            
            <form @submit.prevent="postForm">

              <div class="form-group">
                <label for="date">{{ setCustomField('events', 'date') }}</label>
                <input
                  id="date"
                  v-model="form.date"
                  type="date"
                  class="form-control"
                  required
                >
              </div>

              <template v-if="form.date">
                <div class="form-group">
                  <label for="time">{{ setCustomField('events', 'time') }}</label>
                  <input
                    id="time"
                    v-model="form.time"
                    type="time"
                    class="form-control"
                    required
                  >
                </div>
              </template>

              <div class="form-group" v-if="shouldShowField('event_reference')">
                <label for="event_reference">{{ setCustomField('events', 'event_reference') }}</label>
                <input
                  id="event_reference"
                  v-model="form.event_reference"
                  type="text"
                  class="form-control"
                >
              </div>

              <div class="form-group" v-if="shouldShowField('case_number')">
                <label for="case_number">{{ setCustomField('events', 'case_number') }}</label>
                <input
                  id="case_number"
                  v-model="form.case_number"
                  type="text"
                  class="form-control"
                >
              </div>

              <template v-if="field_options['statuses'] && field_options['statuses'].length > 0">
                <div class="form-group" v-if="shouldShowField('status')">
                  <label for="status">{{ setCustomField('events', 'status') }}</label>
                  <select
                    id="status"
                    v-model="form.status"
                    class="form-control"
                    :disabled="processing"
                  >
                    <option value="" />
                    <template v-if="field_options['statuses'] && field_options['statuses'].length > 0">
                      <template v-for="status in field_options['statuses']">
                        <option :value="status.name">{{ status.name }}</option>
                      </template>
                    </template>
                  </select>
                </div>
              </template>

              <template v-if="field_options['types'] && field_options['types'].length > 0">
                <div class="form-group" v-if="shouldShowField('type')">
                  <label for="type">{{ setCustomField('events', 'type') }}</label>
                <select
                  id="type"
                    v-model="form.type"
                    class="form-control"
                    :disabled="processing"
                  >
                    <option value="" />
                  <template v-if="field_options['types'] && field_options['types'].length > 0">
                    <template v-for="type in field_options['types']">
                      <option :value="type.type">{{ type.type }}</option>
                    </template>
                  </template>
                  </select>
                </div>
              </template>

              <div class="form-group" v-if="shouldShowField('description')">
                <label for="description">{{ setCustomField('events', 'description') }}</label>
                <input
                  id="description"
                  v-model="form.description"
                  type="text"
                  class="form-control"
                >
              </div>

              <div class="form-group" v-if="shouldShowField('flow')">
                <label for="flow">{{ setCustomField('events', 'flow') }}</label>
                <select
                  id="flow"
                  v-model="form.flow"
                  class="form-control"
                >
                  <option value="" />
                  <template v-if="field_options['flows'] && field_options['flows'].length > 0">
                    <template v-for="flow in field_options['flows']">
                      <option :value="flow.uuid">{{ flow.name }}</option>
                    </template>
                  </template>
                </select>
              </div>

              <div class="form-group" v-if="shouldShowField('portal')">
                <label for="portal">{{ setCustomField('events', 'portal') }}</label>
                <select
                  id="portal"
                  v-model="form.portal"
                  class="form-control"
                >
                  <option value="" />
                  <template v-if="field_options['portals'] && field_options['portals'].length > 0">
                    <template v-for="portal in field_options['portals']">
                      <option :value="portal.uuid">{{ portal.name }}</option>
                    </template>
                  </template>
                </select>
              </div>

              <div class="form-group" v-if="shouldShowField('location')">
                <label for="location">{{ setCustomField('events', 'location') }}</label>
                <select
                  id="location"
                  v-model="form.location"
                  class="form-control"
                >
                  <option value="" />
                  <template v-if="field_options['locations'] && field_options['locations'].length > 0">
                    <template v-for="location in field_options['locations']">
                      <option :value="location.uuid">{{ location.name }}</option>
                    </template>
                  </template>
                </select>
              </div>
              
              <template v-if="locationRooms.length > 0">
              <div class="form-group">
                <label for="room">{{ setCustomField('events', 'room') }}</label>
                <select id="room" v-model="form.room" class="form-control" :disabled="processing" @change="setLocationRoom()">
                <option value="" />
              <template v-for="room in locationRooms">
                <option :value="room.uuid">{{ room.name }}</option>
                      </template>
                    </select>
                  </div>
                </template>

              <template v-if="form.location">
              <div class="form-group" v-if="shouldShowField('location_notes')">
                <label for="location_notes">{{ setCustomField('events', 'location_notes') }}</label>
                <input
                  id="location_notes"
                  v-model="form.location_notes"
                  type="text"
                  class="form-control"
                >
                </div>
              </template>

              <template v-if="judges && judges.length > 0">
                <div class="form-group" v-if="shouldShowField('judge_name')">
                  <label for="judge_name">{{ setCustomField('events', 'judge_name') }}</label>
                  <select
                    id="judge_name"
                    v-model="form.judge_name"
                    class="form-control"
                  >
                    <option value="" />
                    <template v-for="judge in judges">
                      <option :value="judge.name">{{ judge.name }}</option>
                    </template>
                  </select>
                </div>
              </template>

              <button type="submit" class="btn btn-success" :disabled="processing">Add</button>

            </form>

          </div><!--Body-->
        </div><!--Card-->

        <div class="card mt-3">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <p class="card-title">
                  Search {{ setCustomLabel(title) }}s
                </p>
              </div><!--Col-->

              <div class="col-sm-12 col-md-6 text-right">
                <button
                  type="button"
                  data-tooltip="Reset search fields"
                  class="btn btn-outline-secondary"
                  :disabled="processing"
                  @click="resetSearch"
                >
                  <i class="fa fa-eraser" /> <span class="sr-only">Reset Form</span>
                </button>
              </div><!--Col-->
            </div><!--Row-->

            <form @submit.prevent="searchRecords()">

              <div class="form-group">
                <label for="search">SEARCH</label>
                <input
                  id="search"
                  v-model="queries.search"
                  type="search"
                  class="form-control"
                  placeholder="Reference"
                >
              </div>

              <div class="form-group">
                <label for="search_event_reference">{{ setCustomField('events', 'event_reference') }}</label>
                <input
                  id="search_event_reference"
                  v-model="queries.event_reference"
                  type="text"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="search_case_number">{{ setCustomField('events', 'case_number') }}</label>
                <input
                  id="search_case_number"
                  v-model="queries.case_number"
                  type="text"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="search_type">{{ setCustomField('events', 'type') }}</label>
                <select
                  id="search_type"
                  v-model="queries.type"
                  class="form-control"
                  @change="searchRecords()"
                  :disabled="processing"
                >
                  <option value="" />
                  <template v-if="event_types && event_types.length > 0">
                    <template v-for="event_type in event_types">
                      <option
                        :value="event_type.type"
                      >
                        {{ event_type.type }}
                      </option>
                    </template>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="search_status">{{ setCustomField('events', 'status') }}</label>
                <select
                  id="search_status"
                  v-model="queries.status"
                  class="form-control"
                  @change="searchRecords()"
                  :disabled="processing"
                >
                  <option value="" />
                  <template v-if="field_options['statuses'] && field_options['statuses'].length > 0">
                    <template v-for="search_status in field_options['statuses']">
                        <option
                          :value="search_status.name"
                        >
                          {{ search_status.name }}
                        </option>
                    </template>
                  </template>
                </select>
              </div>


              <div class="form-group">
                <label for="search_flow">{{ setCustomField('events', 'flow') }}</label>
                <select
                  id="search_flow"
                  v-model="queries.flow"
                  class="form-control"
                  @change="searchRecords()"
                  :disabled="processing"
                >
                  <option value=""></option>
                  <option value="none">
                    No Flow
                  </option>
                  <template v-if="field_options['flows'] && field_options['flows'].length > 0">
                    <template v-for="flow in field_options['flows']">
                      <option
                        :value="flow.uuid"
                      >
                        {{ flow.name }}
                      </option>
                    </template>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="search_location">{{ setCustomField('events', 'location') }}</label>
                <select
                  id="search_location"
                  v-model="queries.location"
                  class="form-control"
                  @change="searchRecords()"
                  :disabled="processing"
                >
                  <option value=""></option>
                  <option value="none">
                    No Location
                  </option>
                  <template v-if="field_options['locations'] && field_options['locations'].length > 0">
                    <template v-for="location in field_options['locations']">
                      <option
                        :value="location.uuid"
                      >
                        {{ location.name }}
                      </option>
                    </template>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="search_user">{{ setCustomField('events', 'user') }}</label>
                <select
                    id="search_user"
                    v-model="queries.user"
                    class="form-control"
                    @change="searchRecords()"
                    :disabled="processing"
                >
                  <option value="" />
                  <template v-if="field_options['users'] && field_options['users'].length > 0">
                    <template v-for="user in field_options['users']">
                      <option
                          :value="user.uuid"
                      >
                        {{ user.name }}
                      </option>
                    </template>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="search_judge">{{ setCustomField('events', 'judge_name') }}</label>
                <select
                  id="search_judge"
                  v-model="queries.judge"
                  class="form-control"
                  @change="searchRecords()"
                  :disabled="processing"
                >
                  <option value="" />
                  <template v-for="judge in judges">
                    <option
                        :value="judge.name"
                    >
                      {{ judge.name }}
                    </option>
                  </template>
                </select>
              </div>


              <div class="form-group">
                <label for="search_from_date">From Date</label>
                <input
                  id="search_from_date"
                  v-model="queries.from_date"
                  type="date"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="search_to_date">To Date</label>
                <input
                  id="search_to_date"
                  v-model="queries.to_date"
                  type="date"
                  class="form-control"
                >
              </div>
              
              <div class="form-group custom-control custom-switch">
                <input
                  id="search_virtual"
                  v-model="queries.virtual"
                  type="checkbox"
                  class="custom-control-input"
                >
                <label
                  class="custom-control-label"
                  for="search_virtual"
                >Virtual</label>
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                  id="archived"
                  v-model="queries.archived"
                  type="checkbox"
                  class="custom-control-input"
                  @change="searchRecords()"
                  :disabled="processing"
                >
                <label
                  class="custom-control-label"
                  for="archived"
                >Archived</label>
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                  id="trashed"
                  v-model="queries.trashed"
                  type="checkbox"
                  class="custom-control-input"
                  @change="searchRecords()"
                  :disabled="processing"
                >
                <label
                  class="custom-control-label"
                  for="trashed"
                >Trashed</label>
              </div>


              <button
                type="submit"
                class="btn btn-primary"
                :disabled="processing"
              >
                Search
              </button>

            </form>
          </div><!--Body-->
        </div><!--Card-->
      </div><!--Col-->

      <div class="col-sm-12 col-md-9">
        <div class="card">
          <div class="card-body">
            <div class="row">
 
              <div class="col-sm-12 col-md-6">

                <p class="card-title" :hidden="layout == 'grid'">
                  {{ records.length }} of {{ total.toLocaleString() }} {{  title }}s
                </p>

              </div><!--Col-->

              <div class="col-sm-12 col-md-6 text-right">

                <div class="btn-group d-print-none">
                  <button type="button" class="btn btn-secondary" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
                  <button type="button" class="btn btn-secondary" @click="downloadCSV()" :hidden="!total" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
                  <button type="button" class="btn btn-secondary" :class="{'active': layout === 'table'}" @click="layout = 'table'" :disabled="processing"><i class="fa fa-table"></i> <span class="sr-only">Table</span></button>
                  <button type="button" class="btn btn-secondary" :class="{'active': layout === 'grid'}" @click="updateCalendar" :disabled="processing"><i class="fa fa-calendar"></i> <span class="sr-only">Calendar</span></button>
                </div>
              </div><!--Col-->

            </div><!--Row-->

            <template v-if="layout == 'table'">

            <div class="table-responsive my-3">
            <table class="table table-hover table-striped">
              <thead>
                <tr>
                  <th>
                   Edit
                  </th>
                  <template v-for="column in tableFields">
                    <th :hidden="!column.show">

                      <template v-if="column.key">
                        <span
                          class="text-capitalize link-hover"
                          @click="sortColumn(column)"
                        >{{ setCustomField('events', column.key) }}</span>
                      </template>

                      <template v-if="!column.key">
                        <span class="text-capitalize">{{ column.title }}</span>
                      </template>

                      <template v-if="column.key && column.key == queries.sort">
                        <button
                          type="button"
                          class="btn btn-outline-secondary btn-sm ml-1"
                          :disabled="processing"
                          @click="updateSortDirection()"
                        >
                          <template v-if="queries.sortDirection == 'asc'">
                            <i class="fa fa-arrow-up" /> <span class="sr-only">Asc</span>
                          </template>

                          <template v-if="queries.sortDirection == 'desc'">
                            <i class="fa fa-arrow-down" /> <span class="sr-only">Desc</span>
                          </template>
                        </button>
                      </template>
                    </th>
                  </template>
                </tr>
              </thead>

              <tbody>
                <template v-for="record in records">
                
                  
                  <tr>

                    <td>
                      <a :href="'/'+$route.meta.base_url+'/'+record.uuid"><i class="fa fa-edit"></i> Edit</a>
                    </td>

                    <template v-for="field_row in tableFields">
                      
                      <td :hidden="!field_row.show">

                        <template v-if="field_row.type == 'date'">
                          
                          {{ record[field_row.key] | date }}
                        
                        </template>

                        <template v-if="field_row.type == 'time'">
                          
                          {{ record[field_row.key] | time }}

                        </template>

                        <template v-if="field_row.type == 'text'">
                          
                          <p contenteditable="true"
                            @blur="editRow(record, field_row, $event)"
                            >
                            {{ record[field_row.key] }}
                          </p>

                        </template>

                        <template v-if="field_row.type == 'longtext'">
                          
                          <template v-if="record[field_row.key]">
                          {{ $options.filters.truncateText(record[field_row.key], 50) }}
                          </template>
                    
                        </template>

                        <template v-if="field_row.type == 'list'">
                          
                          <template v-if="record[field_row.key]">
                          <span class="badge bg-secondary">{{ setOptionName(record[field_row.key], field_row.key) }}</span>
                          </template>

                        </template>

                        <template v-if="field_row.type == 'checkbox'">
                          
                          <i v-if="record[field_row.key] == true" class="fa fa-check text-primary" />
                          <i v-else class="fa fa-times text-danger" />

                        </template>

                        <template v-if="field_row.type == 'timestamp'">
                          
                          <template v-if="record[field_row.key]">
                            {{ record[field_row.key] | datetime }}
                          </template>

                        </template>


                      </td>
                    
                    </template>
                  </tr>

                </template>
              </tbody>
            </table>
            </div><!--Responsive-->

            </template>

            <template v-if="layout == 'grid'">


            <div class="row mb-3">
              <div class="col-12">

                <h4>{{ currentMonthName }} {{ currentYear }}</h4>

                <p>{{ records.length }} of {{ total.toLocaleString() }}</p>

                <div class="btn-group">
                <button @click="prevMonth" class="btn btn-light" :disabled="processing">&lt; Prev</button>
                <button @click="nextMonth" class="btn btn-light" :disabled="processing">&gt; Next</button>
                <template v-if="currentMonth != $moment().month() || currentYear != $moment().year()">
                  <button @click="goToCurrentMonth" class="btn btn-light" :disabled="processing">This Month</button>
                </template>
                </div><!--BtnGroup-->

                </div><!--Col-->
              </div><!--Row--->

            
              <div class="row week my-3" v-for="week in computedWeeks">

              <template v-for="day in week">
                <template v-if="isEnabledDay(day)">
                <div class="col-sm-12 col-md day link-hover" :class="setDayClass(day)" @click="addEvent(day)">
                    
                      {{ day.short_date }}

                      <template v-if="isHoliday(day)">
                        <div class="events">
                          <div class="event">
                            
                            {{ holiday_icons[setCurrentHoliday(day)] || '' }}
                            
                            {{ setCurrentHoliday(day) }}
                          </div>
                        </div>
                      </template>

                  <div class="events">
                    <div class="event w-100" v-for="event in day.events">
                      <a :href="'/events/'+event.uuid" class="text-decoration-none" target="_blank">
                        {{ setEventName(event) }}
                        <template v-if="event.end_date && event.date != event.end_date">
                          <br><small>Multi-Day {{ event.time | time }} - {{ event.end_time | time }}</small>
                        </template>
                        <template v-else>
                          <br><small>{{ event.time | time }} - {{ event.end_time | time }}</small>
                        </template>
                      </a>
                    </div>
                  </div>

                </div>
              </template>
              </template>

            </div>

            </template>


              <template v-if="!processing && !records.length">
                <div class="alert alert-warning">
                  No {{ title }}s found.
                </div>
              </template>

          </div><!--Body-->
        </div><!--Card-->

        <template v-if="layout == 'grid'">
          <template v-if="records && records.length">
                    <ul class="list-group my-3">
                      <li class="list-group-item" v-for="record in records">
                        <a :href="'/events/'+record.uuid" class="text-decoration-none" target="_blank">
                          {{ record.date | date }} {{ record.time | time }} 
                            <template v-if="record.end_date && record.date != record.end_date">
                              - {{ record.end_date | end_date }} {{ record.end_time | time }}
                            </template>
                            <template v-if="record.status">
                              <span class="badge bg-secondary">{{ setOptionName(record.status, 'status') }}</span>
                            </template>
                            <template v-if="record.type">
                              <span class="badge bg-secondary">{{ record.type }}</span>
                            </template>
                            <template v-if="record.description">
                              <p class="text-muted small">{{ record.description }}</p>
                            </template>
                            <template v-if="record.case_number">
                              <p class="text-muted">Case {{ record.case_number }}</p>
                            </template>
                        </a>
                      </li>
                    </ul>
              </template>
        </template>

        <template v-if="records && records.length && total > records.length">
          <div class="row mt-3">
            <div class="col-12 text-center">
              <button
                type="button"
                class="btn btn-outline-primary"
                :disabled="processing"
                @click="getRecords"
              >
                Load more
              </button>
            </div><!--Col-->
          </div><!--Row-->
        </template>
      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>
<script>
import _ from 'lodash';
import queries from '../mixins/queries';
import draggable from 'vuedraggable';

export default {

  components: { draggable },

  mixins: [ queries ],

data() {
    return {
        processing: false,
        title: null,
        layout: 'table',
        currentYear: null,
        currentMonth: null,
        records: [],
        queries: {},
        field_options: {},
        event_types: [],
        statuses: [],
        event_results: [],
        flows: [],
        locations: [],
        rooms: [],
        portals: [],
        judges: [],
        users: [],
        total: 0,
        form: {},
        client: {},
        columns: [
            {title: 'Created', key: 'created_at', type: 'timestamp', show: true},
            {title: 'Updated', key: 'updated_at', type: 'timestamp', show: true},
            {title: 'Archived', key: 'archived_at', type: 'timestamp', show: true}
        ],
        availableFields: [
          {title: 'Start Date', key: 'date', type: 'date', show: true},
          {title: 'End Date', key: 'end_date', type: 'date', show: true},
          {title: 'Start Time', key: 'time', type: 'time', show: true},
          {title: 'End Time', key: 'end_time', type: 'time', show: true},
          {title: 'Event Reference', key: 'event_reference', type: 'text', show: true},
          {title: 'Case Number', key: 'case_number', type: 'text', show: true},
          {title: 'Status', key: 'status', type: 'list', show: true},
          {title: 'Virtual', key: 'virtual', type: 'checkbox', show: true},
          {title: 'Flow', key: 'flow', type: 'list', show: true},
          {title: 'Location', key: 'location', type: 'list', show: true},
          {title: 'Location Notes', key: 'location_notes', type: 'longtext', show: true},
          {title: 'Portal', key: 'portal', type: 'list', show: true},
          {title: 'Judge', key: 'judge_name', type: 'text', show: true},
          {title: 'Description', key: 'description', type: 'longtext', show: true},
          {title: 'Type', key: 'type', type: 'list', show: true}
        ],
        fields: [],
        holidays: [],
        unreachables: [],
        holiday_icons: {
          "Independence Day": "🇺🇸",
          "Christmas Day": "🎄",
          "New Year's Day": "🎉",
          "Thanksgiving Day": "🦃",
          "Memorial Day": "🎖️",
          "Labor Day": "🏖️",
          "Veterans Day": "🪖"
        }
    }
},

computed: {

    tableFields: function() {
      var fields = this.fields;

      var columns = this.columns;

      return fields.concat(columns);
      
    },

    currentMonthName() {
      return this.$moment().month(this.currentMonth).format('MMMM');
    },

    computedWeeks() {
      const days = this.createMonth();
      const weeks = [];
      let week = [];
      days.forEach(day => {
        if (new Date(day.year, day.month, day.date).getDay() === 0 && week.length > 0) {
          weeks.push(week);
          week = [];
        }
        week.push(day);
      });
      if (week.length > 0) {
        weeks.push(week);
      }
      return weeks;
    },

    locationRooms: function() {
      if(!this.form.location) {
        return [];
      }
      return this.rooms.filter(room => room.location == this.form.location);
    }
    
    },

mounted() {

    if(this.fields.length < 1) {
      this.fields = this.availableFields;
    }

    var self = this;

    this.$eventBus.$on('search_clients', function(uuid) {

       self.queries.client = uuid;

       self.getClient();

    });

    this.$eventBus.$on('preferences_updated', function() {
      self.setEventFields();
    });

    this.setEventFields();


},

created() {

    this.currentYear = this.$moment().year();

    this.currentMonth = this.$moment().month();

    if(!this.$root.preferences) {
      this.getPreferences();
    }

    this.title = this.setCustomLabel(this.$route.meta.title);

    this.resetForm();
    this.resetQueries();
    this.setURLParams();

    if(this.queries.client) {
        this.getClient();
    } else {
        this.getRecords();        
    }

    this.getStatuses();
    this.getFlows();
    this.getLocations();
    this.getRooms();
    this.getPortals();
    this.getOptions('users');
    this.getUnreachables();
    this.getEventTypes();

    this.getJudges();

    this.getHolidays();
},

methods: {


    resetSearch() {
        this.resetQueries();
        this.searchRecords();
    },

    resetQueries() {
        this.queries = {
            date: '',
            from_date: '',
            to_date: '',
            from_time: '',
            to_time: '',
            search: '',
            event_reference: '',
            case_number: '',
            type: '',
            flow: null,
            location: null,
            judge: null,
            user: null,
            client: null,
            virtual: null,
            sort: 'created_at',
            sortDirection: 'desc',
            limit: 10,
            slim: true,
            archived: false,
            trashed: false,
            upload: null
        }
    },

    setTitle() {
      document.title = this.total + ' ' + this.title + 's | eCourtDate.com';
    },

    setURLParams() {
        var searchParams = new URLSearchParams(window.location.search);

        var self = this;

        searchParams.forEach(function(value, key) {

            if(!value || value == 'null') {
              return;
            }

            self.queries[key] = value;
        });
    },


    getEventTypes() {
        this.$http.get('/event_types')
        .then(response => {
            if(response && response.data) {
                this.event_types = response.data;

                if(this.event_types.length) {
                  this.field_options['types'] = this.event_types;
                }
            }
        })
    },

    getStatuses() {

      this.field_options['statuses'] = [];
      this.field_options['statuss'] = [];

        this.$http.get('/statuses?limit=1000&slim=true&fields=name&sort=name&sortDirection=asc&type=event')
        .then(response => {
            if(response && response.data) {

                if(!response.data.length) {
                  return;
                }

                var results = response.data;

                results = results.map(function(result) {
                  return {
                    name: result.name,
                    uuid: result.name
                  }
                });

                this.field_options['statuses'] = results;
                this.field_options['statuss'] = results;
            }
        })
    },

    getLocations() {
      this.$http.get('/locations?limit=100&slim=true&fields=name,uuid,default&sort=name&sortDirection=asc')
      .then(response => {
        if(response && response.data) {
         var locations = response.data;

          if(!locations.length) {
            locations = [];
          }

          this.field_options['locations'] = locations;

          let defaultLocation = locations.find(location => location.default);

          if(defaultLocation && !this.form.location) {
            this.form.location = defaultLocation.uuid;
          }

        }
      })
    },

    getRooms() {
      this.$http.get('/rooms?limit=1000&slim=true&fields=name,uuid,location&sort=name&sortDirection=asc')
      .then(response => {
        if(response && response.data) {
          this.rooms = response.data;
        }
      })
    },

    setLocationRoom() {
      if(!this.form.room) {
        this.form.location_notes = null;
        return;
      }

      var notes = this.rooms.find(room => room.uuid == this.form.room).name || null;

      if(!notes) {
        return;
      }

      notes = notes.replace(/^Room: /, '');

      this.form.location_notes = 'Room: '+notes;
    },

    getPortals() {
      this.$http.get('/portals?limit=100&slim=true&fields=name,uuid,default&sort=name&sortDirection=asc')
      .then(response => {
        if(response && response.data) {
          this.field_options['portals'] = response.data;

          let defaultPortal = response.data.find(portal => portal.default);

          if(defaultPortal && !this.form.portal) {
            this.form.portal = defaultPortal.uuid;
          }
        }
      })
    },

    getFlows() {
      this.$http.get('/flows?limit=100&slim=true&fields=name,uuid&sort=default&sortDirection=desc')
      .then(response => {
        if(response && response.data) {
          var flows = response.data;

          flows = flows.filter(flow => !flow.type || flow.type == 'event');
          // this.flows = this.flows.filter(flow => !flow.type || flow.type == 'event');

          if(!flows.length) {
            flows = [];
          }

          if(flows.length && !this.form.flow) {
            this.form.flow = flows[0].uuid;
          }

          flows.sort((a, b) => a.name.localeCompare(b.name));

          this.field_options['flows'] = flows;
        }
      })
    },

    getOptions(key) {
      this.$http.get('/'+key+'?limit=100&slim=true&fields=name,uuid&sort=name&sortDirection=asc')
          .then(response => {
            if(response && response.data) {
              this.field_options[key] = response.data;
            }
          })
    },

    getUnreachables() {
        this.$axios.get("https://assets.ecourtdate.com/app/js/unreachables.json")
        .then(response => {
            if(response && response.data) {
                this.unreachables = response.data;
            }
        })
    },

    setOptionName(record, key) {

      var field_key = 'uuid';

      if(key == 'reached' && this.unreachables && this.unreachables[record]) {
        return this.unreachables[record];
      }

      if(key == 'status') {
        field_key = 'name';
      }

      var options = this.field_options[key+'s'];

      var option = _.find(options, { 'name': record });

      if(option && option.name) {
        return option.name;
      }

      var option = _.find(options, { 'uuid': record });

      if(option && option.name) {
        return option.name;
      }

      return record;

    },

    searchRecords() {
        this.resetRecords();
        this.getRecords();
    },

    updateCalendar() {
      this.resetRecords();
      this.layout = 'grid';
      this.getRecords();
    },

    resetRecords() {
      this.queries.skip = 0;
      this.total = 0;
      this.records = [];
    },

    getTotal() {
        this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
        .then(response => {
            if(response && response.status === 200) {
                this.total = response.data;
                this.setTitle();
            }
        })
    },

    updateURL() {
        history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url, this.queries));
    },

    getRecords() {

        this.processing = true;

        if(this.layout == 'grid' && this.currentMonth && this.currentYear) {
          this.queries.from_date = this.$moment().year(this.currentYear).month(this.currentMonth).startOf('month').format('YYYY-MM-DD');
          this.queries.to_date = this.$moment().year(this.currentYear).month(this.currentMonth).endOf('month').format('YYYY-MM-DD');
          this.queries.sort = 'date';
          this.queries.sortDirection = 'asc';
          this.queries.limit = 250;
        }

        this.updateURL();
        this.getTotal();

        this.queries.slim = true;

        this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
        .then(response => {
            if(response && response.data) {

                if(response.data.length) {

                    var self = this;

                    response.data.forEach(function(data) {

                      data.start = self.convertToTimezone(data.date + ' ' + data.time, self.timezone);

                      var end_date = data.end_date || data.date;

                      var end_time = data.end_time || data.time;

                      data.end = self.convertToTimezone(end_date + ' ' + end_time, self.timezone);

                        self.records.push(data);
                    });

                }

                this.queries.skip = this.records.length;

                this.processing = false;
            }
        })
    },

    toggleArchivedEvents() {

        if(this.queries.archived === true) {
            this.queries.archived = false;
        } else {
            this.queries.archived = true;
        }

        this.getRecords();
    },

    toggleTrashedEvents() {

        if(this.queries.trashed === true) {
            this.queries.trashed = false
        } else {
            this.queries.trashed = true
        }

        this.getRecords();
    },

    getJudges () {
      this.$http.get('/judges?slim=true&fields=name&sort=name&sortDirection=asc&limit=100&fields=name,uuid')
      .then(response => {
        if(response && response.data) {
          this.judges = response.data;
        }
      })
    },

    getUsers() {
        this.$http.get('/users?slim=true&fields=name,uuid&sort=name&sortDirection=desc&limit=100')
        .then(response => {
            if(response && response.data) {
                this.users = response.data;
            }
        })
    },

    updateSortDirection() {

      if(this.queries.sortDirection == 'asc') {
        this.queries.sortDirection = 'desc';
      } else {
        this.queries.sortDirection = 'asc';
      }

      this.resetRecords();
      
      this.getRecords();
    },

    sortColumn(column) {

      this.queries.sort = column.key;

      this.updateSortDirection();
    },

    getClient() {

        if(!this.queries.client) {
            return;
        }

        this.$http.get('/clients/'+this.queries.client+'?slim=true&fields=uuid,client_reference,first_name,last_name,group,type,language')
        .then(response => {
            if(response && response.data) {
                this.client = response.data;

                if(!this.client.language) {
                  this.client.language = 'en';
                }

                this.queries.client = this.client.uuid;
                this.searchRecords();
            }
        })
    },

    resetClient() {
        this.queries.client = null;
        this.client = {};
    },

    closeClient() {
        this.resetClient();
        this.resetRecords();
        this.getRecords();
    },

  activeField(field) {

    var activeField = _.find(this.fields, { 'key': field });

    if(activeField && activeField.show && activeField.show === true) {
      return 'active';
    }
  },

  toggleField(field) {

    var fields = this.fields;

    if(!fields || !fields.length) {
      fields = this.availableFields;
    }

    var activeField = _.find(fields, { 'key': field.key });

    if(!activeField) {
      field.show = false;
      fields.push(field);
      activeField = field;
    }

    if(activeField.show) {
      activeField.show = false;
    } else {
      activeField.show = true;
    }

    this.fields = _.map(this.fields, function(f) {
      if(f.key == field.key) {
        f.show = activeField.show;
      }
      return f;
    });

    this.$eventBus.$emit('updateEventFields', this.fields);
  },

  setFieldTitle(field) {

    if(field.title) {
      return field.title;
    }

    var fields = localStorage.getItem('fields');

    if(!fields || !fields.length) {
      return field.title;
    }

    var search = field.key;

    var title = _.find(JSON.parse(fields), { 'key': 'events.'+search });

    if(!title || !title.name) {
      return field.title;
    }

    return title.name;
  },

  setEventFields() {
    if(this.$root.preferences && this.$root.preferences.event_fields) {
      this.fields = this.$root.preferences.event_fields;
    } else {
      this.fields = this.availableFields;
    }
  },

  shouldShowField(field) {

    if(!this.fields || !this.fields.length) {
      return true;
    }

    var activeField = _.find(this.fields, { 'key': field });

    return activeField && activeField.show;
  },

  resetForm() {
    this.form = {
      client: null,
      date: null,
      time: null,
      event_reference: null,
      case_number: null,
      flow: null,
      location: null,
      location_notes: null,
      description: null,
      type: null,
      status: null,
      appeared: null,
      virtual: 0,
      judge_name: null,
      internal_notes: null,
      url: null
    }

    if(!this.form.date) {
      this.form.date = this.$moment().tz(this.timezone).add(1, 'day').format('YYYY-MM-DD');
    }

    if(!this.form.time) {

      var time = '08:00';

      if(this.settings && this.settings.business_from_time) {
        time = this.settings.business_from_time;
      }

      this.form.time = time;
    }
  },

  postForm() {

    if(this.client && this.client.uuid) {
      this.form.client = this.client.uuid;
    }

    if(this.client_uuid && !this.form.client) {
      this.form.client = this.client_uuid;
    }

    this.form.skip_sync = true;

    this.processing = true;

    this.$http.post('/'+this.$route.meta.base_url, this.form)
        .then(response => {
          if(response && response.data) {

            if(response.data.uuid) {
              new this.$noty({text: this.title + ' created'}).show();
              this.records.unshift(response.data);
              this.total++;
              this.resetForm();
              this.setTitle();
              this.$http.post('events/'+response.data.uuid+'/process')
            }

            this.processing = false;

          }
        })
  },

  editField(field, event) {

    var label = event.target.innerText;

    if(!label) {
      return;
    }

    if(!field.key) {
      return;
    }

    if(field.title == label) {
      return;
    }

    this.fields = _.map(this.fields, function(f) {
      if(f.key == field.key) {
        f.title = label;
      }
      return f;
    });

    this.$eventBus.$emit('updateEventFields', this.fields);
  },

  dragEnd(event) {
    this.$eventBus.$emit('updateEventFields', this.fields);
  },

  editRow(record, field, event) {

    var value = event.target.innerText;

    if(!value) {
      return;
    }

    if(!field.key) {
      return;
    }

    if(record[field.key] == value) {
      return;
    }

    record[field.key] = value;

    this.$http.patch('/'+this.$route.meta.base_url+'/'+record.uuid, record)
        .then(response => {
          if(response && response.status === 200) {
            new this.$noty({text: this.title + ' updated'}).show();
          }
        })
    },

    getDaysInMonth(month, year) {
          return this.$moment().tz(this.timezone).month(month).daysInMonth();
    },

    createMonth() {
      const daysInMonth = this.getDaysInMonth(this.currentMonth, this.currentYear);
      const days = [];
      for (let i = 1; i <= daysInMonth; i++) {
        // const date = new Date(this.currentYear, this.currentMonth, i);

        const date = this.$moment().tz(this.timezone).year(this.currentYear).month(this.currentMonth).date(i).toDate();
        
        const dayEvents = this.records.filter(event => {

            // if multi-day event
            if(event.start != event.end) {
              var start = this.$moment(event.date).tz(this.timezone).toDate();
              var end = this.$moment(event.end).tz(this.timezone).toDate();

              if(date >= start && date <= end) {
                return true;
              }
            } else {
            const eventDate = this.$moment(event.start).tz(this.timezone).toDate();
            return eventDate.getFullYear() === this.currentYear && eventDate.getMonth() === this.currentMonth && eventDate.getDate() === i;
            }
          });

        dayEvents && dayEvents.sort((a, b) => {
            return this.$moment(a.start).toDate() - this.$moment(b.start).toDate();
        });

        days.push({
          date: i,
          full_date: this.$moment(date).tz(this.timezone).format('YYYY-MM-DD'),
          short_date: this.$moment(date).tz(this.timezone).format('ddd Do'),
          month: this.currentMonth,
          year: this.currentYear,
          events: dayEvents
        });
      }
      return days;
    },

    convertToTimezone(dateString, timezone) {
        return this.$moment(dateString).format('YYYY-MM-DD HH:mm');
    },

    prevMonth() {
      if (this.currentMonth === 0) {
        this.currentMonth = 11;
        this.currentYear -= 1;
      } else {
        this.currentMonth -= 1;
      }

      this.resetRecords();
      this.getRecords();
    },

    goToCurrentMonth() {
      this.currentMonth = this.$moment().month();
      this.currentYear = this.$moment().year();
      this.resetRecords();
      this.getRecords();
    },

    nextMonth() {
      if (this.currentMonth === 11) {
        this.currentMonth = 0;
        this.currentYear += 1;
      } else {
        this.currentMonth += 1;
      }

      this.resetRecords();
      this.getRecords();
    },

        setDayClass(day) {
          var today = this.$moment().tz(this.timezone).format('YYYY-MM-DD');

          var date = this.$moment(day).format('YYYY-MM-DD');

          if(today === date) {
              return 'bg-primary text-white';
          }

          return '';
        },

        setEventName(event) {

          var result = '';

          var options = ['virtual', 'description', 'type', 'status', 'location_notes', 'judge_name'];

          options.forEach(field => {
            if(event[field]) {

              var value = event[field];

              if(field == 'virtual') {
                value = event[field] == true ? 'Virtual' : 'In Person';
              }

              result += value + ' ';
            }
          });

          return result.trim();
          },

          addEvent(day) {

          var date = this.$moment(day.full_date).tz(this.timezone).format('YYYY-MM-DD');

          this.form.date = date;

          this.form.time = '08:00';

          },

          isHoliday(day) {
            var date = this.$moment(day).tz(this.timezone);

            for(var i = 0; i < this.holidays.length; i++) {
              if(date.isSame(this.holidays[i].date, 'day')) {
                return true;
              }
            }

            return false;
          },

          setCurrentHoliday(day) {
            var date = this.$moment(day).tz(this.timezone);

            for(var i = 0; i < this.holidays.length; i++) {
              if(date.isSame(this.holidays[i].date, 'day')) {
                return this.holidays[i].name;
              }
            }
          },

          getNthDayOfMonth(n, weekday, month, year) {

            if(month < 10) {
              month = '0' + month;
            }

          let firstDay = this.$moment(`${year}-${month}-01`).tz(this.timezone);
          let dayOffset = (7 + weekday - firstDay.day()) % 7;
          return firstDay.add((n - 1) * 7 + dayOffset, 'days');
          },

          getLastMondayOfMonth(month, year) {
            if (month < 10) {
              month = '0' + month;
            }

            let lastDay = this.$moment(`${year}-${month}`).tz(this.timezone).endOf('month');
            let dayOfWeek = lastDay.day(); // 0 (Sunday) to 6 (Saturday)
            let dayOffset = (dayOfWeek >= 1) ? dayOfWeek - 1 : 6; // Adjust for Monday
            return lastDay.subtract(dayOffset, 'days');
          },

          getNthWeekdayOfMonth(n, weekday, month, year) {

          if(month < 10) {
            month = '0' + month;
          }

          let date = this.$moment(`${year}-${month}-01`).tz(this.timezone);
          date = date.day() <= weekday ? date.add(weekday - date.day(), 'days') : date.add(7 + weekday - date.day(), 'days');

          return date.add((n - 1) * 7, 'days');
          },

          setHolidays(year) {
            var holidays = {
                "New Year's Day": this.$moment(`${year}-01-01`).tz(this.timezone),
                "Martin Luther King Jr. Day": this.getNthDayOfMonth(3, 1, 1, year),
                "Presidents' Day": this.getNthWeekdayOfMonth(3, 1, 2, year),
                "Independence Day": this.$moment(`${year}-07-04`).tz(this.timezone),
                "Memorial Day": this.getLastMondayOfMonth(5, year),
                "Labor Day": this.getNthDayOfMonth(1, 1, 9, year),
                "Veterans Day": this.$moment(`${year}-11-11`).tz(this.timezone),
                "Thanksgiving Day": this.getNthWeekdayOfMonth(4, 4, 11, year),
                "Christmas Day": this.$moment(`${year}-12-25`).tz(this.timezone),
                "Juneteenth": this.$moment(`${year}-06-19`).tz(this.timezone),
            };

            var results = [];

            for (var holiday in holidays) {
                if (holidays.hasOwnProperty(holiday)) {
                    results.push({
                        name: holiday,
                        date: holidays[holiday],
                        value: holidays[holiday].format('YYYY-MM-DD')
                    });
                }
            }

            return results;
        },

        getHolidays() {
          this.holidays = this.setHolidays(this.$moment.tz(this.timezone).year());
        },

        isEnabledDay(day) {
          var date = this.$moment(day).tz(this.timezone);

          if(!this.settings || !this.settings['business_days']) {

            if(date.day() == 0 || date.day() == 6) {
            return false;
          }

          }

          var days = this.settings['business_days'];

          if(!days || !days.length) {
            return true;
          }

          if(days.includes(date.format('ddd').toLowerCase())) {
            return true;
          }

          return false;
        },

}

}
</script>