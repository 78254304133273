<template>
  <div>

    <page-tabs page="auto_messages" />

    <div class="row">

      <template v-for="record in records">

      <div class="col-sm-12 col-md-4">

        <div class="card mb-3">
          <div class="card-body">

            <div class="row mb-3">

              <div class="col-sm-12 col-md-9">
                <p class="card-title">{{ record.name | rmDashes }}</p>

                <template v-if="record.trigger">
                  <span class="badge badge-pill bg-secondary text-uppercase">Trigger: {{ record.trigger | rmDashes }}</span>
                </template>

                <template v-if="record.value">
                  <span class="badge badge-pill bg-secondary text-uppercase">Value: {{ record.value | rmDashes }}</span>
                </template>

              </div><!--Col-->

              <div class="col-sm-12 col-md-3 text-right">
                  <button type="button" class="btn btn-success btn-sm" @click="importRecord(record)" :disabled="processing"><i class="fa fa-download"></i> Import</button>
              </div><!--Col-->

            </div><!--Row-->

            <template v-if="record.templates && record.templates['en']">
                  <p class="sms outbound">{{ record.templates['en'] }}</p>

                  <div class="btn-group">
                    <a :href="'/preview?content='+record.templates['en']" class="btn btn-secondary" :disabled="processing"><i class="fal fa-mobile"></i> Preview</a>
                    <button type="button" class="btn btn-primary" @click="copyToClipboard(record.templates['en'])" :disabled="processing"><i class="fa fa-copy"></i> Copy to Clipboard</button>
                  </div>

            </template>

          </div><!--Body-->
        </div><!--Card-->

      </div><!--Col-->

      </template>

    </div><!--Row--> 

  </div>
</template>
<script>
import _ from 'lodash';
import queries from '../mixins/queries';

export default {
  
  mixins: [ queries ],

  data () {
    return {
      processing: false,
      records: [],
      queries: {},
      triggers: [],
      form: {}
    }
  },

  created () {
    this.resetQueries();
    this.resetForm();
    this.getTriggers();
    this.getRecords();
  },

  methods: {

    resetQueries() {
      this.queries = {
        slim: false
      }
    },

    resetForm() {
      this.form = {
        name: null,
        trigger: null,
        simulate: true,
        templates: {}
      }
    },

    getTriggers() {
        
        this.$axios.get(this.$root.assets_url+'js/options.json')
        .then(response => {
          if(response && response.data) {
            this.triggers = response.data.flow_message_triggers;
          }
        })

    },

    setMessageName(message) {
        var trigger = _.find(this.triggers, {'value': message.trigger});

        if(trigger && trigger.label) {
          return trigger.label;
        }

        return message.trigger;
    },

    getRecords() {
      this.processing = true;
      this.$http.get(this.$apiURL + 'auto_templates?limit=100&sort=name&sortDirection=asc')
      .then(response => {
        if(response && response.data) {
          this.records = response.data;
          this.processing = false;
        }
      })
    },

    importRecord(record) {

      this.processing = true;

      this.form.name = record.name;
      this.form.trigger = record.trigger;
      this.form.templates = record.templates;

      this.form.simulate = false;

      this.processing = true;

      this.$http.post('/auto_messages', this.form)
      .then(response => {
        if(response && response.data) {

          this.processing = false;

          if(response.data.uuid) {
            this.resetForm();
            this.$router.push('/auto_messages/'+response.data.uuid);
          }
          
        }
      })
    }
  }
}
</script>
