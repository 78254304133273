<template>
  <div>

    <page-tabs page="clients" />

    <div class="row">
      <div class="col-12 col-lg-3 mb-3">
        <client-add />

    <div class="card mt-3">
      <div class="card-body">

        <form @submit.prevent="searchRecords">

        <div class="row">
          <div class="col-sm-12 col-md-6">
            <p class="card-title">
              Search {{ title }}s
            </p>
          </div><!--Col-->
          <div class="col-sm-12 col-md-6 text-right">
            <div class="btn-group">
            <button
              type="button"
              class="btn btn-secondary btn-sm"
              :disabled="processing"
              @click="resetSearch"
            >
            Reset
            </button>
            <button
              type="button"
              class="btn btn-primary btn-sm"
              :disabled="processing"
              @click="searchRecords"
            >
            Search
            </button>
            </div><!--BtnGroup-->
          </div><!--Col-->
        </div><!--Row-->

          <div class="form-group">
            <label for="search">Search</label>
            <input
              id="search"
              v-model="queries.search"
              type="text"
              class="form-control"
            >
          </div>

          <template v-if="clientGroups && clientGroups.length">

          <p class="form-label">{{ chosen_groups.length }} of {{ clientGroups.length}} Client Groups</p>

          <template v-for="client_group in clientGroups">
            <span
              :value="client_group.group"
              class="badge badge-pill mr-1 mb-3 link-hover"
              :class="setGroupClass(client_group)"
              @click="toggleClientGroup(client_group)"
            >
              {{ client_group }}
            </span>
          </template>

          </template>

          <template v-if="organizations && organizations.length > 0">
            <div class="form-group">
              <label for="search_organization">Organization</label>
              <select
                id="search_organization"
                v-model="queries.organization"
                class="form-control"
                @change="searchRecords"
                :disabled="processing"
              >
                <option value="" />
                <template v-for="organization in organizations">
                  <option
                    :value="organization.uuid"
                  >
                    {{ organization.name }}
                  </option>
                </template>
              </select>
            </div>
          </template>

          <div class="form-group">
            <label for="search_type">Client Type</label>
            <select
              id="search_type"
              v-model="queries.type"
              class="form-control"
              @change="searchRecords"
              :disabled="processing"
            >
              <option value="" />
              <template v-for="client_type in client_types">
                <option
                  :value="client_type"
                >
                  {{ client_type }}
                </option>
              </template>
            </select>
          </div>

          <div class="form-group">
            <label for="search_language">Preferred Language</label>
            <select
              id="search_language"
              v-model="queries.language"
              class="form-control text-capitalize"
              @change="searchRecords"
              :disabled="processing"
            >
              <option value="" />
              <template v-for="language in languages">
                <option
                  :value="language.key"
                >
                  {{ language.label }}
                </option>
              </template>
            </select>
          </div>

          <div class="form-group">
              <label for="search_status">Status</label>
              <select
                id="search_status"
                v-model="queries.status"
                class="form-control"
                @change="searchRecords"
                :disabled="processing"
                >
                <option value=""></option>
                <template v-for="status in statuses">
                  <option
                    :value="status.name"
                  >{{ status.name }}</option>
                </template>
              </select>
            </div>

            <template v-if="!processing && !statuses.length">
                <a href="/statuses?type=client" target="_blank" class="alert-link">Manage client statuses</a>
            </template>

          <div class="form-group">
            <label for="search_user">Assigned User</label>
            <select id="search_user" class="form-control" v-model="queries.user" 
            @change="searchRecords"
            :disabled="processing">
              <option value=""></option>
              <template v-for="user in users">
                <option :value="user.uuid">{{ user.name }} {{ user.email }}</option>
              </template>
            </select>
          </div>

          <div class="form-group">
            <label for="search_created_by">Creator</label>
            <select
              id="search_created_by"
              v-model="queries.created_by"
              class="form-control"
              @change="searchRecords"
              :disabled="processing"
            >
              <option value="" />
              <template v-for="creator in users">
                <option
                  :value="creator.email"
                >
                  {{ creator.name }} {{ creator.email }}
                </option>
              </template>
            </select>
          </div>

          <div class="row">

            <div class="col-sm-12 col-md-6">

              <div class="form-group custom-control custom-switch">
                <input
                  id="is_archived"
                  v-model="queries.archived"
                  type="checkbox"
                  class="custom-control-input"
                  @change="searchRecords"
                  :disabled="processing"
                >
                <label
                  class="custom-control-label"
                  for="is_archived"
                >Archived</label>
              </div>

            </div><!--Col-->

            <div class="col-sm-12 col-md-6">

              <div class="form-group custom-control custom-switch">
                <input
                  id="is_trashed"
                  v-model="queries.trashed"
                  type="checkbox"
                  class="custom-control-input"
                  @change="searchRecords"
                  :disabled="processing"
                >
                <label
                  class="custom-control-label"
                  for="is_trashed"
                >Trashed</label>
              </div>

            </div><!--Col-->

          </div><!--Row-->

          <button
            type="submit"
            class="btn btn-primary"
            :disabled="processing"
          >
            Search
          </button>
        </form>
      </div><!--Body-->
    </div><!--Card-->
      </div><!--Col-->

      <div class="col-12 col-lg-9">
        <div class="card">
          <div class="card-body">

            <div class="row">

            <div class="col-sm-12 col-md-6">

              <p class="card-title">
                {{ records.length }} of {{ total.toLocaleString() }} {{  title }}s
              </p>

            </div><!--Col-->

            <div class="col-sm-12 col-md-6 text-right">

              <div class="btn-group d-print-none">
                <button type="button" class="btn btn-secondary" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
                <button type="button" class="btn btn-secondary" @click="downloadCSV()" :hidden="!total" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
              </div>
            </div><!--Col-->

            </div><!--Row-->

            <template v-if="actions && actions.records && actions.records.length > 0">

              <p class="text-success">
                {{ actions.records.length }} selected
              </p>

              <div class="row">

                <div class="col-sm-12 col-md-6">
                  <div class="btn-group">
                  <button type="button" class="btn btn-danger" @click="deselectAll()" :disabled="processing"><i class="fa fa-times"></i> Deselect All</button>
                  <template v-if="actions.records.length < this.total">
                    <button type="button" class="btn btn-primary" @click="selectAll()" :disabled="processing"><i class="fa fa-check"></i> Select All</button>                    
                  </template>
                  <button type="button" class="btn btn-outline-danger" @click="archiveSelected()" :disabled="processing" :hidden="queries.archived || queries.trashed"><i class="fa fa-archive"></i> Archive</button>
                  <button type="button" class="btn btn-outline-danger" @click="trashSelected()" :disabled="processing" :hidden="queries.archived || queries.trashed"><i class="fa fa-trash"></i> Trash</button>
                  <button type="button" class="btn btn-outline-success" @click="restoreSelected()" :disabled="processing" :hidden="!queries.archived && !queries.trashed"><i class="fa fa-undo"></i> Restore</button>
                  </div>

                </div><!--Col-->

              </div>

              <div class="row my-3" v-if="actions.action">

                <div class="col-sm-12 col-md-6">

                  <form @submit.prevent="postAction()">

                    <template v-if="actions.action">

                      <p class="text-danger">
                        Are you sure you want to {{ actions.action }} {{ actions.records.length }} {{ title }}s?
                      </p>

                    </template>

            
                <button type="button" class="btn btn-secondary" @click="resetActionsForm()" :disabled="processing">Cancel</button>


                <button type="submit" class="btn btn-success" :disabled="processing">Confirm</button>

                </form>


                </div><!--Col-->

              </div>

              </template>

            <div class="table-responsive my-3">

            <table class="table table-hover table-striped">
              <thead>
                <tr>
                  <th>Edit</th>
                  <th @click="toggleSelect()" class="link-hover">Select</th>
                  <template v-for="column in columns">
                    <th>
                      <template v-if="column.key">
                        <span
                          class="text-capitalize link-hover"
                          @click="sortColumn(column)"
                        >{{ setColumnTitle(column) }}</span>
                      </template>

                      <template v-if="!column.key">
                        <span class="text-capitalize">{{ column.label }}</span>
                      </template>

                      <template v-if="column.key == queries.sort">
                        <button
                          type="button"
                          class="btn btn-outline-secondary btn-sm ml-1"
                          :disabled="processing"
                          @click="updateSortDirection()"
                        >
                          <template v-if="queries.sortDirection == 'asc'">
                            <i class="fa fa-arrow-up" /> <span class="sr-only">Asc</span>
                          </template>

                          <template v-if="queries.sortDirection == 'desc'">
                            <i class="fa fa-arrow-down" /> <span class="sr-only">Desc</span>
                          </template>
                        </button>
                      </template>
                    </th>
                  </template>
                </tr>
              </thead>
              <tbody>
                <template v-for="record in records">
                  <tr>
                    <td>
                      <router-link :to="{ 'name': $route.meta.base_url+'.edit', 'params': {'id': record.uuid} }">
                        <i class="fa fa-edit"></i> Edit
                      </router-link>
                    </td>
                    <td>
                      <label
                        class="sr-only"
                        :for="'record-'+record.uuid"
                        aria-label="Select"
                      >Select</label>
                      <input
                        :id="'record-'+record.uuid"
                        type="checkbox"
                        :value="record.uuid"
                        v-model="actions.records"
                      />
                    </td>
                    <td
                    :class="isPrivacyMode ? 'privacy-mode' : ''"
                    >
                        <span v-if="record.name && !record.first_name">
                          {{ record.name }}
                        </span>
                        <span v-if="record.first_name">
                          {{ record.first_name }}
                        </span>
                        <span v-if="!record.name && !record.first_name">
                          <i class="fa fa-user-circle" /> {{ title }}
                        </span>
                    </td>
                    <td
                    :class="isPrivacyMode ? 'privacy-mode' : ''"
                    >{{ record.middle_name }}</td>
                    <td
                    :class="isPrivacyMode ? 'privacy-mode' : ''"
                    >{{ record.last_name }}</td>
                    <td>{{ record.client_reference }}</td>
                    <td>
                      <template v-if="record.group">
                        <span class="badge badge-pill text-uppercase link-hover" :class="setGroupClass(record.group)" @click="toggleClientGroup(record.group)">{{ record.group }}</span>
                      </template>
                    </td>
                    <td>{{ record.type }}</td>
                    <td>{{ record.status }}</td>
                    <td>{{ setLanguageName(record) }}</td>
                    <td>{{ record.dob | date }}</td>
                    <td>
                      <total-button
                        :client="record.uuid"
                        :url="'contacts'"
                      />
                    </td>
                    <td>
                      <total-button
                        :client="record.uuid"
                        :url="'messages'"
                      />
                    </td>
                    <td>{{ record.created_by }}</td>
                    <td>
                      <template v-if="record.deleted_at">
                        <span class="badge badge-danger badge-pill">TRASHED</span><br>
                      </template>
                      <template v-if="record.archived_at">
                        <span class="badge badge-warning badge-pill">ARCHIVED</span><br>
                      </template>
                      {{ record.created_at | datetime }}
                    </td>
                    <td>{{ record.updated_at | datetime }}</td>
                  </tr>
                </template><!--Row-->
              </tbody>
            </table>
            </div><!--TableResponsive-->

            <template v-if="!processing && !records.length">
              <div class="alert alert-warning">
                No {{ title }}s found.
              </div>
            </template>
          </div><!--Body-->
        </div><!--Card-->

        <template v-if="records && records.length && total > records.length">
        <div class="row mt-3">
          <div class="col-12 text-center">
            <button
              type="button"
              id="loadMoreButton"
              ref="loadMoreButton"
              class="btn btn-outline-primary"
              :disabled="processing"
              @click="getRecords"
            >
              Load more
            </button>
          </div><!--Col-->
        </div><!--Row-->
        </template><!--LoadMore-->


      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>
<script>
import queries from '../mixins/queries';
import _ from 'lodash';

export default {
  
  mixins: [ queries ],

  data () {
    return {
      processing: false,
      downloading: false,
      title: null,
      records: [],
      downloads: [],
      total: 0,
      queries: {},
      columns: [
      {label: 'first name', key: 'first_name'},
      {label: 'middle name', key: 'middle_name'},
      {label: 'last name', key: 'last_name'},
      {label: 'reference', key: 'client_reference'},
      {label: 'group', key: 'group'},
      {label: 'type', key: 'type'},
      {label: 'status', key: 'status'},
      {label: 'language', key: 'language'},
      {label: 'dob', key: 'dob'},
      {label: 'contacts'},
      {label: 'messages'},
      {label: 'creator', key: 'created_by'},
      {label: 'created', key: 'created_at'},
      {label: 'updated', key: 'updated_at'}
      ],
      statuses: [],
      client_groups: [],
      chosen_groups: [],
      client_types: [],
      organizations: [],
      users: [],
      actions: {
        action: null,
        records: []
      },
    }

  },

  computed: {

    clientGroups: function() {

      if(!this.$root.current_user || !this.$root.current_user.current_agency) {
        return;
      }

      var groups = [];

      if(this.settings && this.settings.default_groups) {
        groups = this.settings.default_groups;

        groups = groups.split(",");

        groups = groups.map(g => g.trim());
      }

      var client_groups = [];

      if(this.client_groups && this.client_groups.length > 0) {
        client_groups = this.client_groups.map(r => r.group);

        client_groups.forEach(g => {
          if(!groups.includes(g)) {
            groups.push(g);
          }
        })
      }

      if(!groups) {
        return [];
      }

      return groups.map(g => g.trim());
    }

  },

  mounted () {

    var self = this;
    
    this.$eventBus.$on('addClient', function (data) {
       self.addNewRecord(data);
    });

    this.$eventBus.$on('scrolling', function() {
        self.shouldLoadMore();
    });

  },
  
  created () {
    this.resetQueries();
    this.resetRecords();
    this.setURLParams();
    
    this.title = this.setCustomLabel(this.$route.meta.title);

    this.getRecords();
    this.getStatuses();
    this.getOrganizations();
    this.getUsers();
    this.getClientGroups();
    this.getClientTypes();
  },

  methods: {

    setURLParams() {
        var searchParams = new URLSearchParams(window.location.search);

        var self = this;

        searchParams.forEach(function(value, key) {

            if(!value || value == 'null') {
              return;
            }

            self.queries[key] = value;
        });
    },

    resetSearch() {
      this.resetQueries();
      this.updateURL();
      this.resetRecords();
      this.getRecords();
    },

    resetQueries() {
      this.queries = {
        sort: 'created_at',
        sortDirection: 'desc',
        search: '',
        skip: 0,
        limit: 20,
        total: false,
        slim: true,
        status: null,
        upload: null,
        groups: [],
        user: null,
        language: null,
        type: null,
        created_by: null,
        archived: false,
        trashed: false
      }

      this.chosen_groups = [];
    },

    resetRecords() {
      this.records = [];
      this.total = 0;
      this.queries.skip = 0;
    },

    setLanguageName(record) {

      if(!record.language) {
        return;
      }

      var language = _.find(this.languages, {'key': record.language});

      if(!language || !language.label) {
        return record.language;
      }

      return language.label;
    },

    setGroupClass(group) {

      if(this.chosen_groups && this.chosen_groups.includes(group)) {
        return 'badge-primary';
      }

      return 'badge-secondary';
    },

    toggleClientGroup(group) {

      if(this.chosen_groups && !this.chosen_groups.includes(group)) {
        this.chosen_groups.push(group);

        this.searchRecords();
        return;
      }

      this.chosen_groups.splice(this.chosen_groups.findIndex((obj => obj === group)), 1);

      this.searchRecords();
    },

    getRecords() {

      if(this.chosen_groups) {
        this.queries.groups = this.chosen_groups;
        this.queries.groups.join(',');
      }

      this.setAssignedUser();

      this.getTotal();

      this.updateURL();

      this.processing = true;

      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
      .then(response => {
        if(response && response.data) {
          if(response.data.length) {

              var self = this;

              response.data.forEach(function(data) {
                  self.records.push(data);
              });
          }

          this.queries.skip = this.records.length;
          this.processing = false;
        }
      })
    },

    getTotal() {
      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
      .then(response => {
        if(response && response.status === 200) {
          this.total = response.data;
          this.setTitle();
        }
      })
    },

    setAssignedUser() {
      if(!this.$root.current_user || !this.$root.current_user) {
        return;
      }

      if(this.$root.current_user.permissions && this.$root.current_user.permissions.length > 0) {
          if(!this.queries.user && this.$root.current_user.permissions.includes('only_assigned_clients')) {
            this.queries.user = this.$root.current_user.uuid;
          }
      }
    },

    setTitle() {
      var title = this.title;

      title = title.charAt(0).toUpperCase() + title.slice(1);

      document.title = this.total + ' '+title+'s | eCourtDate.com';
    },

    updateURL() {
      history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url, this.queries));
    },

    addNewRecord(data) {

      var index = _.findIndex(this.records, {'uuid': data.uuid});

      if(index == -1) {
        this.total++;
        this.records.unshift(data);
        this.setTitle();
        return;
      }

      var records = [];

      this.records.forEach(function(record) {

        if(record.uuid == data.uuid) {
          record = {...record, ...data};
        }

        records.push(record);


      })

      this.records = records;

    },

    updateSortDirection() {

      if(this.queries.sortDirection == 'asc') {
        this.queries.sortDirection = 'desc';
      } else {
        this.queries.sortDirection = 'asc';
      }

      this.resetRecords();

      this.getRecords();
    },

    sortColumn(column) {

      this.queries.sort = column.key;

      this.updateSortDirection();
    },

    searchRecords () {
      this.resetRecords();
      this.getRecords();

      new this.$noty({ text: 'Searching '+this.title+'s' }).show();

    },

    getUsers () {
      this.$http.get('users?limit=1000&slim=y&fields=name,email,uuid&sort=name&sortDirection=asc')
      .then(response => {
        if(response && response.data) {
          this.users = response.data;
        }
      })
    },

    getClientGroups () {
      this.$http.get('/client_groups?limit=100')
      .then(response => {
        if(response && response.data) {
          this.client_groups = response.data;
        }
      })
    },

    getClientTypes () {
      this.$http.get('/client_types?limit=20')
      .then(response => {
        if(response && response.data && response.data.length) {
          this.client_types = response.data.map((r) => r.type);
        }
      })
    },

    getOrganizations() {
      this.$http.get('/organizations')
      .then(response => {
        if(response && response.data) {
          this.organizations = response.data;
        }
      })
    },

    setColumnTitle(field) {

        var fields = localStorage.getItem('fields');

        if(!fields || !fields.length) {
          return field.label;
        }

        var search = field.key.toLowerCase();

        var title = _.find(JSON.parse(fields), { 'key': 'clients.'+search });      

        if(!title || !title.name) {
          return field.label;
        }

        return title.name;
    },

    setFieldTitle(field) {

        var fields = localStorage.getItem('fields');

        if(!fields || !fields.length) {
          return field;
        }

        var search = field.toLowerCase();

        var title = _.find(JSON.parse(fields), { 'key': 'clients.'+search });      

        if(!title || !title.name) {
          return field;
        }

        return title.name;
    },

    shouldLoadMore() {

      if(this.processing) {
        return;
      }

      if(this.total <= this.records.length) {
        return;
      }

      const loadMoreButton = this.$refs.loadMoreButton;

      if(!loadMoreButton) {
        return;
      }

      const rect = loadMoreButton.getBoundingClientRect();
      if (rect.top <= window.innerHeight) {
        this.getRecords();
        return;
      }
      },

      resetActionsForm() {
        this.actions = {
          action: null,
          records: []
        }
      },

      deselectAll() {
        this.actions.records = [];
      },

      selectAll() {
        this.actions.records = this.records.map(function(record) {
          return record.uuid;
        });
      },

      toggleSelect() {
        if(this.actions.records.length == this.total) {
          this.deselectAll();
          return;
        }

        this.selectAll();
      },

      postAction() {

        if(!this.actions.action) {
          return;
        }

        if(!this.actions.records || !this.actions.records.length) {
          return;
        }

        this.processing = true;

        var self = this;

        this.actions.records.forEach(function(uuid) {

          if(self.actions.action == 'trash') {
            self.postTrash(uuid);
            return;
          }

          if(['archive', 'restore'].includes(self.actions.action)) {
            self.putAction(uuid, self.actions.action);
          }

        })

      },

      removeResetActions(uuid) {
        if(this.actions.records.includes(uuid)) {
          this.actions.records.splice(this.actions.records.indexOf(uuid), 1);
        }

        var index = _.findIndex(this.records, {'uuid': uuid});

        if(index > -1) {
          this.records.splice(index, 1);
          if(this.total > 0) {
            this.total--;
          }
        }

        if(this.actions.records.length == 0) {
          this.processing = false;
          this.resetActionsForm();
        }
      },

      putAction(uuid, path) {
        this.$http.put('/'+this.$route.meta.base_url+'/'+uuid+'/'+path)
        .then(response => {
          if(response && response.status === 200) {
            this.removeResetActions(uuid);
          }
        })
      },

      postTrash(uuid) {
        this.$http.delete('/'+this.$route.meta.base_url+'/'+uuid)
        .then(response => {
          if(response && response.status === 204) {
            this.removeResetActions(uuid);
          }
        })
      },

      trashSelected() {
        this.actions.action = 'trash';
      },

      archiveSelected() {
        this.actions.action = 'archive';
      },

      restoreSelected() {
        this.actions.action = 'restore';
      },

      getStatuses() {
      this.$http.get('/statuses?limit=100&type=client&slim=true&sort=name&sortDirection=asc&fields=name')
      .then(response => {
        if(response && response.data) {
          this.statuses = response.data;
        }
      })
    },

  }
}
</script>