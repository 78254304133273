<template>
  <div>
  <p class="small text-muted">
    <i class="fa fa-mobile"></i> {{ platform }} {{ browser }} {{ os }}
  </p>
  </div>
</template>
<script>
export default {

props: {
  raw: {
    type: String,
    default: null
  }
},

data() {
    return {
        browser: '',
        os: '',
        device: ''
    }
},


created() {
    this.parseUserAgent();
},

methods: {


    parseUserAgent() {

      this.platform = /Mobile|Tablet|iPad|iPhone/.test(this.raw) ? 'Mobile' : 'Desktop';
      
      if (this.raw.includes('Firefox')) {
        this.browser = 'Firefox';
      } else if (this.raw.includes('Chrome')) {
        this.browser = 'Chrome';
      } else if (this.raw.includes('Safari') && !this.raw.includes('Chrome')) {
        this.browser = 'Safari';
      } else if (this.raw.includes('Edg')) {
        this.browser = 'Edge';
      }
      
      if (this.raw.includes('Windows')) {
        this.os = 'Windows';
      } else if (this.raw.includes('Macintosh') || this.raw.includes('Mac OS X')) {
        this.os = 'macOS';
      } else if (this.raw.includes('Android')) {
        this.os = 'Android';
      } else if (this.raw.includes('iOS') || this.raw.includes('iPhone') || this.raw.includes('iPad')) {
        this.os = 'iOS';
      }
      
    }


    }

}
</script>