<template>
  <div>

    <archived-trashed-status
      :form="form"
      :processing="processing"
      :title="$route.meta.title"
    />
        
    <div class="row">
      <div class="col-sm-12 col-md-4">
        <template v-if="form.name">
          <h1>{{ form.name }}</h1>
        </template>
        <template v-if="form.opened_at">
          <span class="badge badge-success">Opened {{ form.opened_at | dateTimezone | timeago }}</span>
        </template>
        <template v-else>
          <span class="badge badge-secondary">Not Opened</span>
        </template>
          <p>
            <router-link :to="{name: 'portals.index'}">Portals</router-link> /
            <router-link :to="{name: $route.meta.base_url+'.index'}">{{ $route.meta.title }}s</router-link> / Edit {{ $route.meta.title }}</p>
      </div><!--Col-->

      <div class="col-sm-12 col-md-2">

        <template v-if="portalLink">
          <a :href="portalLink" class="btn btn-outline-success mr-3" target="_blank" :disabled="processing" :hidden="form.status == 'draft'"><i class="fa fa-link"></i> Open Portal</a>
        </template>

        <template v-if="portalLink && qr_code">
          <a :href="portalLink" target="_blank"
          :hidden="form.status == 'draft'"
          >
          <img
            :src="'data:image/svg+xml;base64,'+qr_code"
            width="50"
            :alt="'Go to '+form.name"
          >
          </a>
        </template>

      </div><!--Col-->

      <div class="col-sm-12 col-md-2">
          <router-link :to="{'name': 'forms.submissions', 'params': { 'id': $route.params.id } }" tag="button" class="btn btn-outline-primary">
            <i class="fa fa-file-spreadsheet"></i> 
            <template v-if="total_submissions">
            View {{ total_submissions }} Submissions
            </template>
            <template v-else>
              No Submissions Yet
            </template>
          </router-link>
      </div>


      <div class="col-sm-12 col-md-4 text-right">
        <div class="btn-group">

            <div class="btn-group">
              <button
                type="button"
                class="btn btn-outline-primary dropdown-toggle radius-right-0"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Tools
              </button>
              <div class="dropdown-menu">
                <a
                  v-if="!form.archived_at && !form.deleted_at"
                  class="dropdown-item"
                  @click="archiveRecord()"
                >Archive</a>
                <a
                  v-if="form.archived_at || form.deleted_at"
                  class="dropdown-item"
                  @click="restoreRecord()"
                >Restore</a>
                <a
                  v-if="!form.deleted_at && !form.archived_at"
                  class="dropdown-item"
                  @click="trashRecord()"
                >Trash</a>
              </div>
          </div><!--BtnGroup-->
            
          <button
            v-if="!form.archived_at && !form.deleted_at"
            type="button"
            class="btn btn-success"
            :disabled="processing"
            @click="postForm()"
          >
            Save
          </button>

          <router-link
            :to="{ name: $route.meta.base_url+'.index' }"
            class="btn btn-danger"
          >
            Close
          </router-link>

        </div>
      </div><!--Col-->
    </div><!--Row-->

    <template v-if="form.status">

      <template v-if="!isExpired() && !form.archived_at && !form.deleted_at">
        
        <template v-if="form.status == 'draft'">
          <div class="alert alert-danger">
            Draft status. The form is only accessible here.
          </div>
        </template>

        <template v-if="form.status == 'private'">
          <div class="alert alert-info">
            Private status. The form is only accessible to signed-in clients.
          </div>
        </template>

        <template v-if="form.status == 'public'">
          <div class="alert alert-success">
            Public status. The form is accessible to anyone.
          </div>
        </template>

      </template>

        <template v-if="isExpired() && form.status != 'draft'">
          <div class="alert alert-danger">
            <i class="fa fa-exclamation-triangle"></i>
            This form has expired. It is no longer accessible.
          </div>
        </template>

        <template v-if="form.expires_at && form.status != 'draft' && !isExpired()">
          <div class="alert alert-warning">
            <i class="fa fa-clock"></i>
            Expires {{ form.expires_at | dateTimezone | timeago }}
          </div>
        </template>

    </template>

    <div class="row">
      <div class="col-sm-12 col-md-4">
          <div class="card">
            <div class="card-body">

              <p class="card-title">Edit {{ $route.meta.title }}</p>

              <form @submit.prevent="postForm()">

              <div class="form-group">
                <label for="name" class="form-label">Name</label>
                <input id="name" type="text" class="form-control" v-model="form.name" required>
              </div>

              <div class="form-group">
                <label for="reference" class="form-label">Reference ID</label>
                <input id="reference" type="text" class="form-control" v-model="form.reference" required>
              </div>


              <div class="form-group">
                <label for="status">Status</label>
                <select id="status" 
                class="form-control" 
                v-model="form.status" 
                :disabled="processing" 
                @change="postForm()"
                required>
                  <template v-for="status in statuses">
                    <option :value="status">{{ status }}</option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="portal">Portal

                <template v-if="form.portal">
                  <a
                  :href="'/portals/'+form.portal"
                  target="_blank"
                  data-tooltip="Edit Portal">
                  <i class="fa fa-edit"></i> <span class="sr-only">Edit Portal</span>
                  </a>
                </template>
                
                </label>
                <select
                  id="portal"
                  v-model="form.portal"
                  class="form-control"
                  :disabled="processing"
                  @change="postForm()"
                  required
                >
                  <option value="">
                    Choose Portal
                  </option>
                  <template v-for="portal in portals">
                    <option
                      :value="portal.uuid"
                    >
                      {{ portal.name }}
                    </option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="description" class="form-label">Description</label>
                <textarea id="description" class="form-control" v-model="form.description" rows="3"></textarea>
              </div>

              <div class="form-group">
                <label for="language">Default Language</label>
                <select
                  id="language"
                  v-model="form.language"
                  class="form-control"
                  :disabled="processing"
                  required
                >
                  <option value="">
                    Choose Language
                  </option>
                  <option value="en">English</option>

                  <template v-for="language in languages">

                    <option
                      :value="language.key"
                      :hidden="language.key == 'en'"
                    >
                      {{ language.label }}
                    </option>

                  </template>

                </select>
              </div>

              <div class="form-group">
                <label for="redirect" class="form-label">External Redirect</label>
                <input id="redirect" type="text" class="form-control" v-model="form.redirect">
                <small class="text-muted">URL with an external form</small>
              </div>

              <div class="form-group">
                <label for="amount">Payment Amount</label>
                <input id="amount" type="number" class="form-control" v-model="form.amount" step="0.01" min="5" max="1000">
                <small class="text-muted">A 5% processing fee will be added to the amount charged to the client.</small>
              </div>

              <h5>Expiration Settings</h5>

              <p class="text-muted">Set an expiration date to auto-close the form.</p>

              <div class="form-group">
                <label for="expire_date" class="form-label">Expiration Date</label>
                <input id="expire_date" type="date" class="form-control" v-model="form.expire_date">
              </div>

              <div class="form-group" v-if="form.expire_date">
                <label for="expire_time" class="form-label">Expiration Time</label>
                <input id="expire_time" type="time" class="form-control" v-model="form.expire_time">
              </div>

              <template v-if="!form.redirect">

              <h5>Submission Settings</h5>

              <div class="form-group">
                <label for="submissions_notify" class="form-label">Notify on Submission</label>
                <input id="submissions_notify" type="text" class="form-control" v-model="form.notify">
                <small class="text-muted">Comma-separated list of emails</small>
              </div>

              <div class="form-group">
                  <label for="flow">Assign Flow</label>
                  <select id="flow" class="form-control" v-model="form.flow" :disabled="processing">
                    <option value=""></option>
                    <template v-for="flow in flows">
                      <option :value="flow.uuid">{{ flow.name }}</option>
                    </template>
                  </select>
              </div>
              
              <div class="form-group">
                <label for="submissions_auto_message" class="form-label">Trigger Auto Message
                  <template v-if="form.submissions['auto_message']">
                    <a
                        :href="'/auto_messages/'+form.submissions['auto_message']"
                        target="_blank"
                        data-tooltip="Edit Auto Message">
                      <i class="fa fa-edit"></i> <span class="sr-only">Edit Auto Message</span>
                    </a>
                  </template>
                </label>
                <select id="submissions_auto_message" class="form-control" v-model="form.submissions['auto_message']" :disabled="processing">
                  <option value=""></option>
                  <template v-for="auto_message in auto_messages">
                    <option :value="auto_message.uuid">{{ auto_message.name }}</option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="submissions_redirect" class="form-label">Redirect on Submission</label>
                <input id="submissions_redirect" type="text" class="form-control" v-model="form.submissions['redirect']">
              </div>

              <div class="form-group">
                <label for="submissions_label" class="form-label">Submit Button Label</label>
                <input id="submissions_label" type="text" class="form-control" v-model="form.submissions['submit_label']">
              </div>

              <div class="form-group">
                <label for="submissions_confirm_alert" class="form-label">Submit Confirm Alert</label>
                <input id="submissions_confirm_alert" type="text" class="form-control" v-model="form.submissions['confirm_alert']">
              </div>

              <div class="form-group">
                <label for="submissions_confirm_description" class="form-label">Submit Confirm Description</label>
                <input id="submissions_confirm_description" type="text" class="form-control" v-model="form.submissions['confirm_description']">
              </div>

              </template>

              <button type="submit" class="btn btn-success" :disabled="processing">Save</button>

              </form>

            </div><!--Body-->
          </div><!--Card-->

          <audit-logs :form="form" class="mt-3" />

      </div><!--Col-->

      <div class="col-sm-12 col-md-4">

            <button
              type="button"
              class="btn btn-success"
              @click="postSection()"
              :disabled="processing"
            >
              Add Section
            </button>


        <template v-if="!form.sections || form.sections.length === 0">
          
          <div class="alert alert-info my-3" :hidden="processing">
            No sections found. Add a section to get started.
          </div>
        
        </template>

        <template v-if="form && form.sections && form.sections.length">

          <h4 class="mt-3">{{ form.sections.length }} 
            <template v-if="form.sections.length == 1">
              Section
            </template>
            <template v-else>
              Sections
            </template>
          </h4>

          <div class="btn-group my-3">
          <template v-for="(section_button, section_toggle) in form.sections">

            <button
              type="button"
              class="btn btn-outline-primary"
              :class="{ 'active': current_section == section_toggle }"
              @click="toggleSection(section_toggle)"
              :disabled="processing"
            >
              {{ section_button.title }}
            </button>

          </template>
          </div>

          <div class="card mb-3">
          <div class="card-body">

            <p class="card-title">Add Field</p>

            <div class="form-group">
              <label for="field_title">Field Title</label>
              <input id="field_title" type="text" class="form-control" v-model="field_title">
            </div>

              <template v-for="field_type in field_types">
                  <button
                    type="button"
                    class="btn btn-outline-success mr-2 mb-2"
                    @click="postField(field_type)"
                    :disabled="processing"
                  >
                    {{ field_type | rmDashes }}
                  </button>
              </template>

          </div><!--Body-->
        </div><!--Card-->

          <template v-for="(section, section_index) in form.sections">

            <div class="card mb-3" :hidden="current_section != section_index">
              <div class="card-body">

                <div class="row mb-3">

                  <div class="col-sm-12 col-md-6">
                    <div class="btn-group">
                      <button
                        type="button"
                        class="btn btn-outline-secondary"
                        @click="moveSectionUp(section_index)"
                        :disabled="processing || section_index == 0"
                      >
                        <i class="fa fa-arrow-up"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-outline-secondary"
                        @click="moveSectionDown(section_index)"
                        :disabled="processing || section_index == form.sections.length-1"
                      >
                        <i class="fa fa-arrow-down"></i>
                      </button>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-6 text-right">
                    <button
                      type="button"
                      class="btn btn-outline-danger btn-sm"
                      @click="deleteSection(section_index)"
                      :disabled="processing"
                    >
                      <i class="fa fa-trash"></i> Delete Section
                    </button>
                  </div><!--Col-->

                </div><!--Row-->

                <label>Section Title</label>
                <h4 contenteditable @blur="editSectionTitle($event, section_index)">{{ section.title }}</h4>

                <label>Section Description</label>
                <h5 contenteditable @blur="editSectionDescription($event, section_index)">{{ section.description }}</h5>

                <label>{{ section.fields.length || 0 }} Section Fields</label>
                
              <template v-for="(form_field, field_index) in section.fields">


                    <div class="form-group">
                      <label>Field Title</label>
                      <input :id="'field_'+field_index" type="text" class="form-control" v-model="form.sections[section_index]['fields'][field_index]['title']" @keyup.enter="postForm()">
                    </div>

                  <div class="form-group">
                    <label>Field Type</label>
                    <select :id="'field_type_'+field_index" class="form-control" v-model="form.sections[section_index]['fields'][field_index]['type']" @change="postForm()">
                      <template v-for="field_type in field_types">
                        <option :value="field_type">{{ field_type | rmDashes }}</option>
                      </template>
                    </select>
                  </div>

                    <div class="form-group">
                      <label>Help Text</label>
                      <input type="text" class="form-control" v-model="form.sections[section_index]['fields'][field_index]['help_text']" @keyup.enter="postForm()">
                    </div>

                    <div class="form-group">
                      <label>Default Value</label>
                      <input type="text" class="form-control" v-model="form.sections[section_index]['fields'][field_index]['default']" @keyup.enter="postForm()">
                    </div>

                    <template v-if="form_field.type == 'dropdown'">

                      <div class="form-group">
                        <label>Dropdown Choices</label>
                        <input type="text" class="form-control" v-model="form.sections[section_index]['fields'][field_index]['options']" @keyup.enter="postForm()" required>
                        <small class="form-text text-muted">Comma-separated list of choices. Dropdowns allow a single choice.</small>
                      </div>

                      </template>

                      <template v-if="form_field.type == 'multi_dropdown'">

                      <div class="form-group">
                        <label>Multi-Dropdown Choices</label>
                        <input type="text" class="form-control" v-model="form.sections[section_index]['fields'][field_index]['options']" @keyup.enter="postForm()" required>
                        <small class="form-text text-muted">Comma-separated list of choices. Multi-Dropdowns allow multiple choices.</small>
                      </div>

                      </template>


                      <template v-if="form_field.type == 'checkbox'">

                      <div class="form-group">
                        <label>Checkbox Choices</label>
                        <input type="text" class="form-control" v-model="form.sections[section_index]['fields'][field_index]['options']" @keyup.enter="postForm()" required>
                        <small class="form-text text-muted">Comma-separated list of choices. Checkboxes allow multiple choices.</small>
                      </div>

                      </template>


                      <template v-if="form_field.type == 'radio'">

                      <div class="form-group">
                        <label>Radio Choices</label>
                        <input type="text" class="form-control" v-model="form.sections[section_index]['fields'][field_index]['options']" @keyup.enter="postForm()" required>
                        <small class="form-text text-muted">Comma-separated list of choices. Radios allow a single choice.</small>
                      </div>

                      </template>


                    <div class="form-group">
                      <label>Condition Field</label>
                      <select class="form-control" v-model="form.sections[section_index]['fields'][field_index]['condition_field']" @change="postForm()">
                        <option value="">None</option>
                        <template v-if="form.sections[section_index]['fields']">
                        <template v-for="form_field in form.sections[section_index]['fields']">
                          <template v-if="form_field.title">
                            <option :value="form_field.title">{{ form_field.title }}</option>                          
                          </template>
                        </template>
                        </template>
                      </select>
                    </div>

                    <div class="form-group" :hidden="!form.sections[section_index]['fields'][field_index]['condition_field']">
                      <label>Condition Value</label>
                      <input type="text" class="form-control" v-model="form.sections[section_index]['fields'][field_index]['condition_value']" @keyup.enter="postForm()">
                    </div>

                    <div class="form-group" :hidden="!form.sections[section_index]['fields'][field_index]['condition_field']">
                      <label>Condition Operator</label>
                      <select class="form-control" v-model="form.sections[section_index]['fields'][field_index]['condition_operator']" @change="postForm()">
                        <option value="=">Equals</option>
                        <option value="!=">Does Not Equal</option>
                        <option value=">">Greater Than</option>
                        <option value="<">Less Than</option>
                        <option value=">=">Greater Than or Equal To</option>
                        <option value="<=">Less Than or Equal To</option>
                      </select>
                    </div>

                    <div class="form-group" :hidden="!form.sections[section_index]['fields'][field_index]['condition_field']">
                      <label>Condition Action</label>
                      <select class="form-control" v-model="form.sections[section_index]['fields'][field_index]['condition_action']" @change="postForm()">
                        <option value="show">Show</option>
                        <option value="hide">Hide</option>
                      </select>
                    </div>

                      <div class="form-group custom-control custom-switch">
                        <input
                          :id="'field_required_'+field_index+section_index"
                          v-model="form.sections[section_index]['fields'][field_index]['required']"
                          type="checkbox"
                          class="custom-control-input"
                          @change="postForm()"
                          :disabled="processing"
                        >
                        <label
                          class="custom-control-label"
                          :for="'field_required_'+field_index+section_index"
                        > Required Field</label>
                      </div>

                      <div class="form-group custom-control custom-switch">
                        <input
                          :id="'field_hidden'+field_index+section_index"
                          v-model="form.sections[section_index]['fields'][field_index]['hidden']"
                          type="checkbox"
                          class="custom-control-input"
                          :disabled="processing"
                        >
                        <label
                          class="custom-control-label"
                          :for="'field_hidden'+field_index+section_index"
                        > Hidden Field</label>
                      </div>

                      <div class="form-group">
                        <label for="section">Section</label>
                        <select
                          id="section"
                          class="form-control"
                          v-model="form.sections[section_index]['fields'][field_index]['section']"
                          :disabled="processing"
                          required
                          @change="updateSection($event, section_index, field_index)"
                        >
                          <option value="">
                            Choose Section
                          </option>
                          <template v-for="(section, section_option) in form.sections">
                            <option :value="section_option" :hidden="section_index == section_option">{{ section.title }}</option>
                          </template>
                        </select>
                      </div>

                  <div class="btn-group my-3">
                    <button
                      type="button"
                      class="btn btn-outline-secondary"
                      @click="moveFieldUp(section_index, field_index)"
                      :disabled="processing"
                      :hidden="field_index == 0"
                    >
                      <i class="fa fa-arrow-up"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-outline-secondary"
                      @click="moveFieldDown(section_index, field_index)"
                      :disabled="processing"
                      :hidden="field_index == form.fields.length-1"
                    >
                      <i class="fa fa-arrow-down"></i>
                    </button>  

                    <button type="button" class="btn btn-outline-danger" @click="deleteFormField(section_index, field_index)" :disabled="processing"><i class="fa fa-trash"></i> Delete Field</button>
                  </div>

                <hr>

                </template>


              </div><!--Body-->
            </div><!--Card-->

          </template>

        </template>


          <template v-if="form.submissions['submit_label']">
            <br>
            <div class="btn-group my-3">
            <button type="button" class="btn btn-success" @click="postForm()" :disabled="processing">{{ form.submissions['submit_label'] }}</button>
            </div>

          </template>

      </div><!--Col-->

      <div class="col-sm-12 col-md-4">

        <div class="card">
          <div class="card-body">

            <p class="card-title">Auto Actions</p>

            <form @submit.prevent="addAction()">

            <div class="form-group">
              <label for="auto_action_type">Type</label>
              <select id="auto_action_type" class="form-control" v-model="auto_action.type">
                <option value="update_client">Update Client</option>
              </select>
            </div>

            <div class="form-group">
              <label for="auto_action_field">Field</label>
              <select id="auto_action_field" class="form-control" v-model="auto_action.field">
                <option value="">Choose Field</option>
                <template v-for="form_field in formFields">
                  <template v-if="form_field.title">
                    <option :value="form_field.title">{{ form_field.title }}</option>                    
                  </template>
                </template>
              </select>
            </div>

            <div class="form-group">
              <label for="auto_action_operator">Operator</label>
              <select id="auto_action_operator" class="form-control" v-model="auto_action.operator">
                <option value="">Choose Operator</option>
                <option value="=">Equals</option>
                <option value="!=">Does Not Equal</option>
                <option value=">">Greater Than</option>
                <option value="<">Less Than</option>
                <option value=">=">Greater Than or Equal To</option>
                <option value="<=">Less Than or Equal To</option>
              </select>
            </div>

            <div class="form-group">
              <label for="auto_action_value">Value</label>
              <input id="auto_action_value" type="text" class="form-control" v-model="auto_action.value">
            </div>

            <div class="form-group">
              <label for="auto_action_rule">Rule</label>
              <select id="auto_action_rule" class="form-control" v-model="auto_action.rule">
                <option value="and">And</option>
                <option value="or">Or</option>
              </select>
            </div>

            <div class="form-group">
              <label for="auto_action_field">Action Field</label>
              <select id="auto_action_field" class="form-control" v-model="auto_action.action_field">
                <option value="">Choose Field</option>
                <option value="status">Status</option>
                <option value="type">Type</option>
                <option value="group">Group</option>
              </select>
            </div>

            <div class="form-group">
              <label for="auto_action_field_value">Action Field Value</label>
              <input id="auto_action_field_value" type="text" class="form-control" v-model="auto_action.action_field_value">
            </div>

            <button type="submit" class="btn btn-success" :disabled="processing">Add Action</button>

            </form>

          </div><!--Body-->
        </div><!--Card-->

        <template v-if="form.actions && form.actions.length">

          <button type="button" clas="btn btn-danger" @click="form.actions = null" :disabled="processing">Reset Actions</button>

          <h4 class="mt-3">{{ form.actions.length }} 
            <template v-if="form.actions.length == 1">
              Action
            </template>
            <template v-else>
              Actions
            </template>
          </h4>

          <div class="card mb-3">
          <div class="card-body">

            <template v-for="(action, action_index) in form.actions">

              <div class="form-group">
                <label :for="'action_type_'+action_index">Type</label>
                <input :id="'action_type_'+action_index" type="text" class="form-control" v-model="action.type" disabled>
              </div>

              <div class="form-group">
                <label :for="'action_field_'+action_index">Field</label>
                <input :id="'action_field_'+action_index" type="text" class="form-control" v-model="action.field" disabled>
              </div>

              <div class="form-group">
                <label :for="'action_operator_'+action_index">Operator</label>
                <input :id="'action_operator_'+action_index" type="text" class="form-control" v-model="action.operator" disabled>
              </div>

              <div class="form-group">
                <label :for="'action_value_'+action_index">Value</label>
                <input :id="'action_value_'+action_index" type="text" class="form-control" v-model="action.value" disabled>
              </div>

              <div class="form-group">
                <label :for="'action_rule_'+action_index">Rule</label>
                <input :id="'action_rule_'+action_index" type="text" class="form-control" v-model="action.rule" disabled>
              </div>

              <div class="form-group">
                <label :for="'action_field_'+action_index">Action Field</label>
                <input :id="'action_field_'+action_index" type="text" class="form-control" v-model="action.action_field" disabled>
              </div>

              <div class="form-group">
                <label :for="'action_field_value_'+action_index">Action Field Value</label>
                <input :id="'action_field_value_'+action_index" type="text" class="form-control" v-model="action.action_field_value" disabled>
              </div>

            </template>

          </div><!--Body-->
        </div><!--Card-->


        </template>

      </div><!--Col-->

     
    </div><!--Row-->
  </div>
</template>
<script>
import queries from '../mixins/queries';
export default {

mixins: [queries],

data () {
    return {
        processing: false,
        loading: false,
        form: {},
        portal: {},
        portals: [],
        statuses: ['draft', 'private', 'public'],
        field_title: '',
        field_types: ['short_text', 'long_text', 'information', 'date', 'time', 'phone', 'email', 'number', 'dropdown', 'multi_dropdown', 'checkbox', 'radio', 'file'],
        fieldsForm: {},
        auto_messages: [],
        flows: [],
        qr_code: null,
        total_submissions: 0,
        current_section: 0,
        auto_action: {}
    }
},

computed: {

  portalLink: function() {

    if(!this.portal || !this.portal.link) {
      return;
    }

    if(!this.form || !this.form.url) {
      return;
    }

    if(this.form.status == 'draft') {
      return;
    }

    var language = this.form.language || 'en';

    return 'https://'+this.portal.link+'.'+this.portal.domain+'/w/'+this.form.url+'?lang='+language;
  },

  formFields: function() {
    var results = [];

    if(!this.form.sections) {
      return results;
    }

    this.form.sections.forEach(function(section) {
      if(section.fields) {
        section.fields.forEach(function(field) {
          results.push(field);
        })
      }
    });

    return results;
  }
},

created () {
    this.resetForm();
    this.resetActionForm();
    this.getRecord();
    this.getPortals();
    this.getAutoMessages();
    this.getFlows();
},

methods: {

    resetForm() {
      this.form = {
        name: null,
        description: null,
        reference: null,
        client: null,
        status: null,
        portal: null,
        flow: null,
        expires_at: null,
        amount: null,
        language: 'en',
        submissions: {
          'notify': null,
          'redirect': null,
          'submit_label': null,
          'confirm_alert': null,
          'confirm_description': null,
          'auto_message': null,
        },
        actions: [],
        fields: [],
        sections: []
      }
    },

    resetActionForm() {
      this.auto_action = {
        type: 'update_client',
        field: null,
        operator: null,
        value: null,
        rule: null,
        action_field: null,
        action_field_value: null
      }
    },

    postForm() {
      this.processing = true;

      if(this.form.expire_date) {

        if(!this.form.expire_time) {
          this.form.expire_time = '00:00';
        }

        this.form.expires_at = this.$moment(this.form.expire_date + ' ' + this.form.expire_time).tz(this.timezone).utc().format('YYYY-MM-DD HH:mm:ss');
      }

      if(!this.form.expire_date) {
        this.form.expires_at = null;
      }

      this.$http.patch('/'+this.$route.meta.base_url+'/'+this.form.uuid, this.form)
      .then(response => {
        if(response && response.data) {

          this.processing = false;

          if(response.data.updated_at) {
            this.form.updated_at = response.data.updated_at;
            new this.$noty({text: this.$route.meta.title + ' updated'}).show();
            this.getPortal();
          }

        }
      })
    },

    isExpired() {
      if(!this.form.expires_at) {
        return false;
      }

      return this.$moment().isAfter(this.form.expires_at);
    },

    getQRCode() {
      this.$http.post('/qrs?content='+this.portalLink)
      .then(response => {
          if(response && response.data) {
              this.qr_code = response.data;
          }
      })
    },


    setLangDirection(form) {

      if(!form.language) {
        return 'ltr';
      }

      if(this.$root.rtl_langs.includes(form.language)) {
        return 'rtl';
      }

      return 'ltr';
    },

    addAction() {

      if(!this.form.actions) {
        this.form.actions = [];
      }

      this.form.actions.push(this.auto_action);

      this.resetActionForm();

    },

    deleteFormField(section_index, field_index) {
      this.form.sections[section_index].fields.splice(field_index, 1);
      new this.$noty({text: 'Save to apply changes.'}).show();
    },

    moveFieldUp(section_index, field_index) {
      if(field_index == 0) {
        return;
      }

      var field = this.form.sections[section_index].fields[field_index];
      this.form.sections[section_index].fields.splice(field_index, 1);
      this.form.sections[section_index].fields.splice(field_index-1, 0, field);

      this.postForm();
    },

    moveFieldDown(section_index, field_index) {
      if(field_index == this.form.fields.length-1) {
        return;
      }

      var field = this.form.sections[section_index].fields[field_index];
      this.form.sections[section_index].fields.splice(field_index, 1);
      this.form.sections[section_index].fields.splice(field_index+1, 0, field);

      this.postForm();
    },


    moveSectionUp(section_index) {
      if(section_index == 0) {
        return;
      }

      var section = this.form.sections[section_index];
      this.form.sections.splice(section_index, 1);
      this.form.sections.splice(section_index-1, 0, section);
      this.current_section = section_index-1;
      new this.$noty({text: 'Save to apply changes.'}).show();
    },

    moveSectionDown(section_index) {
      if(section_index == this.form.sections.length-1) {
        return;
      }

      var section = this.form.sections[section_index];
      this.form.sections.splice(section_index, 1);
      this.form.sections.splice(section_index+1, 0, section);
      this.current_section = section_index+1;
      new this.$noty({text: 'Save to apply changes.'}).show();
    },

    toggleSection(section_index) {
      this.current_section = section_index;
    },

    editSectionTitle(event, section_index) {

      if(!event.target.textContent) {
        return;
      }

      if(this.form.sections[section_index].title == event.target.textContent) {
        return;
      }

      this.form.sections[section_index].title = event.target.textContent;
      new this.$noty({text: 'Save to apply changes.'}).show();
    },

    editSectionDescription(event, section_index) {
      this.form.sections[section_index].description = event.target.textContent;
      this.postForm();
    },

    deleteSection(section_index) {
      this.form.sections.splice(section_index, 1);
      this.current_section = section_index-1;
      new this.$noty({text: 'Save to apply changes.'}).show();
    },

    updateSection(e, section_index, field_index) {

      if(!this.form.sections[e.target.value].fields) {
        this.form.sections[e.target.value].fields = [];
      }

      this.form.sections[e.target.value].fields.push(this.form.sections[section_index].fields[field_index]);

      this.form.sections[section_index].fields.splice(field_index, 1);

      new this.$noty({text: 'Section updated'}).show();
    },

    postSection() {

      var count = this.form.sections.length;

      count++;

      this.form.sections.push({title: 'New Section '+count, description: '', fields: []});

      this.current_section = count-1;

      this.postForm();

    },

    postField(field) {
      
      if(!this.form.sections || !this.form.sections.length) {
        this.form.sections = [
          {title: 'General', description: '', fields: []}
        ];
      }

      if(!this.form.sections[this.current_section]['fields']) {
        this.form.sections[this.current_section]['fields'] = [];
      }

      var title = this.field_title || field;

      title = this.$options.filters.rmDashes(title);

      if(field == title) {
        title = title.charAt(0).toUpperCase() + title.slice(1);
      }

      this.form.sections[this.current_section]['fields'].push({type: field, title: title, required: true});

      this.field_title = '';

      this.postForm();
    },

    getRecord() {
        this.processing = true
        this.$http.get('/'+this.$route.meta.base_url+'/'+this.$route.params.id)
        .then(response => {
            if(response && response.data) {
                this.form = response.data;

                if(!this.form.submissions) {
                  this.form.submissions = {
                    'notify': null,
                    'redirect': null,
                    'submit_label': null
                  }
                }

                if(!this.form.sections) {
                  this.form.sections = [
                    {title: 'General', description: '', fields: []}
                  ];
                }

                if(!this.form.fields) {
                  this.form.fields = [];
                }

                this.setTitle();

                this.processing = false;
                this.getPortal();

                this.getTotalSubmissions();
            }
        })
    },

    getTotalSubmissions() {
      this.$http.get('/submissions/total?form='+this.form.uuid)
      .then(response => {
        if(response && response.status) {
          this.total_submissions = response.data || 0;
        }
      })
    },

    setTitle() {
      document.title = this.form.name + ' | eCourtDate.com';
    },
        
      restoreRecord () {
          this.processing = true;
          this.$http.put('/'+this.$route.meta.base_url + '/' + this.form.uuid + '/restore')
          .then(response => {
              if (response && response.data) {

                  this.processing = false;

                  if(response.status === 200) {
                      new this.$noty({text: this.$route.meta.title + ' restored'}).show();
                      this.form.deleted_at = null;
                      this.form.archived_at = null;
                  }
                  
              }
          })
      },

      archiveRecord () {
          this.processing = true
          this.$http.put('/'+this.$route.meta.base_url + '/' + this.form.uuid + '/archive')
          .then(response => {
              if (response && response.data) {

                  this.processing = false;

                  if(response.status === 200) {
                      new this.$noty({text: this.$route.meta.title + ' archived'}).show()
                      this.form.archived_at = new Date().toISOString();
                  }

              }
              
          })
      },

      trashRecord () {
          this.processing = true;
          this.$http.delete('/'+this.$route.meta.base_url + '/' + this.form.uuid)
          .then((response) => {
            if(response && response.status) {
              this.processing = false;

              if(response.status === 204) {
                new this.$noty({text: this.$route.meta.title + ' trashed'}).show();
                this.form.deleted_at = new Date().toISOString();
              }
            }
          })
      },

    getPortals () {
        this.$http.get('/portals?slim=true&limit=1000&sort=name&sortDirection=asc&fields=uuid,name,link&alias=ecase.io')
        .then(response => {
            if (response && response.data) {
                this.portals = response.data;
            }
        })
    },

    getPortal() {

      if(!this.form.portal) {
        return;
      }

      if(this.form.portal == this.portal.uuid) {
        return;
      }

      this.$http.get('/portals/'+this.form.portal+'?slim=true&fields=domain,link')
      .then(response => {
        if(response && response.data) {
          this.portal = response.data;
          this.getQRCode();
        }
      })
    },

  getAutoMessages() {
    this.auto_messages = [];
    this.$http.get('/auto_messages?limit=100&slim=true&fields=uuid,name&sort=name&sortDirection=asc')
        .then(response => {
          if(response && response.data) {
            this.auto_messages = response.data;
          }
        })
  },

  getFlows() {
    this.flows = [];
    this.$http.get('/flows?limit=100&slim=true&fields=uuid,name&sort=name&sortDirection=asc&type=form')
        .then(response => {
          if(response && response.data) {
            this.flows = response.data;
          }
        })
  },
}
}
</script>