<template>
    <div>
    
    <page-tabs page="setup" />
    
          <div class="row">
            <div class="col-6 mx-auto">
              <div class="card">
                <div class="card-body">
    
                  <h1>Billing Profile</h1>

                  <p class="lead">To update your billing profile, please contact billing@ecourtdate.com from an authorized billing contact.</p>

                  <template v-if="customer && customer.uuid">

                  <template v-if="customer.name">
                    <p>Customer Name: {{ customer.name }}</p>
                  </template>

                  <template v-if="customer.address_1">
                    <p>Address: {{ customer.address_1 }}</p>
                  </template>

                  <template v-if="customer.address_2">
                    <p>Address 2: {{ customer.address_2 }}</p>
                  </template>

                  <template v-if="customer.city">
                    <p>City: {{ customer.city }}</p>
                  </template>

                  <template v-if="customer.state">
                    <p>State: {{ customer.state }}</p>
                  </template>

                  <template v-if="customer.zip">
                    <p>Zip: {{ customer.zip }}</p>
                  </template>

                  <template v-if="customer.website">
                    <p>Website: {{ customer.website }}</p>
                  </template>

                  </template>

                  <template v-if="customer.contacts && customer.contacts.length > 0">
  
                    <h2 class="mt-3">Authorized Billing Contacts</h2>

                    <ul class="list-group">
                    <template v-for="contact in customer.contacts">
                      <li class="list-group-item">
                          {{ contact.name }}
                          <span class="badge badge-pill bage-secondary">{{ contact.email }}</span>
                      </li>
                    </template>
                    </ul>

                    </template>

                    <p><a href="https://billing.ecourtdate.com" target="_blank" class="btn btn-primary mt-3">Billing Portal</a></p>

    
                </div><!--Body-->
            </div><!--Card-->
    
            </div><!--Col-->
        </div><!--Row-->

    </div>
    </template>
    <script>    
    export default {
    
        data() {
            return {
              processing: false,
              customer: {},
              states: []
            }
        },
        
        created () {
            this.getStates();
            this.getCustomer();
        },
    
        methods: {

        getStates() {
            this.$axios.get(this.$root.assets_url+'js/states.json')
            .then(response => {
                if(response && response.data) {
                    this.states = response.data;
                }
            })
        },
    
          getCustomer() {
            this.customer = {};
            this.processing = true;
            this.$http.get('/agencies/customer')
                .then(response => {
                  if(response && response.data) {
                    this.customer = response.data;
                    this.processing = false;
                  }
                })
          },
    
    
        }
    
    }
    </script>