<template>
  <div>
    <template v-if="reason == 1">
      <span class="badge badge-success">REACHED</span>
    </template>

    <template v-if="reason != 1 && reasons[reason]">
      <span class="badge badge-danger badge-pill text-uppercase">{{ reasons[reason] }}</span>
    </template>
  </div>
</template>
<script>
export default {

props: {
    reason: {
        type: Number,
        default: null
    }
},

data() {
    return {
        reasons: []
    }
},

created() {
    this.getReasons();
},

methods: {

    getReasons() {
        this.$axios.get(this.$root.assets_url+'js/unreachables.json')
        .then(response => {
            if(response && response.data) {
                this.reasons = response.data;
            }
        })
    },

}

}
</script>