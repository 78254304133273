<template>
  <div>

    <archived-trashed-status
        :form="form"
        :processing="processing"
        :title="$route.meta.title"
    />

    <div class="row">
      <div class="col-sm-12 col-md-8">
        <h1>{{ form.name }}</h1>
        <h3>{{ form.phone_number }}</h3>
        <p>
          <router-link :to="{name: 'settings.index'}">Settings</router-link> /
          <router-link :to="{name: $route.meta.base_url+'.index'}">{{ $route.meta.title }}es</router-link> / Edit {{ $route.meta.title }}
        </p>

      </div><!--Col-->

      <div class="col-sm-12 col-md-4 text-right">
        <div class="btn-group">

          <div class="btn-group">
            <button
                type="button"
                class="btn btn-outline-primary dropdown-toggle radius-right-0"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            >
              Tools
            </button>
            <div class="dropdown-menu">
              <a
                  v-if="!form.archived_at"
                  class="dropdown-item"
                  @click="archiveRecord()"
              >Archive</a>
              <a
                  v-if="form.archived_at || form.deleted_at"
                  class="dropdown-item"
                  @click="restoreRecord()"
              >Restore</a>
              <a
                  v-if="!form.deleted_at"
                  class="dropdown-item"
                  @click="trashRecord()"
              >Trash</a>
            </div>
          </div><!--BtnGroup-->

          <button
              v-if="!form.archived_at && !form.deleted_at"
              type="button"
              class="btn btn-success"
              :disabled="processing"
              @click="postForm()"
          >
            Save
          </button>

          <router-link
              :to="{ name: $route.meta.base_url+'.index' }"
              class="btn btn-danger"
          >
            Close
          </router-link>

        </div>
      </div><!--Col-->
    </div><!--Row-->

    <div class="row">
      <div class="col-md-6 col-xs-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <p class="card-title">
                  Edit {{ $route.meta.title }}
                </p>
              </div><!--Col-->

              <div class="col-6 text-right">
              </div><!--Col-->
            </div><!--Row-->

            <form @submit.prevent="postForm">

              <div class="form-group">
                <label for="name">Name</label>
                <input
                    v-if="form"
                    id="name"
                    v-model="form.name"
                    type="text"
                    class="form-control"
                    required
                >
              </div>
              <div class="form-group">
                <label for="type">Type</label>
                <select
                    id="type"
                    v-model="form.type"
                    class="form-control text-uppercase"
                    :disabled="processing"
                >
                  <option value=""></option>
                  <template v-for="status_type in $root.status_types">
                    <option
                        :value="status_type"
                    >{{ status_type }}</option>
                  </template>
                </select>
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                    id="disableMessages"
                    v-model="form.disable_messages"
                    type="checkbox"
                    class="custom-control-input"
                >
                <label
                    class="custom-control-label"
                    for="disableMessages"
                >Disable Messages</label>
              </div>
              <div class="form-group custom-control custom-switch">
                <input
                    id="defaultStatus"
                    v-model="form.default"
                    type="checkbox"
                    class="custom-control-input"
                >
                <label
                    class="custom-control-label"
                    for="defaultStatus"
                >Default Status</label>
              </div>
              <div class="form-group custom-control custom-switch">
                <input
                    id="archive"
                    v-model="form.archive"
                    type="checkbox"
                    class="custom-control-input"
                >
                <label
                    class="custom-control-label"
                    for="archive"
                >Auto-Archive Record</label>
              </div>
              <template v-if="form.type == 'event'">
                <div class="form-group custom-control custom-switch">
                  <input
                      id="fta"
                      v-model="form.fta"
                      type="checkbox"
                      class="custom-control-input"
                  >
                  <label
                      class="custom-control-label"
                      for="fta"
                  >Failed to Appear?</label>
                </div>
              </template>

              <button type="submit" class="btn btn-success" :disabled="processing">Save</button>

            </form>
          </div><!--Body-->
        </div><!--Card-->

        <audit-logs
            class="mt-3"
            :form="form"
        />
      </div><!--Col-->

      <div class="col-md-3">


      </div><!--Col-->

      <div class="col-md-3">

      </div><!--Col-->

    </div><!--Row-->
  </div>
</template>
<script>
import queries from '../mixins/queries';

export default {

  mixins: [ queries ],

  data () {
    return {
      processing: false,
      total: 0,
      queries: {
        limit: 100,
        slim: true,
        sort: 'name',
        sortDirection: 'asc'
      },
      records: [],
      form: {},
    }
  },

  computed: {

  },

  created() {
    this.resetForm();
    this.setURLParams();
    this.getRecord();
  },

  methods: {

    setURLParams() {

      var searchParams = new URLSearchParams(window.location.search);

      var self = this;

      searchParams.forEach(function(value, key) {
        self.queries[key] = value;
      });
    },

    postForm() {
      this.processing = true;

      this.$http.patch('/'+this.$route.meta.base_url+'/'+this.form.uuid, this.form)
          .then(response => {
            if(response && response.data) {

              this.processing = false;

              if(response.data.updated_at) {
                this.form.updated_at = response.data.updated_at;
                new this.$noty({text: this.$route.meta.title + ' updated'}).show();
              }

            }
          })
    },

    resetForm() {
      this.form = {
        name: null,
        type: null,
        disable_messages: false,
        default: false,
        archive: false,
        fta: false
      }
    },

    getRecord() {
      this.processing = true;
      this.$http.get('/'+this.$route.meta.base_url+'/'+this.$route.params.id)
          .then(response => {
            if(response && response.data) {

              if(response.data.uuid) {
                this.form = response.data;
              }

              this.processing = false;
            }
          })
    },

    restoreRecord () {
      this.processing = true;
      this.$http.put('/'+this.$route.meta.base_url + '/' + this.form.uuid + '/restore')
          .then(response => {
            if (response && response.data) {

              this.processing = false;

              if(response.status === 200) {
                new this.$noty({text: this.$route.meta.title + ' restored'}).show();
                this.form.deleted_at = null;
                this.form.archived_at = null;
              }

            }
          })
    },

    archiveRecord () {
      this.processing = true
      this.$http.put('/'+this.$route.meta.base_url + '/' + this.form.uuid + '/archive')
          .then(response => {
            if (response && response.data) {

              this.processing = false;

              if(response.status === 200) {
                new this.$noty({text: this.$route.meta.title + ' archived'}).show()
                this.form.archived_at = new Date().toISOString();
              }

            }

          })
    },

    trashRecord () {
      this.processing = true;
      this.$http.delete('/'+this.$route.meta.base_url + '/' + this.form.uuid)
          .then((response) => {
            if(response && response.status) {
              this.processing = false;

              if(response.status === 204) {
                new this.$noty({text: this.$route.meta.title + ' trashed'}).show();
                this.form.deleted_at = new Date().toISOString();
              }
            }
          })
    },


  },
}
</script>