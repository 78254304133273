<template>
  <div>

    <archived-trashed-status
      :form="form"
      :processing="processing"
      :title="$route.meta.title"
    />

    <div class="row mb-3">
      <div class="col-sm-12 col-md-3">
        <template v-if="form.name">
          <h2 id="title" class="m-0">
            {{ form.name }}
          </h2>
          <p>
          <router-link :to="{name: 'settings.index'}">Settings</router-link> /
          <router-link :to="{name: $route.meta.base_url+'.index'}">{{ $route.meta.title }}s</router-link> / Edit {{ $route.meta.title }}
        </p>

        </template>

        <template v-if="form.map_url && !form.virtual">
          <a
            :href="form.map_url"
            target="_blank"
          >{{ form.full_address }}</a>
          <merge-tag :merge_tag="'[LocationFullAddress]'" />
        </template>
      </div><!--Col-->

      <div class="col-sm-12 col-md-3">
          <template v-if="portal && portal.uuid">
            <a :href="'https://'+portal.link+'.'+portal.domain+'/l/'+form.uuid" class="btn btn-outline-primary" target="_blank"><i class="fa fa-link"></i> Open Portal Link</a>
          </template>
      </div><!--Col-->

      <div class="col-sm-12 col-md-3">
        <template v-if="form.full_address && !form.virtual">
          <map-links :address="form.full_address" />
        </template>
      </div><!--Col-->

      <div class="col-sm-12 col-md-3 text-right">

        <template v-if="form.uuid">
          <button type="button" class="btn btn-secondary" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
        </template>

          <div class="btn-group">

            <button
              type="button"
              class="btn btn-secondary dropdown-toggle radius-right-0"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Tools
            </button>
            <div class="dropdown-menu">
              <a
                  v-if="!form.archived_at && !form.deleted_at"
                  class="dropdown-item"
                  @click="archiveRecord()"
              >Archive</a>
              <a
                  v-if="form.archived_at || form.deleted_at"
                  class="dropdown-item"
                  @click="restoreRecord()"
              >Restore</a>
              <a
                  v-if="!form.deleted_at && !form.archived_at"
                  class="dropdown-item"
                  @click="trashRecord()"
              >Trash</a>
            </div>
            <button
              type="submit"
              class="btn btn-success"
              :disabled="processing"
              @click="postForm()"
            >
             Save
            </button>
          </div>
        <router-link
          :to="{ name: $route.meta.base_url+'.index' }"
          class="btn btn-danger"
        >
          Close
        </router-link>
      </div><!--Col-->
    </div><!--Row-->
    <div class="row">
      <div class="col-sm-12 col-md-3">
        <div class="card">
          <div class="card-body">
            <form @submit.prevent="postForm()">

              <div class="form-group">
                <label for="name">Name <merge-tag :merge_tag="'[LocationName]'" /></label>
                <input
                  id="name"
                  v-model="form.name"
                  type="text"
                  class="form-control"
                  required
                >
                <p class="text-muted small">The public-facing location name</p>
              </div>

              <div class="form-group">
                <label for="location_reference">Location Reference</label>
                <input
                  id="location_reference"
                  v-model="form.location_reference"
                  type="text"
                  class="form-control"
                  :disabled="!superAdmin"
                >
                <p class="text-muted small">Internal reference for this location</p>
              </div>

              <div class="form-group">
                <label for="description">Description <merge-tag :merge_tag="'[LocationDescription]'" /></label>
                <textarea
                  id="description"
                  v-model="form.description"
                  class="form-control"
                  rows="3"
                  maxlength="255"
                />
                <span
                  class="text-muted small"
                  :style="descriptionLength === 255 ? 'color: #dc3545!important' : ''"
                >{{ descriptionLength }} of 255</span>
              </div>

                  <div class="form-group">
                    <label for="address">Address <merge-tag :merge_tag="'[LocationAddress]'" /></label>
                    <input
                      id="address"
                      v-model="form.address"
                      type="text"
                      class="form-control"
                      autocomplete="street-address"
                    >
                  </div>

              <template v-if="!form.virtual">

                  <div class="form-group">
                    <label for="address_2">Address 2 <merge-tag :merge_tag="'[LocationAddress2]'" /></label>
                    <input
                      id="address_2"
                      v-model="form.address_2"
                      type="text"
                      class="form-control"
                      >
                  </div>


                  <div class="form-group">
                    <label for="city">City <merge-tag :merge_tag="'[LocationCity]'" /></label>
                    <input
                      id="city"
                      v-model="form.city"
                      type="text"
                      class="form-control"
                    >
                  </div>


                  <div class="form-group">
                    <label for="county">County <merge-tag :merge_tag="'[LocationCounty]'" /></label>
                    <input
                      id="county"
                      v-model="form.county"
                      type="text"
                      class="form-control"
                    >
                  </div>


                  <div class="form-group">
                    <label for="state">State <merge-tag :merge_tag="'[LocationState]'" /></label>
                    <select
                      id="state"
                      v-model="form.state"
                      class="form-control"
                      :disabled="processing"
                    >
                      <option value="" />
                      <option
                        v-for="state in states"
                        :value="state.key"
                      >
                        {{ state.label }}
                      </option>
                    </select>
                  </div>
   
                  <div class="form-group">
                    <label for="zip">Zip <merge-tag :merge_tag="'[LocationZip]'" /></label>
                    <input
                      id="zip"
                      v-model="form.zip"
                      type="text"
                      class="form-control"
                    >
                  </div>

                <div class="form-group">
                  <label for="locales">Agency</label>
                  <input id="locales" type="text" class="form-control" v-model="form.locales">
                  <p class="text-muted small">The agency or jurisdiction this location belongs to</p>
                </div>

                <div class="form-group">
                  <label for="latitude">Latitude <merge-tag :merge_tag="'[LocationLat]'" /></label>
                  <input
                      id="latitude"
                      v-model="form.lat"
                      type="text"
                      class="form-control"
                  >
                </div>

                <template v-if="form.lat">
                  <button type="button" class="btn btn-outline-secondary btn-sm mb-3" @click="copyToClipboard(form.lat)" :disabled="processing"><i class="fa fa-copy"></i> Copy</button>
                </template>

                <div class="form-group">
                  <label for="longitude">Longitude <merge-tag :merge_tag="'[LocationLng]'" /></label>
                  <input
                      id="longitude"
                      v-model="form.lng"
                      type="text"
                      class="form-control"
                  >
                </div>

                <template v-if="form.lng">
                  <button type="button" class="btn btn-outline-secondary btn-sm mb-3" @click="copyToClipboard(form.lng)" :disabled="processing"><i class="fa fa-copy"></i> Copy</button>
                </template>

              </template>

              <div class="form-group">
                <label for="map_url"><template v-if="form.virtual">Virtual</template> <template v-else>Location</template> Link <merge-tag :merge_tag="'[LocationLink]'" /></label>
                <input
                    id="map_url"
                    v-model="form.map_url"
                    type="text"
                    class="form-control"
                >
              </div>

              <template v-if="form.virtual">

                <div class="form-group">
                <label for="passcode">Virtual Passcode <merge-tag :merge_tag="'[LocationPasscode]'" /></label>
                <input
                    id="passcode"
                    v-model="form.passcode"
                    type="text"
                    class="form-control"
                >
                </div>
              </template>

                <div class="form-group">
                  <label for="phone">Phone <merge-tag :merge_tag="'[LocationPhone]'" /></label>
                  <input
                    id="phone"
                    v-model="form.phone"
                    type="tel"
                    class="form-control"
                  >
                </div>

                <div class="form-group">
                  <label for="email">Email <merge-tag :merge_tag="'[LocationEmail]'" /></label>
                  <input
                    id="email"
                    v-model="form.email"
                    type="email"
                    class="form-control"
                  >
                </div>

                <div class="form-group">
                  <label for="website">Website <merge-tag :merge_tag="'[LocationWebsite]'" /></label>
                  <input
                    id="website"
                    v-model="form.website"
                    type="text"
                    class="form-control"
                  >
                </div>

              <div class="form-group">
                <label for="instructions">Instructions <merge-tag :merge_tag="'[LocationInstructions]'" /></label>
                <textarea id="instructions" class="form-control" v-model="form.instructions" rows="3"></textarea>
                <small class="text-muted">Add check-in, parking, how to prepare, or other helpful instructions.</small>
              </div>

              <div class="form-group">
                <label for="judge">Default Judge</label>
                <select id="judge" class="form-control" v-model="form.judge" :disabled="processing">
                  <option value=""></option>
                  <template v-for="(judge, judge_index) in judges">
                    <option :value="judge.name">{{ judge.name }}</option>
                  </template>
                </select>
                <small class="text-muted">Can be overridden by Event.</small>
              </div>

              <div class="form-group">
                <label for="business_hours">Business Hours</label>
                <input id="business_hours" type="text" class="form-control" v-model="form.business_hours">
              </div>

              <div class="form-group">
                <label for="business_days">Business Days</label>
                <input id="business_days" type="text" class="form-control" v-model="form.business_days">
              </div>

              <div class="form-group">
                <label for="timezone">Time Zone</label>
                <select id="timezone" class="form-control" v-model="form.timezone" :disabled="processing">
                  <option value=""></option>
                  <template v-for="(timezone, timezone_index) in timezones">
                    <option :value="timezone.key">{{ timezone.label }}</option>
                  </template>
                </select>
                <small class="form-text">Leave blank to use the <a href="/settings?tab=general">Time Zone configured in General Settings</a>.</small>
              </div>


                  <div class="form-group custom-control custom-switch">
                    <input
                        id="virtual"
                        v-model="form.virtual"
                        type="checkbox"
                        class="custom-control-input"
                    >
                    <label
                        class="custom-control-label"
                        for="virtual"
                    > Virtual Location</label>
                  </div>

                  <div class="form-group custom-control custom-switch">
                    <input
                      id="portal"
                      v-model="form.portal"
                      type="checkbox"
                      class="custom-control-input"
                    >
                    <label
                      class="custom-control-label"
                      for="portal"
                    > Enable on Portals</label>
                  </div>
               
                  <div class="form-group custom-control custom-switch">
                    <input
                      id="default"
                      v-model="form.default"
                      type="checkbox"
                      class="custom-control-input"
                    >
                    <label
                      class="custom-control-label"
                      for="default"
                    > Default Location</label>
                  </div>

                  <div class="form-group custom-control custom-switch">
                    <input
                      id="disable_messages"
                      v-model="form.disable_messages"
                      type="checkbox"
                      class="custom-control-input"
                    >
                    <label
                      class="custom-control-label"
                      for="disable_messages"
                    > Auto-Disable Messages</label>
                  </div>
        
                  <button type="submit" class="btn btn-success" :disabled="processing">Save</button>
              
            </form>
          </div><!--Body-->
        </div><!--Card-->

        <template v-if="form && form.uuid">
          <audit-logs class="mt-3" :form="form"></audit-logs>
        </template>

      </div><!--Col-->

      <div class="col-sm-12 col-md-9">

        <template v-if="form.virtual && form.map_url">

          <div class="card">
            <div class="card-body">
              <p class="card-title">Virtual Location</p>

              <template v-if="platform">
                <p id="platform_name">Platform {{ platform }}</p>
              </template>


                <template v-if="platform && platform != 'unknown'">

                <a :href="setWebLink(form.map_url)" target="_blank">
                    <img :src="$root.assets_url+'images/virtual-logos/'+platform+'.svg'" width="150" :alt="platform">
                </a>

                  <br>

                <div class="btn-group mt-3">
                <a id="browser_link" :href="setWebLink(form.map_url)" class="btn btn-primary" target="_blank">Open in Browser</a>
                <template v-if="setAppLink(form.map_url)">
                 <a id="desktop_link" :href="setAppLink(form.map_url)" class="btn btn-primary text-capitalize" target="_blank">Open in Desktop</a>
                </template>
                </div>

                </template>


            </div>
          </div>

        </template>


          <div
            id="map"
            class="map-lg"
          />

          <div class="row my-3">
            <div class="col-sm-12 col-md-3">
              <div class="card">
                <div class="card-body">

                  <p class="card-title">
                      <template v-if="roomForm.uuid">Edit</template>
                      <template v-else>Add</template>
                    Room
                  </p>

                  <form @submit.prevent="postRoomForm()">
                    <div class="form-group">
                      <label for="room_name">Name</label>
                      <input
                        id="room_name"
                        v-model="roomForm.name"
                        type="text"
                        class="form-control"
                        required
                      >
                    </div>

                    <template v-if="roomForm.uuid">

                    <div class="form-group">
                      <label for="room_reference">Reference</label>
                      <input
                        id="room_reference"
                        v-model="roomForm.reference"
                        type="text"
                        class="form-control"
                      >
                    </div>

                    <div class="form-group">
                      <label for="floor">Floor</label>
                      <input
                        id="floor"
                        v-model="roomForm.floor"
                        type="number"
                        class="form-control"
                      >
                    </div>

                    <div class="form-group">
                      <label for="sort">Sort</label>
                      <input
                        id="sort"
                        v-model="roomForm.sort"
                        type="number"
                        class="form-control"
                      >
                    </div>

                    <div class="form-group">
                      <label for="room_description">Description</label>
                      <textarea
                        id="room_description"
                        v-model="roomForm.description"
                        class="form-control"
                        rows="3"
                      />
                    </div>

                    <div class="form-group custom-control custom-switch">
                      <input
                        id="room_ada"
                        v-model="roomForm.ada"
                        type="checkbox"
                        class="custom-control-input"
                      >
                      <label
                        class="custom-control-label"
                        for="room_ada"
                      >ADA</label>
                      <p class="text-muted small">If the room is ADA compliant</p>
                    </div>
 
                    </template>

                    <button type="submit" class="btn btn-success" :disabled="processing">Save</button>

                  </form>

                  <template v-if="roomForm.uuid">
                    <button type="button" class="btn btn-danger btn-sm mt-3" @click="trashRoomForm()" :disabled="processing">Trash</button>
                  </template>


                </div><!--Body-->
              </div><!--Card-->
            </div><!--Col-->

            <div class="col-sm-12 col-md-9">

          <div class="card">
              <div class="card-body">
                <p class="card-title">{{ rooms.length }} Rooms</p>

                <template v-if="rooms.length">
                  <div class="list-group">
                    <draggable
                      group="rooms"
                      @start="drag=true"
                      @end="drag=dragRoomEnd($event)"
                    >
                    <template v-for="room in rooms">
                      <button class="list-group-item list-group-item-action" @click="getRoom(room)" :disabled="processing">{{ room.name }}
                      
                      <template v-if="room.floor">
                        <span class="badge badge-secondary">Floor {{ room.floor }}</span>
                      </template>
                      <template v-if="room.sort">
                        <span class="badge badge-secondary ml-1">Sort {{ room.sort }}</span>
                      </template>

                      </button>
                    </template>
                    </draggable>
                  </div>
                </template>



              </div><!--Body-->
            </div><!--Card-->
            </div><!--Col-->

          </div><!--Row--->

      </div><!--Col-->

    </div><!--Row-->
  </div>
</template>

<script>
import { autofill } from '@mapbox/search-js-web';
import draggable from 'vuedraggable';
import queries from '../mixins/queries';

export default {

  mixins: [ queries ],

  components: {
    draggable
  },

  data () {
    return {
      processing: false,
      downloading: false,
      form: {},
      states: [],
      timezones: [],
      platform: null,
      portal: {},
      judges: [],
      rooms: [],
      roomForm: {},
      autofill: null,
    }
  },
  computed: {

    descriptionLength () {
      return this.form.description && this.form.description.length > 0 ? this.form.description.length : 0;
    },

    superAdmin () {
      return this?.$root?.current_user?.super_admin;
    }
  
  },

  mounted () {
    this.autoFill();
  },
  
  created () {

    this.resetForm();
    this.resetRoomForm();
    this.getRecord();
    this.getStates();
    this.getTimezones();
    this.getJudges();

  },

  methods: {

    resetForm() {
        this.form = {
            name: null,
            address: null,
            address2: null,
            city: null,
            state: null,
            zip: null,
            country: null,
            lat: null,
            lng: null,
            instructions: null,
            judge: null,
            business_hours: null,
            business_days: null,
            timezone: null,
            map_url: null,
            passcode: null,
            virtual: false,
            portal: false,
            default: false,
            disable_messages: false
        }
    },

    autoFill() {

      var options = { language: 'en', country: 'us' };

      if(this.settings && this.settings['country']) {
        options['country'] = this.settings['country'];
      }

      if(this.settings && this.settings['latitude'] && this.settings['longitude']) {
        options['proximity'] = [this.settings['longitude'], this.settings['latitude']];
      }

      this.autofill = new autofill({
        accessToken: this.$root.mapbox_token,
        options: options
      });

      var self = this;

      this.autofill.addEventListener('retrieve', (event) => {

        if (!event.detail || !event.detail.features || !event.detail.features.length) {
          return;            
        }

        const features = event.detail.features[0];

        if(features['text_en'] && !self.form.name) {
          self.form.name = event.detail.features[0]['text_en'];
        }

        if(features['geometry'] && features['geometry']['coordinates']) {
          self.form.lat = features['geometry']['coordinates'][1] || null;
          self.form.lng = features['geometry']['coordinates'][0] || null;
        }

        self.getGeocodes();

        if(!features['properties']) {
          return;
        }

        if(features['properties']['district']) {
          self.form.county = features['properties']['district'];
        }

        if(features['properties']['country_code']) {
          self.form.country = features['properties']['country_code'];
        }

        if(features['properties']['address_line2']) {
            self.form.address_2 = features['properties']['address_line2'];
        }

        if(features['properties']['city']) {
          self.form.city = features['properties']['city'];
        }

        if(features['properties']['address_level1']) {
          self.form.state = features['properties']['address_level1'];
        }

        if(features['properties']['postcode']) {
            self.form.zip = features['properties']['postcode'];
        }

        if(features['properties']['country']) {
          self.form.country = features['properties']['country'];
        }


      });

      },

    getRecord () {
      this.processing = true;
      this.$http.get('/'+this.$route.meta.base_url+'/'+this.$route.params.id)
      .then((response) => {
        if (response && response.data) {
          this.form = response.data;
          this.processing = false;

          this.getGeocodes();

          this.getPortal();

          if(this.form.name) {
            this.setTitle();
          }

          this.getRooms();

          if(this.form.map_url) {
            this.platform = this.setVirtualPlatform(this.form.map_url);
          }
        }
      })
    },

    getPortal() {
        this.$http.get('/portals?sort=default&sortDirection=desc&fields=uuid,link,domain,name&limit=1')
            .then(response => {
              if(response && response.data) {

                if(response.data[0]) {
                  this.portal = response.data[0];
                }
              }
            })
    },
    
    getGeocodes() {

      if(this.form.lat && this.form.lng) {
        this.initMap();
        return;
      }

      if(this.form.virtual) {
        return;
      }

      var address = null;

      var fields = ['address', 'address_2', 'city', 'state', 'zip'];

      for (var field in fields) {
        if(this.form[fields[field]]) {
          address += this.form[fields[field]] + ' ';
        }
      }

            this.$http.get('/geocode?address='+address)
            .then(response => {
                if(response && response.data) {

                    if(response.data[0] && response.data[0]['geometry']) {
                        if(response.data[0]['geometry']['location']) {
                            this.form.lat = response.data[0]['geometry']['location']['lat'];
                            this.form.lng = response.data[0]['geometry']['location']['lng'];
                            this.initMap();
                        }
                    }

                }
            })
    },

    setTitle() {
      document.title = this.form.name + ' | Edit '+ this.$route.meta.title;
    },

    postForm () {
      this.processing = true;

      this.getGeocodes();

      this.$http.patch('/'+this.$route.meta.base_url+'/'+this.form.uuid, this.form)
      .then(response => {
        if (response && response.status) {

          if(response.data.updated_at) {
            new this.$noty({text: this.$route.meta.title + ' updated'}).show();
            this.form.updated_at = new Date().toISOString();
          }

          this.processing = false;

          if(this.form.map_url) {
            this.platform = this.setVirtualPlatform(this.form.map_url);
          }
        }
      })
    },

    getRooms() {
      if(!this.form.uuid) {
        return;
      }

      this.$http.get('/rooms?location='+this.form.uuid+'&limit=1000&sort=sort&sortOrder=asc')
          .then(response => {
            if(response && response.data) {
              this.rooms = response.data;
              this.resortRooms();
            }
          })
    },

    resetRoomForm() {
      this.roomForm = {
        name: null,
        reference: null
      }
    },

    getRoom(room) {
      if(!room.uuid) {
        return;
      }

      if(this.roomForm.uuid == room.uuid) {
        this.resetRoomForm();
        return;
      }

      this.resetRoomForm();

      this.processing = true;

      this.$http.get('/rooms/'+room.uuid)
          .then(response => {
            if(response && response.data) {
              this.roomForm = response.data;
              this.processing = false;
            }
          })
    },

    postRoomForm() {

      if(this.roomForm.uuid) {
        this.updateRoomForm();
        return;
      }

      this.processing = true;

      this.roomForm.location = this.form.uuid;

      this.$http.post('/rooms', this.roomForm)
          .then(response => {
            if(response && response.data) {

              if(!this.rooms) {
                this.rooms = [];
              }

              this.rooms.unshift(response.data);
              this.resetRoomForm();
              this.processing = false;
            }
          })
    },

    updateRoomForm() {
      this.processing = true;

      this.$http.patch('/rooms/'+this.roomForm.uuid, this.roomForm)
          .then(response => {
            if(response && response.status === 200) {
              this.processing = false;

              new this.$noty({text: 'Room updated'}).show();

              this.rooms = this.rooms.map(room => {
                if(room.uuid === this.roomForm.uuid) {
                  return this.roomForm;
                }
                return room;
              });

              this.resortRooms();
            }
          })
    },

    trashRoomForm() {

      if(!this.roomForm.uuid) {
        return;
      }

      this.processing = true;

      this.$http.delete('/rooms/'+this.roomForm.uuid)
          .then(response => {
            if(response && response.status) {

              if(response.status === 204) {
                new this.$noty({text: 'Room trashed', type: 'error'}).show();
                this.rooms = this.rooms.filter(room => room.uuid !== this.roomForm.uuid);
                this.resetRoomForm();
              }

              this.processing = false;
            }
          })
    },
    
    restoreRecord () {
      this.processing = true;
      this.$http.put('/'+this.$route.meta.base_url + '/' + this.form.uuid + '/restore')
          .then(response => {
            if (response && response.data) {

              this.processing = false;

              if(response.status === 200) {
                new this.$noty({text: this.$route.meta.title + ' restored'}).show();
                this.form.deleted_at = null;
                this.form.archived_at = null;
              }

            }
          })
    },

    archiveRecord () {
      this.processing = true
      this.$http.put('/'+this.$route.meta.base_url + '/' + this.form.uuid + '/archive')
          .then(response => {
            if (response && response.data) {

              this.processing = false;

              if(response.status === 200) {
                new this.$noty({text: this.$route.meta.title + ' archived'}).show()
                this.form.archived_at = new Date().toISOString();
              }

            }

          })
    },

    trashRecord () {
      this.processing = true;
      this.$http.delete('/'+this.$route.meta.base_url+'/'+this.form.uuid)
      .then(response => {
        if(response && response.status) {

          if(response.status === 204) {
            new this.$noty({text: this.$route.meta.title + ' trashed', type: 'error'}).show();
            this.form.deleted_at = new Date().toISOString();
          }

          this.processing = false;
        }
      })
    },

    setVirtualPlatform(link) {

      let platform = this.settings['virtual_platform'] || 'zoom';

      if(platform) {
        return platform.toLowerCase();
      }

      if (/zoom\.us\/j\//i.test(link) || /zoom\.us\/w\//i.test(link)) {
        return 'zoom';
      } else if (/teams\.microsoft\.com/i.test(link)) {
        return 'teams';
      } else if (/webex\.com\/meet\//i.test(link) || /webex\.com\/join\//i.test(link)) {
        return 'webex';
      }
      else {
        return 'unknown';
      }
    },

    setAppLink(link) {
      switch (this.platform.toLowerCase()) {
        case 'zoom':
          if (link.startsWith("https://") || link.startsWith("http://")) {
            return;
          }
          return `zoomus://zoom.us/wc/${link}/join`;

        case 'teams':
          if (link.startsWith("https://") || link.startsWith("http://")) {
            return;
          }
          return `msteams://teams.microsoft.com/l/meetup-join/${link}`;
      }
    },

    setWebLink(link) {
      switch (this.platform.toLowerCase()) {
        case 'zoom':
          if (link.startsWith("https://") || link.startsWith("http://")) {
            return link;
          }

          var url = `https://zoom.us/wc/${link}/join`;

          if(this.form.passcode) {
            url += `?pwd=${this.form.passcode}`;
          }

          return url;

        case 'teams':
          if (link.startsWith("https://") || link.startsWith("http://")) {
            return link;
          }
          return `https://teams.microsoft.com/l/meetup-join/${link}`;

        case 'webex':
          if (link.startsWith("https://") || link.startsWith("http://")) {
            return link;
          }
          return `https://meetings.webex.com/webex/?meetingNumber=${link}`;

      }

      return link;

    },

    getJudges() {
      this.$http.get('/judges?slim=true&fields=uuid,name&limit=1000&sort=name&sortOrder=asc')
          .then(response => {
            if(response && response.data) {
              this.judges = response.data;
            }
          })
    },

    initMap() {

      if(this.map) {
        this.map.remove();
      }

    this.map = window.L.map("map").setView(this.$root.coordinates, 4);

    window.L.tileLayer(this.$root.map_layer).addTo(this.map);

    this.setMarker();
    },

    setMarker() {

    if(!this.form.lat || !this.form.lng) {
        return;
    }

    const courtIcon = window.L.divIcon({
      html: '<i class="fa fa-building-columns text-primary fa-2x"></i>',
      iconSize: [20, 20],
      className: 'map-icon'
    });

    var popup = '<p class="m-0">Address: '+this.form.full_address+'</p>';

    var marker = window.L.marker([this.form.lat, this.form.lng], {icon: courtIcon, draggable: true}).addTo(this.map).bindPopup(popup);

    var self = this;

    marker.on('dragend', function(event) {
      var coords = marker.getLatLng();
      self.form.lat = coords.lat;
      self.form.lng = coords.lng;
      self.postForm();
    });

    this.map.flyTo([this.form.lat, this.form.lng], 14);
    },

    dragRoomEnd(event) {
      //update room sort order using event index
      let room = this.rooms[event.oldIndex];
      this.$http.patch('/rooms/'+room.uuid, {sort: event.newIndex})
          .then(response => {
            if(response && response.status === 200) {
              new this.$noty({text: 'Room order updated'}).show();
              this.resortRooms();
            }
          })
    },

    resortRooms() {
      this.rooms = this.rooms.sort((a, b) => a.sort - b.sort);
    },

  }
}
</script>
