<template>
  <div>

    <archived-trashed-status
        :form="form"
        :processing="processing"
        :title="$route.meta.title"
      />
          
      <div class="row mb-3">
        <div class="col-sm-12 col-md-4">
            <h1>{{ form.name || $route.meta.title }}</h1>
            <p>
              <router-link :to="{name: 'cases.index'}">Cases</router-link> /
              <router-link :to="{name: $route.meta.base_url+'.index'}">{{ $route.meta.title }}s</router-link> / Edit {{ $route.meta.title }}</p>
        </div><!--Col-->
  
        <div class="col-sm-12 col-md-4">
    
        </div><!--Col-->
  
  
        <div class="col-sm-12 col-md-4 text-right">
          <div class="btn-group">
  
              <div class="btn-group">
                <button
                  type="button"
                  class="btn btn-outline-primary dropdown-toggle radius-right-0"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Tools
                </button>
                <div class="dropdown-menu">
                  <a
                    v-if="!form.deleted_at && !form.archived_at"
                    class="dropdown-item"
                    @click="trashRecord()"
                  >Trash</a>
                </div>
            </div><!--BtnGroup-->
              
            <button
              v-if="!form.archived_at && !form.deleted_at"
              type="button"
              class="btn btn-success"
              :disabled="processing"
              @click="postForm()"
            >
              Save
            </button>
  
            <router-link
              :to="{ name: $route.meta.base_url+'.index' }"
              class="btn btn-danger"
            >
              Close
            </router-link>
  
          </div>
        </div><!--Col-->
      </div><!--Row-->
    <div class="row">
      <div class="col-sm-12 col-md-3">
        <div class="card">
          <div class="card-body">

            <form @submit.prevent="postForm">
                <div class="form-group">
                  <label for="firm_name">Firm Name</label>
                  <input
                    id="firm_name"
                    v-model="form.firm_name"
                    type="text"
                    class="form-control"
                    required
                  >
                </div>
                <div class="form-group">
                  <label for="bar_number">Bar Number</label>
                  <input
                    id="bar_number"
                    v-model="form.bar_number"
                    type="text"
                    class="form-control"
                  >
                </div>
                <div class="form-group">
                  <label for="first_name">First Name</label>
                  <input
                    id="first_name"
                    v-model="form.first_name"
                    type="text"
                    class="form-control"
                  >
                </div>
                <div class="form-group">
                  <label for="last_name">Last Name</label>
                  <input
                    id="last_name"
                    v-model="form.last_name"
                    type="text"
                    class="form-control"
                  >
                </div>
                <div class="form-group">
                  <label for="email">Email</label>
                  <input
                    id="email"
                    v-model="form.email"
                    type="email"
                    class="form-control"
                  >
                </div>
                <div class="form-group">
                  <label for="phone">Phone</label>
                  <input
                    id="phone"
                    v-model="form.phone"
                    type="tel"
                    class="form-control"
                  >
                </div>

                <div class="form-group">
                  <label for="status">Status</label>
                  <select
                    id="status"
                    v-model="form.status"
                    class="form-control"
                  >
                  <option value=""></option>
                  <template v-for="status in statuses">
                    <option :value="status">{{ status }}</option>
                  </template>
                  </select>
                </div>

                <div class="form-group">
                  <label for="practice_areas">Practice Areas</label>
                  <input
                    id="practice_areas"
                    v-model="form.practice_areas"
                    type="text"
                    class="form-control"
                  >
                </div>

                <template v-if="form.data">

                  <div class="form-group">
                    <label for="fax">Fax</label>
                    <input
                      id="fax"
                      v-model="form.data.fax"
                      type="tel"
                      class="form-control"
                    >
                  </div>

                  <div class="form-group">
                  <label for="website">Website</label>
                  <input
                    id="website"
                    v-model="form.data.website"
                    type="url"
                    class="form-control"
                  >
                </div>

                <div class="form-group">
                  <label for="virtual_link">Virtual Link</label>
                  <input
                    id="virtual_link"
                    v-model="form.data.virtual_link"
                    type="url"
                    class="form-control"
                  >
                </div>

                <div class="form-group">
                  <label for="address">Address</label>
                  <input
                    id="address"
                    v-model="form.data.address"
                    type="text"
                    class="form-control"
                  >
                </div>

                <div class="form-group">
                  <label for="address_2">Address 2</label>
                  <input
                    id="address_2"
                    v-model="form.data.address_2"
                    type="text"
                    class="form-control"
                  >
                </div>

                <div class="form-group">
                  <label for="city">City</label>
                  <input
                    id="city"
                    v-model="form.data.city"
                    type="text"
                    class="form-control"
                  >
                </div>

                <div class="form-group">
                  <label for="state">State</label>
                  <input
                    id="state"
                    v-model="form.data.state"
                    type="text"
                    class="form-control"
                  >
                </div>

                <div class="form-group">
                  <label for="zip">Zip</label>
                  <input
                    id="zip"
                    v-model="form.data.zip"
                    type="text"
                    class="form-control"
                  >
                </div>

                <div class="form-group">
                  <label for="photo">Photo URL</label>
                  <input
                    id="photo"
                    v-model="form.data.photo"
                    type="url"
                    class="form-control"
                  >
                </div>

                <div class="form-group">
                  <label for="logo">Logo URL</label>
                  <input
                    id="logo"
                    v-model="form.data.logo"
                    type="url"
                    class="form-control"
                  >
                </div>

                <div class="form-group">
                  <label for="facebook">Facebook URL</label>
                  <input
                    id="facebook"
                    v-model="form.data.facebook"
                    type="url"
                    class="form-control"
                  >
                </div>

                <div class="form-group">
                  <label for="linkedin">LinkedIn URL</label>
                  <input
                    id="linkedin"
                    v-model="form.data.linkedin"
                    type="url"
                    class="form-control"
                  >
                </div>

                <div class="form-group">
                  <label for="description">Description</label>
                  <textarea
                    id="description"
                    v-model="form.data.description"
                    class="form-control"
                    rows="10"
                  ></textarea>
                </div>


                </template>


              <button
                type="submit"
                class="btn btn-success"
                :disabled="processing"
              >
                Save
              </button>

            </form>

          </div><!--Body-->
        </div><!--Card-->


      </div><!--Col-->
      <div class="col-sm-12 col-md-6">

        <div class="card mb-3">
          <div class="card-body">
              <p class="card-title">
                Search Cases
              </p>

            
            <form @submit.prevent="searchCases()">

            <div class="form-group">
              <label for="case_number">Search by Case Number</label>
              <input id="case_number" type="text" class="form-control" @change="searchCases()" v-model="addCase.case_number">
            </div>

            <button type="submit" class="btn btn-primary" :disabled="processing">Search</button>

            </form>

            <template v-if="search_cases && search_cases.length">
              <ul class="list-group mt-3">
              <template v-for="search_case in search_cases">
                <li class="list-group-item list-group-item-action"><p class="fw-bold">Case Number: {{ search_case.case_number }}</p>

                  <template v-if="search_case.type">
                    <small class="text-muted">Type: {{ search_case.type }}</small>
                  </template>

                  <template v-if="search_case.status">
                    <small class="text-muted">Status: {{ search_case.status }}</small>
                  </template>

                  <template v-if="search_case.attorneys">
                    <p class="m-0 text-muted small">Attorneys:</p>
                    <template v-for="attorney in search_case.attorneys">
                      <p class="m-0">{{ attorney.firm_name }} {{ attorney.bar_number }}</p>
                    </template>
                  </template>
                
                  <div class="btn-group">
                    <a :href="'/cases/'+search_case.uuid" target="_blank" class="btn btn-secondary btn-sm" :disabled="processing"><i class="fa fa-edit"></i> Edit</a>
                    <template v-if="!isAttached(search_case)">
                      <button type="button" class="btn btn-success btn-sm" @click="attachCase(search_case)" :disabled="processing">Attach</button>
                    </template>
                  </div>
                </li>
              </template>
              </ul>
            </template>

          </div><!--Body-->
        </div><!--Card-->

        <div class="card mb-3">
          <div class="card-body">

            <p class="card-title">{{ total_cases }} Attached Cases</p>

            <template v-if="!processing && !attorney_cases.length">
              <p class="text-muted">No cases found</p>
            </template>

            <template v-if="attorney_cases && attorney_cases.length">
              <ul class="list-group">
              <template v-for="attorney_case in attorney_cases">
                <li class="list-group-item">
                  
                  <template v-if="attorney_case.case_number">
                    <p class="m-0">
                      Case Number: {{ attorney_case.case_number }}
                    </p>
                  </template>

                  <template v-if="attorney_case.client_reference">
                    <p class="m-0">
                      Client: {{ attorney_case.client_reference }} {{ attorney_case.first_name }} {{ attorney_case.last_name }}
                    </p>
                  </template>

                  <a :href="'/cases/'+attorney_case.uuid" target="_blank" class="btn btn-secondary btn-sm" :disabled="processing"><i class="fa fa-edit"></i> Edit</a>

                  <button type="button" class="btn btn-success btn-sm" @click="postNotify(attorney_case)" :disabled="processing">Notify</button>

                  </li>
              </template>
              </ul>
            </template>

          </div><!--Body-->
        </div><!--Card-->



      </div><!--Col-->

      <div class="col-sm-12 col-md-3">

        <div class="card">
          <div class="card-body">
            <p class="card-title m-0">
              Notification Settings
            </p>

            <p class="m-0 text-muted">
              Send automated notifications of upcoming case events.
            </p>

            <template v-if="!form.email">
              <div class="alert alert-warning">Email is required to send notifications</div>
            </template>

            <form @submit.prevent="postNotifies()">
              <div class="mt-3 form-group">
                <label for="frequency">Frequency</label>
                <select
                  id="frequency"
                  v-model="notify.frequency"
                  class="form-control"
                >
                <option value="">Disabled</option>
                <template v-for="frequency in frequencies">
                  <option :value="frequency">{{ frequency }}</option>
                </template>
                </select>
                <template v-if="notify.frequency">
                  <p class="form-text small text-muted">Attorney is only notified if there are upcoming events for the frequency.</p>
                </template>
              </div>

              <div class="form-group">
                <label for="from_email">From Email</label>
                <select
                  id="from_email"
                  v-model="notify.from_email"
                  class="form-control"
                >
                  <option value="" />
                  <template v-if="agency_emails && agency_emails.length">
                    <template v-for="agency_email in agency_emails">
                      <option
                        :value="agency_email.email"
                      >
                        <template v-if="agency_email.name != agency_email.email">
                          {{ agency_email.name }}
                        </template>
                        {{ agency_email.email }}
                      </option>
                    </template>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="portal">Portal</label>
                <select
                  id="portal"
                  v-model="notify.portal"
                  class="form-control"
                >
                  <option value="" />
                  <template v-if="portals && portals.length">
                    <template v-for="portal in portals">
                      <option
                        :value="portal.uuid"
                      >
                        {{ portal.name }} ({{ portal.link }}.{{ portal.domain }})
                      </option>
                    </template>
                  </template>
                </select>
              </div>

              <button
                type="submit"
                class="btn btn-success"
                :disabled="processing"
              >
                Save
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->


            <p class="card-title mt-3">
             <a :href="'/messages?to='+form.email" target="_blank">{{ messages.length }}
              of {{ total_messages }} Messages
             </a>
            </p>

            <template v-if="!messages.length">
              <div class="alert alert-warning">No messages found</div>
            </template>

            <template v-if="messages.length">
              <template v-for="message in messages">
                <message :message="message" />
              </template> 
            </template>

          
        </div><!--Col-->

      
    </div><!--Row-->
  </div>
</template>

<script>
export default {

  data () {
    return {
      processing: false,
      form: {},
      attorney_cases: [],
      search_cases: [],
      total_cases: 0,
      notify: {
        frequency: null,
        from_email: null,
        portal: null
      },
      addCase: {
        case_number: ''
      },
      agency_emails: [],
      portals: [],
      messages: [],
      total_messages: 0,
      frequencies: ['daily', 'weekly', 'monthly'],
      statuses: ['pending', 'active', 'inactive', 'expired']
    }
  },

  mounted() {

    var self = this;

    this.$eventBus.$on('errors', function (errors) {
        self.processing = false;
    });


  },
  
  created () {
    this.resetNotifyForm();
    this.getRecord();
    this.getAgencyEmails();
    this.getPortals();
  },

  methods: {

    resetNotifyForm() {
      this.notify = {
        frequency: null,
        from_email: null,
        portal: null
      }
    },

    getRecord () {
      this.processing = true;
      this.$http.get('/'+this.$route.meta.base_url+'/'+this.$route.params.id)
      .then((response) => {
        if (response && response.data) {
          this.form = response.data;

          this.processing = false;

          this.getNotifies();
          this.getMessages();
          this.getCases();

          if(this.form.bar_number) {

            var name = this.form.firm_name || this.form.last_name || 'Attorney';

            document.title = this.form.name + ' | Edit ' + this.$route.meta.title;
          }
        }
      })
    },

    getNotifies() {
      this.$http.get('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/notifies')
      .then(response => {
        if(response && response.data && response.data) {
          this.notify = response.data;
        }
      })
    },

    getMessages() {

      if(this.messages && this.messages.length) {
        return;
      }

      this.messages = [];

      if(!this.form.email) {
        return;
      }

      this.$http.get('/messages?to='+this.form.email)
      .then(response => {
        if(response && response.data && response.data.data) {
          this.messages = response.data.data;
          this.getTotalMessages();
        }
      })
    },

    getTotalMessages() {
      this.total_messages = 0;
      this.$http.get('/messages/total?to='+this.form.email)
      .then(response => {
        if(response && response.status === 200) {
          this.total_messages = response.data;
        }
      })
    },

    postForm() {

      if(!this.form.data) {
        this.form.data = {};
      }

      this.processing = true;

      this.$http.patch('/'+this.$route.meta.base_url+'/'+this.form.uuid, this.form)
      .then(response => {
        if(response && response.status === 200) {
          this.processing = false;
          this.form = {...this.form, ...response.data};
          new this.$noty({text: this.$route.meta.title + ' updated'}).show();
        }
      })
    },

    trashRecord () {
      this.processing = true;
      this.$http.delete('/'+this.$route.meta.base_url+'/'+this.form.uuid)
      .then(response => {
        if (response) {
          new this.$noty({text: this.$route.meta.title + ' trashed', type: 'error'}).show();
          this.processing = false;
          this.$router.push({name: this.$route.meta.base_url+'.index'});
        }
      })
    },

    getCases() {
      this.totalCases();
      this.$http.get('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/cases')
      .then(response => {
        if(response && response.data) {
          this.attorney_cases = response.data;
        }
      })
    },

    totalCases() {
      this.$http.get('/cases/total?attorneys='+this.form.uuid)
      .then(response => {
        if(response && response.status === 200) {
          this.total_cases = response.data;
        }
      })
    },

    getAgencyEmails() {
      this.$http.get('/agency_emails?slim=true&limit=100&fields=email,uuid,name')
      .then(response => {
        if(response && response.data) {
          this.agency_emails = response.data;
        }
      })
    },

    searchCases() {
      this.processing = true;
      this.$http.get('/cases?slim=false&fields=uuid,case_number,attorneys,type,status&search='+this.addCase.case_number)
      .then(response => {
        if(response && response.data) {
          this.search_cases = response.data;
          this.processing = false;
        }
      })
    },

    postNotifies() {

      if(!this.form.uuid) {
        return;
      }

      this.processing = true;

      this.notify.uuid = this.form.uuid;

      this.$http.post('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/notifies', this.notify)
      .then(response => {
        if(response && response.data) {
          this.processing = false;
          this.notify = response.data;
          new this.$noty({text: 'Notification settings updated'}).show();
        }
      })
    },

    isAttached(search_case) {
      if(!this.attorney_cases || !this.attorney_cases.length) {
        return false;
      }

      var found = this.attorney_cases.find(attorney_case => attorney_case.uuid === search_case.uuid);

      return found ? true : false;
    },

    attachCase(search_case) {
      this.processing = true;

      var form = {};

      form.attorneys = [this.form.uuid];

      this.$http.patch('/cases/'+search_case.uuid, form)
      .then(response => {
        if(response && response.data) {
          this.processing = false;
          this.search_cases = [];
          this.addCase.case_number = '';
          this.getCases();
          new this.$noty({text: 'Case attached'}).show();
        }
      })
    },

    postNotify(attorney_case) {
        if(!attorney_case || !attorney_case.case_number) {
          return;
        }

        this.processing = true;

        this.$http.post('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/notify', {case_number: attorney_case.case_number})
        .then(response => {
          if(response && response.status) {

            this.processing = false;

            if(response.status === 201) {
              new this.$noty({text: 'Notification sent'}).show();
            }
          }
        })
    },

    getPortals() {
      this.portals = [];
      this.$http.get('/portals?slim=field&fields=name,uuid,link,domain')
      .then(response => {
        if(response && response.data) {
          this.portals = response.data;
        }
      })
    }

  }

}
</script>
