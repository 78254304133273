<template>
  <div>

    <page-tabs page="message_templates" />

    <div class="row">
      <div class="col-sm-12 col-md-3 d-print-none" data-html2canvas-ignore>

    <div class="card">
      <div class="card-body">
        <p class="card-title m-0">
          Add {{ $route.meta.title }}
        </p>

        <form
          @submit.prevent="postForm"
        >
          <div class="form-group">
            <label for="name">Flow Name</label>
            <input
              id="name"
              v-model="form.name"
              type="text"
              class="form-control"
              required
            >
          </div>

          <div class="form-group">
            <label for="type">Type</label>
            <select id="type" class="form-control" v-model="form.type" :disabled="processing" required>
              <option value="" />
              <option
                  v-for="flow_type in flow_types"
                  :value="flow_type"
              >
                {{ flow_type }}
              </option>
            </select>
            <template v-if="form.type">
              <p class="form-text text-muted small">Create a flow to send {{ form.type }} notifications and reminders.</p>
            </template>

          </div>

          <button
            type="submit"
            class="btn btn-success"
            :disabled="processing"
          >
            Add
          </button>
        </form>
      </div><!--Body-->
    </div><!--Card-->

        <div class="card mt-3">
          <div class="card-body">
            <p class="card-title">
              Search {{  $route.meta.title }}s
            </p>

            <form @submit.prevent="searchRecords">
              <div class="form-group">
                <label for="search">Search</label>
                <input
                  id="search"
                  v-model="queries.search"
                  class="form-control"
                  type="search"
                  placeholder="Search by name or reference..."
                >
              </div>

              <div class="form-group">
                <label for="search_type">Type</label>
                <select
                    id="search_type"
                    v-model="queries.type"
                    class="form-control"
                    :disabled="processing"
                    @change="searchRecords"
                >
                  <option value="">
                    Any
                  </option>
                  <template v-if="flow_types && flow_types.length > 0">
                    <template v-for="flow_type in flow_types">
                      <option
                          :value="flow_type"
                      >
                        {{ flow_type }}
                      </option>
                    </template>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="search_portal">Portal</label>
                <select
                    id="search_portal"
                    v-model="queries.portal"
                    class="form-control"
                    :disabled="processing"
                >
                  <option value="">
                    Any
                  </option>
                  <option value="none">
                    No Portal
                  </option>
                  <template v-if="portals && portals.length > 0">
                    <template v-for="portal in portals">
                      <option
                          :value="portal.uuid"
                      >
                        {{ portal.name }}
                      </option>
                    </template>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="search_phone">From Phone</label>
                <select
                    id="search_phone"
                    v-model="queries.phone"
                    class="form-control"
                    :disabled="processing"
                >
                  <option value="">
                    Any
                  </option>
                  <option value="none">
                    No Phone
                  </option>
                  <template v-if="phones && phones.length > 0">
                    <template v-for="phone in phones">
                      <option
                          :value="phone.phone_number"
                      >
                        <template v-if="phone.name == phone.phone_number">
                          {{ phone.phone_number }}
                        </template>
                        <template v-else>
                          {{ phone.name }} {{ phone.phone_number }}
                        </template>
                      </option>
                    </template>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="search_email">From Email</label>
                <select
                    id="search_email"
                    v-model="queries.email"
                    class="form-control"
                    :disabled="processing"
                >
                  <option value="">
                    Any
                  </option>
                  <option value="none">
                    No Email
                  </option>
                  <template v-if="emails && emails.length > 0">
                    <template v-for="email in emails">
                      <option
                          :value="email.email"
                      >
                      <template v-if="email.name == email.email">
                        {{ email.email }}
                      </template>
                      <template v-else>
                        {{ email.name }} {{ email.email }}
                      </template>
                      </option>
                    </template>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="search_creator">Creator</label>
                <select
                    id="search_creator"
                    v-model="queries.created_by"
                    class="form-control"
                    :disabled="processing"
                >
                  <template v-if="users && users.length > 0">
                    <option value="" />
                    <template v-for="user in users">
                      <option
                          :value="user.email"
                      >
                        {{ user.email }}
                      </option>
                    </template>
                  </template>
                </select>
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                    id="simulate"
                    v-model="queries.simulate"
                    type="checkbox"
                    class="custom-control-input"
                    @change="searchRecords()"
                    :disabled="processing"
                >
                <label
                    class="custom-control-label"
                    for="simulate"
                >Simulate Mode</label>
              </div>


              <div class="form-group custom-control custom-switch">
                <input
                    id="search_default"
                    v-model="queries.default"
                    type="checkbox"
                    class="custom-control-input"
                    @change="searchRecords()"
                    :disabled="processing"
                >
                <label
                    class="custom-control-label"
                    for="search_default"
                > Is Default</label>
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                    id="search_virtual"
                    v-model="queries.virtual"
                    type="checkbox"
                    class="custom-control-input"
                    @change="searchRecords()"
                    :disabled="processing"
                >
                <label
                    class="custom-control-label"
                    for="search_virtual"
                > Virtual</label>
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                  id="search_trashed"
                  v-model="queries.trashed"
                  type="checkbox"
                  class="custom-control-input"
                  @change="searchRecords()"
                  :disabled="processing"
                >
                <label
                  class="custom-control-label"
                  for="search_trashed"
                >Trashed</label>
              </div>

              <div class="form-group custom-control custom-switch">
                <input
                    id="search_archived"
                    v-model="queries.archived"
                    type="checkbox"
                    class="custom-control-input"
                    @change="searchRecords()"
                    :disabled="processing"
                >
                <label
                    class="custom-control-label"
                    for="search_archived"
                >Archived</label>
              </div>

              <button
                type="submit"
                class="btn btn-primary"
                :disabled="processing"
              >
                Search
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->

      </div><!--Col-->
      <div class="col-sm-12 col-md-9" id="main">

        <div class="card">
          <div class="card-body">

            <div class="row">

              <div class="col-sm-12 col-md-6">

                <p class="card-title">
                  {{ records.length }} of {{ total.toLocaleString() }} {{  $route.meta.title }}s
                </p>

              </div><!--Col-->

              <div class="col-sm-12 col-md-6 text-right">

                <div class="btn-group d-print-none" data-html2canvas-ignore>
                  <button type="button" class="btn btn-secondary" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
                  <button type="button" class="btn btn-secondary" @click="downloadCSV()" :hidden="!total" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
                  <button type="button" class="btn btn-secondary" :class="{'active': layout === 'table'}" @click="setLayout('table')" :disabled="processing"><i class="fa fa-table"></i> <span class="sr-only">Table</span></button>
                  <button type="button" class="btn btn-secondary" :class="{'active': layout === 'grid'}" @click="setLayout('grid')" :disabled="processing"><i class="fa fa-grid"></i> <span class="sr-only">Grid</span></button>
                </div>
              </div><!--Col-->

            </div><!--Row-->

            <div class="table-responsive my-3" v-if="layout == 'table'">
              <table class="table table-striped table-hover">
              <sort-columns
                :columns="columns"
                :queries="queries"
                :processing="processing"
                :base_url="$route.meta.base_url"
              />

              <tbody>
                <template v-for="record in records">
                  <tr>
                    <td>
                      <router-link :to="{'name': $route.meta.base_url+'.edit', 'params': {'id': record.uuid }}">
                        <i class="fa fa-edit"></i> {{ record.name }}
                      </router-link>
                    </td>
                    <td>
                    <template v-if="record.flow_reference">
                      <mark>{{ record.flow_reference }}</mark>
                    </template>
                    </td>
                    <td>
                      <a 
                      :href="'/preview?simulate=true&flow='+record.uuid" 
                      class="btn btn-outline-primary btn-sm"
                      target="_blank"
                      >Preview
                      </a>
                    </td>
                    <td>
                      <template v-if="record.portal">
                        <router-link :to="{'name': 'portals.edit', 'params': {'id': record.portal}}">
                          {{ setPortalName(record) }}
                        </router-link>
                      </template>
                    </td>
                    <td>
                      <template v-if="record.default === 1">
                        <i class="fa fa-check text-success" /> <span class="sr-only">DEFAULT</span>
                      </template>
                      <template v-else>
                        <i class="fa fa-times text-danger" /> <span class="sr-only">NOT DEFAULT</span>
                      </template>
                    </td>
                    <td>{{ record.type }}</td>
                    <td>
                      <template v-if="record.simulate === 1">
                        <i class="fa fa-check text-success" /> <span class="sr-only">SIMULATE</span>
                      </template>
                      <template v-else>
                        <i class="fa fa-times text-danger" /> <span class="sr-only">NOT SIMULATE</span>
                      </template>
                    </td>
                    <td>
                      <template v-if="record.virtual === 1">
                        <i class="fa fa-check text-success" /> <span class="sr-only">VIRTUAL</span>
                      </template>
                      <template v-else>
                        <i class="fa fa-times text-danger" /> <span class="sr-only">NOT VIRTUAL</span>
                      </template>
                    </td>
                    <td>{{ record.phone }}</td>
                    <td>{{ record.email }}</td>
                    <td>{{ record.created_by }}</td>
                    <td>{{ record.created_at | datetime }}
                      <template v-if="record.deleted_at">
                        <span class="badge badge-pill badge-danger">TRASHED</span>
                      </template>
                      <template v-if="record.archived_at">
                        <span class="badge badge-pill badge-warning">ARCHIVED</span>
                      </template>
                    </td>
                    <td>{{ record.updated_at | datetime }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
            </div><!--Responsive-->

            <template v-if="layout == 'grid'">

              <div class="row my-3">
                <template v-for="record in records">
                  <div class="col-sm-12 col-md-6 mb-3">

                    <div class="card">
                      <div class="card-body">

                        <div class="row">

                          <div class="col-sm-12 col-md-9">
                            <router-link :to="{'name': $route.meta.base_url+'.edit', 'params': {'id': record.uuid }}" class="text-decoration-none">
                              <p class="card-title m-0"><i class="fa fa-edit"></i> Edit {{ record.name }}</p>
                            </router-link>
                          </div><!--Col-->

                          <div class="col-sm-12 col-md-3 text-right">
                            <a 
                            :href="'/preview?simulate=true&flow='+record.uuid" 
                            class="btn btn-outline-primary btn-sm"
                            target="_blank"
                            >Preview
                            </a>
                          </div><!--Col-->

                        </div><!--Row-->

                        <template v-if="record.flow_reference">
                          <span class="badge badge-secondary">{{ record.flow_reference }}</span>
                        </template>

                        <template v-if="record.type">
                          <span class="badge badge-secondary">{{ record.type }}</span>
                        </template>

                        <template v-if="record.phone">
                          <p class="m-0">From Phone {{ record.phone }}</p>
                        </template>

                        <template v-if="record.email">
                          <p class="m-0">From Email {{ record.email }}</p>
                        </template>

                        <template v-if="record.portal">
                            <p class="m-0">Portal
                              <router-link tag="a" :to="{'name': 'portals.edit', 'params': {'id': record.portal}}">
                              {{ setPortalName(record) }}
                              </router-link>
                            </p>
                        </template>

                        <template v-if="record.geolocation">
                          <p class="m-0">Geolocation {{ record.geolocation }}</p>
                        </template>

                        <template v-if="record.types">
                          <p class="m-0">Types {{ record.types }}</p>
                        </template>

                        <template v-if="record.locations && record.locations.length > 0">
                          <p class="m-0">Locations
                          <template v-for="(location, location_index) in record.locations">
                            <router-link :to="{'name': 'locations.edit', 'params': {'id': location.uuid}}">
                              {{ location.name }}
                            </router-link>
                          </template>
                          </p>
                        </template>

                        <template v-if="record.simulate === 1">
                          <span class="badge badge-pill badge-primary">Simulate Mode</span><br>
                        </template>

                        <template v-if="record.default === 1">
                          <p class="m-0"><i class="fa fa-circle text-success"></i> Default Flow</p>
                        </template>

                    <template v-if="record.messages">

                      <template v-for="message in record.messages">

                        <div class="my-3">

                          <p class="small text-muted m-0">{{ setMessageName(message) }}

                            <template v-if="message.trigger == 'event_date' || message.trigger == 'payment_issue_date' || message.trigger == 'payment_due_date'">
                              {{ message.difference }} {{ message.unit }}
                              <template v-if="message.operation === '-'">
                                before
                              </template>
                              <template v-if="message.operation === '+'">
                                after
                              </template>
                            </template>

                            <template v-if="message.status">
                              if <span class="badge badge-secondary">{{ message.status }}</span>
                            </template>

                          </p>

                          <template v-if="message.subjects && message.subjects['en']">
                            <span class="badge badge-secondary">English</span>
                            <p>Default Subject: {{ message.subjects['en'] }}</p>
                          </template>
                          <template v-else>
                            <p class="alert alert-warning">No English subject</p>
                          </template>
                          <p class="sms outbound">{{ message.templates['en'] }}</p>

                          <template v-for="(template, template_index) in message.templates">

                            <template v-if="message.subjects && message.subjects[template_index] && message.subjects[template_index] != null">
                              <span class="badge badge-secondary">{{ setLanguage(template_index) }}</span>
                              <p>Subject: {{ message.subjects[template_index] }}</p>
                            </template>

                            <template v-if="template_index !== 'en'">
                              <span class="badge badge-secondary">{{ setLanguage(template_index) }}</span>
                              <template v-if="template">
                                <p class="sms outbound">{{ template }}</p>                              
                              </template>
                              <template v-else>
                                <div class="alert alert-warning">No {{ setLanguage(template_index) }} template</div>
                              </template>
                            </template>

                          </template>

                          <div class="btn-group">
                            <a :href="'/flows/'+record.uuid+'?'+message.uuid+'/edit'" class="btn btn-secondary" :disabled="processing"><i class="fa fa-edit"></i> Edit</a>
                            <a :href="'/preview?simulate=true&content='+message.templates['en']" class="btn btn-secondary" :disabled="processing"><i class="fal fa-mobile"></i> Preview</a>
                            <button type="button" class="btn btn-primary" @click="copyToClipboard(message.templates['en'])" :disabled="processing"><i class="fa fa-copy"></i> Copy</button>
                          </div>

                        </div><!--Message-->

                      </template><!--Message-->

                    </template><!--Messages-->

                        <template v-if="record.created_at">
                          <timestamps :form="record" />
                        </template>

                        <template v-if="record.created_by">
                          <p class="text-muted small m-0">Creator {{ record.created_by }}</p>
                        </template>

                        </div><!--Body-->
                      </div><!--Card-->


                  </div><!--Col-->


                </template>
              </div><!--Row-->

            </template><!--Layout-->

            <template v-if="!processing && records && !records.length">
              <div class="alert alert-warning">
                No {{ $route.meta.title }}s found.
              </div>
            </template>

          </div><!--Body-->
        </div><!--Card-->


        <template v-if="records && records.length < total">
          <div class="row mt-3">
            <div class="col-12 text-center">
              <button
                type="button"
                class="btn btn-outline-primary d-print-none"
                :disabled="processing"
                @click="getRecords"
              >
                Load more
              </button>
            </div><!--Col-->
          </div><!--Row-->
        </template>
      </div><!--Col-->
    </div><!--Row-->

  </div>
</template>
<script>
import queries from '../mixins/queries';
import _ from "lodash";

export default {

  mixins: [ queries ],

  data () {
    return {
      records: [],
      layout: 'grid',
      processing: false,
      queries: {},
      form: {},
      total: 0,
      columns: [
          {key: 'name', label: 'Name'},
          {key: 'flow_reference', label: 'Reference'},
          {label: 'Preview'},
          {key: 'portal', label: 'portal'},
          {key: 'default', label: 'default'},
          {key: 'type', label: 'type'},
          {key: 'simulate', label: 'simulate'},
          {key: 'virtual', label: 'virtual'},
          {key: 'phone', label: 'From Phone'},
          {key: 'email', label: 'From Email'},
          {key: 'created_by', label: 'Creator'},
          {key: 'created_at', label: 'Created'},
          {key: 'updated_at', label: 'Updated'}
      ],
      flow_types: ['event', 'payment', 'case', 'warrant', 'form', 'file', 'task'],
      portals: [],
      users: [],
      phones: [],
      emails: [],
      triggers: []
    }
  },

  mounted() {

    var self = this;

    this.$eventBus.$on('refreshRecords', function(view) {

        if(view !== self.$route.meta.base_url) {
            return;
        }

        self.resetRecords();
        self.getRecords();

    });

  },

  created () {
    this.resetQueries();
    this.resetForm();
    this.resetRecords();
    this.setURLParams();
    this.setDefaultLayout();

    this.getRecords();
    this.getPortals();
    this.getUsers();
    this.getPhones();
    this.getEmails();
    this.getTriggers();
  },

  methods: {

    setDefaultLayout() {

      var preferences = localStorage.getItem('preferences');

      preferences = JSON.parse(preferences);

      if(this.$root.preferences) {
        preferences = this.$root.preferences;
      }

      if(preferences && preferences['default_layout']) {
        this.layout = preferences['default_layout'];
      }
    },

    resetQueries() {
      this.queries = {
        search: '',
        portal: null,
        created_by: null,
        phone: null,
        email: null,
        limit: 10,
        skip: 0,
        sort: 'created_at',
        sortDirection: 'desc',
        type: null,
        simulate: false,
        default: false,
        virtual: false,
        trashed: false,
        archived: false
      }
    },

    resetRecords() {
      this.records = [];
      this.queries.skip = 0;
      this.total = 0;
    },

    setURLParams() {
        var searchParams = new URLSearchParams(window.location.search);

        var self = this;

        searchParams.forEach(function(value, key) {
            self.queries[key] = value;
        });
    },

    searchRecords() {
      this.resetRecords();
      this.getRecords();
    },

    updateURL() {
      history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url, this.queries));
    },

    getRecords () {

      this.processing = true;

      this.updateURL();

      this.totalRecords();

      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
      .then(response => {
        if (response && response.data) {

          this.setDefaultLayout();

          this.processing = false;

          if(response.data.length) {

            this.queries.skip += response.data.length;

            var self = this;

            response.data.forEach(function(data) {
              self.records.push(data);
            });

          }
        }
      })
    },

    totalRecords() {
      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
      .then(response => {
        if(response && response.status === 200) {
          this.total = response.data;
          this.setTitle();
        }
      })
    },

    setTitle() {
      document.title = this.total + ' ' + this.$route.meta.title + 's | eCourtDate.com';
    },

    resetForm() {
      this.form = {
        name: null,
        type: 'event'
      }
    },

    postForm() {
        this.processing = true;

        if(!this.form.type) {
          this.form.type = 'event';
        }

        this.$http.post('/'+this.$route.meta.base_url, this.form)
        .then(response => {
            if(response && response.status) {

                if(response.status === 201) {
                  this.resetForm();
                  response.data.is_new = true;
                  this.total++;
                  this.setTitle();
                  this.records.unshift(response.data);
                  new this.$noty({text: this.$route.meta.title + ' added'}).show();
                }

                this.processing = false;

            }
        });
    },

    getPortals() {
      this.$http.get('/portals?slim=true&limit=150&fields=name,uuid')
          .then(response => {
            if(response && response.data) {
              this.portals = response.data;
            }
          })
    },

    getUsers() {
      this.$http.get('/users?slim=true&limit=150&fields=name,uuid,email')
          .then(response => {
            if(response && response.data) {
              this.users = response.data;
            }
          })
    },

    getPhones() {
      this.$http.get('/agency_phones?slim=true&limit=150&fields=name,uuid,phone_number')
          .then(response => {
            if(response && response.data) {
              this.phones = response.data;
            }
          })
    },

    getEmails() {
      this.$http.get('/agency_emails?slim=true&limit=150&fields=name,uuid,email')
          .then(response => {
            if(response && response.data) {
              this.emails = response.data;
            }
          })
    },

    setPortalName(record) {

      if(!record.portal) {
        return;
      }

      var portal = _.find(this.portals, {'uuid': record.portal});

      if(!portal || !portal.name) {
        return;
      }

      return portal.name;
    },


    setLayout(layout) {
      this.$eventBus.$emit('updatePreferences', {'default_layout': layout})
      this.layout = layout;
    },

    getTriggers() {
      this.$axios.get(this.$root.assets_url+'js/options.json')
          .then(response => {
            if(response && response.data) {
              this.triggers = response.data.flow_message_triggers;
            }
          })
    },

    setMessageName(message) {
      var trigger = _.find(this.triggers, {'value': message.trigger});

      if(trigger && trigger.label) {
        return trigger.label;
      }

      return message.trigger;
    }

  }
}
</script>
